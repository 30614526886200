import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { usePaginate, useSearch } from '@purple/hooks';
import { Heading, SearchInput, TableFooterSection } from '@purple/ui';
// hooks
import { useActivityHistory } from '~/queries';
// components
import { DataTable, DataTableSkeleton } from '~/components';
import { activityHistoryColumns } from './activityHistoryColumns';
// types
import type React from 'react';

export const ActivityHistory: React.FC = () => {
  const { activityId } = useParams();

  const { search, debounceSearch, onSearchChange, onClearSearch } = useSearch();
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();
  const { data, isFetching } = useActivityHistory({
    limit,
    offset,
    search: debounceSearch,
    activityId: activityId as string,
  });
  const history = useMemo(() => data?.results || [], [data?.results]);
  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);
  const isDataAvailable = useMemo(
    () => history.length > 0 && !isFetching,
    [history, isFetching],
  );

  return (
    <section className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-col gap-4 px-4 pt-6">
        <Heading tag="h2" className="text-grey-title text-lg font-semibold">
          History/Timeline
        </Heading>
        <div className="flex w-full items-center justify-between gap-4">
          <SearchInput
            value={search}
            onChange={onSearchChange}
            onClear={onClearSearch}
            placeholder="Search by user"
            className="max-w-[300px]"
          />
        </div>
      </div>
      <div className="flex w-full flex-col">
        <DataTable
          columns={activityHistoryColumns}
          data={history}
          isLoading={isFetching}
          isFiltersApplied={Boolean(search)}
          emptyStateTitle="History Records Not Found"
          emptyStateMessage="There are no history records to display. If it is an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} />}
          classNames={{
            wrapper: 'mb-0',
            cell: 'py-3 h-14',
            emptyCell: 'py-8',
          }}
        />
        {isDataAvailable && (
          <TableFooterSection
            currentPage={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
    </section>
  );
};
