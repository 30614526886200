import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AppRoutes } from '~/constants/routes/routes';
import { useCurrentUser } from '~/hooks';

export const AuthLayout: React.FC = () => {
  const location = useLocation();
  const { isAuthenticated, isLoading, user } = useCurrentUser();

  if (isAuthenticated && !isLoading && user.id) {
    return <Navigate to={AppRoutes.App.Home.Root.path} replace state={{ from: location }} />;
  }

  return (
    <div className="flex h-screen w-full items-center justify-center bg-grey-50">
      <div className="relative flex w-[564px] items-center justify-center rounded-3xl border border-grey-200 bg-white px-[64px] py-[72px] shadow-custom-standard max-h-720:py-[48px]">
        <Outlet />
      </div>
    </div>
  );
};
