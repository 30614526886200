import { getAxiosInstance } from '../../api';
import { DASHBOARD_ENDPOINTS } from '../../constants/private-api';

export const getDashboardNewStudentsExport = async (parameters: { time_period?: string }) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<Blob>(
    DASHBOARD_ENDPOINTS.GET_DASHBOARD_NEW_STUDENTS_EXPORT,
    {
      params: parameters,
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    },
  );
  return response.data;
};
