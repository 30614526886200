import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import type { TActionTypes } from '@purple/shared-types';

export const getActionTypes = async () => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TActionTypes>(
    ACTIONS.TYPES,
  );
  return response.data;
};
