import { useMutation, useQueryClient } from '@tanstack/react-query';
import { applyCustomTags } from '@purple/shared-services';
import { TAGS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TApplyCustomTagsPayload, TApplyCustomTagsResponse } from '@purple/shared-types';
import type { TCustomUseMutationOptions } from '~/types';

export const useApplyCustomTags = (
  options?: TCustomUseMutationOptions<TApplyCustomTagsResponse, AxiosError, TApplyCustomTagsPayload>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationKey: [TAGS_QUERY_KEYS.APPLY_TAGS],
    mutationFn: applyCustomTags,
    onSuccess: (data, vars, ...args) => {
      const { object_id, content_type } = vars;
      queryClient.invalidateQueries({ queryKey: [TAGS_QUERY_KEYS.GET_CUSTOM_TAGS, { object_id, content_type }], exact: true });
      queryClient.invalidateQueries({ queryKey: [TAGS_QUERY_KEYS.GET_CUSTOM_TAGS, { content_type }], exact: true });
      showSuccessToast('System message', 'Tags applied successfully');
      options?.onSuccess?.(data, vars, ...args);
    },
    onError: (error, ...args) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Failed to apply tags');
      }
      options?.onError?.(error, ...args);
    },
  });
};
