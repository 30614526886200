import { type FC, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useSort, useTimePeriod } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { AppFilters, AppSelectedFiltersList, Button, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, TableFooterSection, Text } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { PERIOD_SELECT_OPTIONS, TIME_PERIODS } from '~/constants/options';
import { useExportTotalFlagsReport } from '~/queries/student-trends-and-flags/useExportTotalFlagsReport';
import { useTotalFlagsReport } from '~/queries/student-trends-and-flags/useTotalFlagsReport';
import { showErrorToast } from '~/shared/lib';
import { filterConfig, TOTAL_FLAGS_SORT_SELECT_OPTIONS } from './constants';
import { totalFlagsColumns } from './totalFlagsColumns';
import type { TStudentTotalFlagsRequestParameters } from '@purple/shared-types';

export const TotalFlagsReportContent: FC = () => {
  const { studentId } = useParams();
  const [searchParameters] = useSearchParams();

  const { onPeriodChange, onPeriodClear, timePeriod } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });
  const { sort, onSortChange, onSortClear } = useSort();
  const { limit, offset, onLimitChange, onPageChange, page } = usePaginate();

  const { data, isLoading } = useTotalFlagsReport({
    studentId: studentId!,
    limit,
    offset,
    color_trend: searchParameters.get('color_trend'),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    time_period: timePeriod,
  });

  const { mutate: exportTable, isPending } = useExportTotalFlagsReport(studentId);

  const exportTableHandler = useCallback(() => {
    if (!studentId) {
      return showErrorToast('System message', `Unable to export the table, with studentId: ${studentId}`);
    }
    const parameters: TStudentTotalFlagsRequestParameters = {
      studentId,
      color_trend: searchParameters.get('color_trend'),
      ordering: searchParameters.get(SORT_QUERY_NAME),
      time_period: timePeriod,
    };
    exportTable(parameters);
  }, [exportTable, searchParameters, studentId, timePeriod]);

  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
      <div className="flex flex-col gap-4 px-4 pt-6">
        <Text variant="size-16" type="body-600">
          Total Flags
        </Text>
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-4">
            <AppFilters config={filterConfig} />
            <RadixSelect onValueChange={onPeriodChange} value={timePeriod}>
              <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
                <RadixSelectTrigger
                  hasClearButton={Boolean(sort)}
                  onClearCallback={onSortClear}
                  triggerContainerClassName="w-[200px]"
                >
                  <RadixSelectValue placeholder="Sort" />
                </RadixSelectTrigger>
                <RadixSelectContent className="w-max">
                  {TOTAL_FLAGS_SORT_SELECT_OPTIONS.map((option) => (
                    <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                      {option.label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <RadixSelectTrigger
                hasClearButton={Boolean(timePeriod)}
                onClearCallback={onPeriodClear}
                triggerContainerClassName="w-[200px]"
              >
                <RadixSelectValue placeholder="Time Period" />
              </RadixSelectTrigger>
              <RadixSelectContent>
                {PERIOD_SELECT_OPTIONS.map(({ label, value }) => (
                  <RadixSelectItem key={value} value={value}>
                    {label}
                  </RadixSelectItem>
                ))}
              </RadixSelectContent>
            </RadixSelect>
          </div>
          <Button variant="primary" iconLeft={<PurpleIcon name="download" />} disabled={isLoading} onClick={exportTableHandler} isLoading={isPending}>
            Export
          </Button>
        </div>
        <AppSelectedFiltersList config={filterConfig} />
      </div>

      <DataTable
        columns={totalFlagsColumns}
        data={data?.results || []}
        isLoading={isLoading}
        emptyStateTitle="No Total Flags found"
        emptyStateMessage="There are no Total Flags to display. If it an error, please contact support."
        skeleton={<DataTableSkeleton rows={limit} />}
      />
      {data && (
        <TableFooterSection
          currentPage={page}
          pageCount={data.count ? Math.ceil(data.count / limit) : 1}
          onPageChange={onPageChange}
          rowsPerPage={limit}
          onRowsPerPageChange={onLimitChange}
          totalRows={data?.count || 0}
        />
      )}
    </div>
  );
};
