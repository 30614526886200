import { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { usePaginate, useTimePeriod } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { Button, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, TableFooterSection, Text } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { PERIOD_SELECT_OPTIONS } from '~/constants/options';
import { AppRoutes } from '~/constants/routes/routes';
import { useDashboardGradeStudents, useDashboardGradeStudentsExport } from '~/queries';
import { showSuccessToast } from '~/shared/lib';
import { useReportColumns } from './useReportColumns';

const GradeReport = () => {
  const { hasPermissions } = usePermissions();
  const hasUserPermissions = hasPermissions(APP_PERMISSIONS.CAN_ACCESS_NEW_STUDENTS_VISUAL);

  const [searchParameters] = useSearchParams();

  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const grade = searchParameters.get('grade') || '';

  const { onPeriodChange, onPeriodClear, timePeriod } = useTimePeriod(
    {
      defaultValue: searchParameters.get('time_period') || '',
    },
  );

  const { data: reportData, isFetching } = useDashboardGradeStudents({ enabled: hasUserPermissions, time_period: timePeriod, grade, limit, offset });

  const { mutate: exportReport, isPending: isExportingReport } = useDashboardGradeStudentsExport();

  const columns = useReportColumns();

  const total = useMemo(() => reportData?.count, [reportData]);

  const exportClickHandler = () => {
    if (reportData) {
      exportReport({
        time_period: timePeriod,
        grade,
        offset: 0,
        limit: reportData.count,
      }, {
        onSuccess: () => {
          showSuccessToast('System message', 'The file has been successfully exported and downloaded to the device');
        },
      });
    }
  };

  const pageCount = useMemo(() => (reportData?.count ? Math.ceil(reportData.count / limit) : 1), [reportData?.count, limit]);

  const students = useMemo(() => reportData?.results || [], [reportData?.results]);

  const isDataAvailable = useMemo(
    () => students.length > 0 && !isFetching,
    [students, isFetching],
  );

  return (
    <div className="flex w-full flex-1 flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="text-brand-blue-700 size-4 shrink-0" />}
        className="h-auto p-1 font-semibold"
        asChild
      >
        <Link to={AppRoutes.App.NewStudentsReport.Root.makePath(timePeriod)}>
          Back to New Students
        </Link>
      </Button>

      <div className="border-grey-200 shadow-custom-heavy col-span-2 flex w-full flex-col gap-0 rounded-lg border bg-white">
        <div className="flex flex-col gap-4  px-4 py-6">
          <div className="flex items-center gap-2">
            <Text variant="size-16" type="body-600" className="text-gray-title">
              {grade}
            </Text>
            {Boolean(total) && (
              <Text variant="size-12" type="body-600" className="text-gray-600">
                (
                {total}
                )
              </Text>
            )}

          </div>
          <div className="flex justify-between">
            <RadixSelect onValueChange={onPeriodChange} value={timePeriod}>
              <RadixSelectTrigger
                hasClearButton={Boolean(timePeriod)}
                onClearCallback={onPeriodClear}
                triggerContainerClassName="w-[180px]"
              >
                <RadixSelectValue placeholder="Time Period" />
              </RadixSelectTrigger>
              <RadixSelectContent>
                {PERIOD_SELECT_OPTIONS.map(({ label, value }) => (
                  <RadixSelectItem key={value} value={value}>
                    {label}
                  </RadixSelectItem>
                ))}
              </RadixSelectContent>
            </RadixSelect>
            <Button iconLeft={<PurpleIcon name="download" />} onClick={exportClickHandler} isLoading={isExportingReport}>Export</Button>
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <div>
            <DataTable
              columns={columns}
              data={reportData?.results || []}
              isLoading={isFetching}
              emptyStateTitle="No Students found"
              emptyStateMessage="There are no Students to display. If it an error, please contact support."
              skeleton={<DataTableSkeleton rows={10} />}
            />
            {isDataAvailable && (
              <TableFooterSection
                currentPage={page}
                pageCount={pageCount}
                onPageChange={onPageChange}
                rowsPerPage={limit}
                onRowsPerPageChange={onLimitChange}
                totalRows={reportData?.count || 0}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { GradeReport };
