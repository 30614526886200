import { useEffect, useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounceValue } from 'usehooks-ts';
import { PurpleIcon } from '@purple/icons';
import { TAGS_TYPES, TakeActionDocumentType, TakeActionType, UserSearchActionChoice } from '@purple/shared-types';
import { constructTakeActionTitle, convertStringToEditorJson, getInitialsFromName } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  TagSelect,
  Textarea,
} from '@purple/ui';
// hooks
import { useCurrentUser, useTakeAction } from '~/hooks/redux';
import { useActionChoices, useCreateAction, useCustomTags, useUsersSearch } from '~/queries';
// helpers
import { ReadableTakeActionType } from '~/constants/take-action';
import { showErrorToast } from '~/shared/lib';
// components
import { TipBox } from '~/components/TipBox';
import { ActionContainerModal } from '../ActionContainerModal';
// schema
import { takeActionStakeholderMeetingSchema } from './schema';
// types
import type React from 'react';
import type { z } from 'zod';

type TStakeholderCollaborativeModalProperties = {
  onSuccessfulAction?: () => void;
};

export const StakeholderCollaborativeModal: React.FC<TStakeholderCollaborativeModalProperties> = ({
  onSuccessfulAction,
}) => {
  const formId = useId();

  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounceValue(searchValue, 500);

  const {
    toggleActionModal,
    selectedStudentId,
    selectedSafId,
    selectedPriorityListId,
    setSelectedStudentId,
    setSelectedPriorityListId,
    isStakeholderMeetingModalOpen,
  } = useTakeAction();
  const { user } = useCurrentUser();

  const { data: tags } = useCustomTags({
    content_type: TAGS_TYPES.ACTION,
  }, {
    enabled: isStakeholderMeetingModalOpen,
  });
  const tagOptions = useMemo(() => tags?.results ?? [], [tags]);
  const { typeOptions, subTypeOptions, serviceOptions, sessionParticipantsOptions } = useActionChoices({
    record_action_type: TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING,
    students: selectedStudentId,
    priority_list: selectedPriorityListId,
    enabled: isStakeholderMeetingModalOpen,
  });
  const { mutate: createAction, isPending } = useCreateAction();
  const { data: participants, isFetching } = useUsersSearch({
    search: debouncedSearchValue,
    action_choices: UserSearchActionChoice.PURPLE_USER_PARTICIPANTS,
    enabled: isStakeholderMeetingModalOpen,
  });

  const purpleParticipantsOptions = useMemo(
    () =>
      participants?.results.map((item) => ({
        ...item,
        label: item.full_name ?? item.email,
        value: item.id,
      })) ?? [],
    [participants],
  );

  const form = useForm<z.infer<typeof takeActionStakeholderMeetingSchema>>({
    resolver: zodResolver(takeActionStakeholderMeetingSchema),
    mode: 'onChange',
    defaultValues: {
      title: '',
      type: '',
      subType: '',
      date: new Date(),
      duration: undefined,
      purpleParticipants: [],
      sessionParticipants: [],
      studentGoals: '',
      services: [],
      tags: [],
      sharedNotes: '',
      myNotes: '',
    },
  });

  useEffect(() => {
    form.setValue(
      'title',
      constructTakeActionTitle({
        documentType: TakeActionDocumentType.TARGETED,
        actionType: TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING,
        userName: user.full_name ?? 'Unknown User',
      }),
    );
  }, [user.full_name, form]);

  const resetFormHandler = () => {
    form.reset({
      ...form.formState.defaultValues,
      duration: null!,
      title: constructTakeActionTitle({
        documentType: TakeActionDocumentType.TARGETED,
        actionType: TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING,
        userName: user.full_name ?? 'Unknown User',
      }),
    });
  };

  const submitStakeholderMeetingActionHandler = (data: z.infer<typeof takeActionStakeholderMeetingSchema>) => {
    if (!selectedStudentId) {
      showErrorToast('Student not selected', 'Please select a student to take an action');
      return;
    }
    if (Array.isArray(selectedStudentId) || selectedPriorityListId) {
      showErrorToast(
        'Multiple students selected',
        'Please select only one student to take stakeholder collaborative meeting action',
      );
      return;
    }
    createAction(
      {
        record_action_type: TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING,
        document_as: TakeActionDocumentType.TARGETED,
        student: selectedStudentId,
        date_and_time: data.date.toISOString(),
        duration: data.duration,
        service_categories: data.services,
        ...(data.subType && { subtype: data.subType }),
        ...(data.tags.length > 0 && {
          tags: data.tags,
        }),
        title: data.title,
        type: data.type,
        session_participants: data.sessionParticipants,
        purple_user_participants: data.purpleParticipants.map(({ value }) => value),
        student_goals: data.studentGoals,
        ...(data.sharedNotes && {
          shared_note: {
            text: convertStringToEditorJson(data.sharedNotes),
          },
        }),
        ...(data.myNotes && {
          private_note: {
            text: convertStringToEditorJson(data.myNotes),
          },
        }),
        ...(selectedSafId && { saf: selectedSafId }),
      },
      {
        onSuccess: () => {
          toggleActionModal(null);
          setSelectedStudentId(null);
          setSelectedPriorityListId(null);
          onSuccessfulAction?.();
          form.reset({
            ...form.formState.defaultValues,
            title: data.title,
          });
        },
      },
    );
  };

  return (
    <ActionContainerModal
      title={ReadableTakeActionType[TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING]}
      submitButtonLabel="Create"
      formId={formId}
      actionType={TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING}
      isLoading={isPending}
      onClose={resetFormHandler}
    >
      <Form
        providerProps={form}
        id={formId}
        className="grid w-full grid-cols-2 gap-4"
        onSubmit={form.handleSubmit(submitStakeholderMeetingActionHandler)}
      >
        <FormField
          control={form.control}
          name="sessionParticipants"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Session Participants</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!form.formState.errors.sessionParticipants}
                  selectedOptions={sessionParticipantsOptions.filter((option) => field.value.includes(option.value))}
                  placeholder="Select participants"
                  showSearch
                  modalPopover
                  onOptionChange={field.onChange}
                >
                  {sessionParticipantsOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="purpleParticipants"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Purple Users Participants</FormLabel>
              <FormControl>
                <MultiSelect
                  isError={!!form.formState.errors.purpleParticipants}
                  selectedOptions={field.value}
                  placeholder="Select participants"
                  showSearch
                  searchValue={searchValue}
                  modalPopover
                  loading={isFetching}
                  shouldFilter={false}
                  disableSelectedAppend
                  onSearchChange={setSearchValue}
                  onOptionChange={(_, selected) => field.onChange(selected)}
                >
                  {purpleParticipantsOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      customContent
                    >
                      <>
                        {field.value.some((selected) => selected.value === option.value) && (
                          <PurpleIcon
                            name="check"
                            className="text-brand-blue-700 absolute left-4 top-1/2 size-4 -translate-y-1/2"
                          />
                        )}
                        <div className="flex items-center gap-2">
                          <Avatar size={32}>
                            <AvatarImage src={option.avatar ?? undefined} alt={option.full_name ?? option.email} />
                            <AvatarFallback className="bg-grey-200">
                              {getInitialsFromName(option.full_name ?? '- -')}
                            </AvatarFallback>
                          </Avatar>
                          <div className="flex flex-col gap-1">
                            <strong className="text-grey-950 line-clamp-1 text-xs font-medium">
                              {option.full_name ?? option.email}
                            </strong>
                            <span className="text-grey-600 line-clamp-1 text-xs">
                              {option.prounitas_roles.at(0) ?? 'No Role'}
                            </span>
                          </div>
                        </div>
                      </>
                    </MultiSelectItem>
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Title</FormLabel>
              <FormControl>
                <Input
                  isError={!!form.formState.errors.title}
                  type="text"
                  disabled
                  value={field.value}
                  className="hover:border-grey-300 focus:border-grey-300 focus-visible:border-grey-300 active:border-grey-300 disabled:text-grey-400"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="type"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Type</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select type"
                    selectedLabel={typeOptions.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search type..." emptyContent="Type not found.">
                  {typeOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="subType"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Subtype</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select subtype"
                    selectedLabel={subTypeOptions.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search subtype..." emptyContent="Subtype not found.">
                  {subTypeOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="services"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Service Categories</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!form.formState.errors.services}
                  selectedOptions={serviceOptions.filter((service) => field.value.includes(service.value))}
                  placeholder="Select categories"
                  modalPopover
                  showSearch
                  onOptionChange={field.onChange}
                >
                  {serviceOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="date"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Date & Time</FormLabel>
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  selected={field.value}
                  defaultMonth={field.value}
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="duration"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Duration (In Minutes)</FormLabel>
              <FormControl>
                <NumberInput
                  {...field}
                  type="number"
                  isError={!!form.formState.errors.duration}
                  placeholder="Enter duration"
                  min={0}
                  changeOnWheel
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="tags"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Tags</FormLabel>
              <FormControl>
                <TagSelect
                  value={tagOptions.filter((tag) => field.value.includes(tag.id))}
                  onChange={(tags) => field.onChange(tags.map((tag) => tag.id))}
                  isError={!!fieldState.error}
                  options={tagOptions}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="studentGoals"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel required>Student Goal(s)</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!form.formState.errors.studentGoals}
                  placeholder="Enter goal text here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="sharedNotes"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <div className="flex items-center gap-1">
                <FormLabel>Shared Notes</FormLabel>
                <TipBox
                  text="These notes are visible to all Purple users, which helps ensure continuity of care for students."
                  tooltipClassName="w-[400px]"
                />
              </div>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!form.formState.errors.sharedNotes}
                  placeholder="Enter notes for the Ecosystem here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="myNotes"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <div className="flex items-center gap-1">
                <FormLabel>My Notes</FormLabel>
                <TipBox
                  text="These notes are only visible to you in Purple and will not be shared with other users. However, these notes are still subject to subpoena and other disclosure requests."
                  tooltipClassName="w-[400px]"
                />
              </div>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!form.formState.errors.myNotes}
                  placeholder="Enter your notes here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </Form>
    </ActionContainerModal>
  );
};
