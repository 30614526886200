import { getAxiosInstance } from '../../api';
import { DASHBOARD_ENDPOINTS } from '../../constants/private-api';
import type { TDashboardRedStudentsResponse } from '@purple/shared-types';

export const getDashboardRedStudents = async (parameters: { time_period?: string }) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TDashboardRedStudentsResponse>(
    DASHBOARD_ENDPOINTS.GET_DASHBOARD_RED_STUDENTS,
    {
      params: parameters,
    },
  );
  return response.data;
};
