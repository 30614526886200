import { TakeActionDocumentType, TakeActionGroupType, TakeActionLinkType, TakeActionType } from '@purple/shared-types';
import type { TTakeActionType, ValueOf } from '@purple/shared-types';

export const ReadableTakeActionType = {
  [TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION]: 'Academic/Career Planning Session',
  [TakeActionType.CHECK_IN]: 'Check-In',
  [TakeActionType.COUNSELING_SESSION]: 'Counseling Session',
  [TakeActionType.DISTRICT_INITIATIVE]: 'District Initiative',
  [TakeActionType.GUIDANCE_COUNSELING_LESSON]: 'Guidance/Counseling Lesson',
  [TakeActionType.OBSERVATION]: 'Observation',
  [TakeActionType.CRISIS_RESPONSE_SESSION]: 'Crisis Response Session',
  [TakeActionType.REMINDER]: 'Reminder',
  [TakeActionType.RESOURCE]: 'Resource',
  [TakeActionType.SERVICE_LINK]: 'Refer/Connect Student to a Program',
  [TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING]: 'Stakeholder Collaborative Meeting',
} as const;

export const ReadableTakeActionDocumentType = {
  [TakeActionDocumentType.GENERAL]: 'General',
  [TakeActionDocumentType.TARGETED]: 'Targeted',
  [TakeActionDocumentType.ATTEMPTED]: 'Attempted',
} as const;

export const ReadableTakeActionLinkType = {
  [TakeActionLinkType.INDIVIDUAL]: 'Individual',
  [TakeActionLinkType.GROUP]: 'Group',
} as const;

export const ReadableTakeActionGroupType = {
  [TakeActionGroupType.NEW]: 'New group',
  [TakeActionGroupType.EXISTING]: 'Link to existing group',
} as const;

export const ACTION_TYPES_SELECT_OPTIONS: Array<{ label: ValueOf<typeof ReadableTakeActionType>; value: TTakeActionType }> = [
  {
    label: ReadableTakeActionType[TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION],
    value: TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION,
  },
  {
    label: ReadableTakeActionType[TakeActionType.CHECK_IN],
    value: TakeActionType.CHECK_IN,
  },
  {
    label: ReadableTakeActionType[TakeActionType.COUNSELING_SESSION],
    value: TakeActionType.COUNSELING_SESSION,
  },
  {
    label: ReadableTakeActionType[TakeActionType.DISTRICT_INITIATIVE],
    value: TakeActionType.DISTRICT_INITIATIVE,
  },
  {
    label: ReadableTakeActionType[TakeActionType.GUIDANCE_COUNSELING_LESSON],
    value: TakeActionType.GUIDANCE_COUNSELING_LESSON,
  },
  {
    label: ReadableTakeActionType[TakeActionType.OBSERVATION],
    value: TakeActionType.OBSERVATION,
  },
  {
    label: ReadableTakeActionType[TakeActionType.CRISIS_RESPONSE_SESSION],
    value: TakeActionType.CRISIS_RESPONSE_SESSION,
  },
  {
    label: ReadableTakeActionType[TakeActionType.REMINDER],
    value: TakeActionType.REMINDER,
  },
  {
    label: ReadableTakeActionType[TakeActionType.RESOURCE],
    value: TakeActionType.RESOURCE,
  },
  {
    label: ReadableTakeActionType[TakeActionType.SERVICE_LINK],
    value: TakeActionType.SERVICE_LINK,
  },
  {
    label: ReadableTakeActionType[TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING],
    value: TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING,
  },
];
