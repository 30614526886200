import { useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { Button, Text } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { useBehaviorAnalysisReport } from '~/queries/student-trends-and-flags/useBehaviorAnalysisReport';
import { useExportBehaviorAnalysisReport } from '~/queries/student-trends-and-flags/useExportBehaviorAnalysisReport';
import { showErrorToast } from '~/shared/lib';
import { behaviorAnalysisColumns } from './behaviorAnalysisColumns';
import type { FC } from 'react';

export const BehaviorAnalysisReportContent: FC = () => {
  const { studentId } = useParams();

  const { data, isLoading } = useBehaviorAnalysisReport({
    studentId: studentId!,
  });

  const { mutate: exportTable, isPending } = useExportBehaviorAnalysisReport(studentId);

  const exportTableHandler = () => {
    if (!studentId) {
      return showErrorToast('System message', `Unable to export the table, with studentId: ${studentId}`);
    }
    exportTable({ studentId });
  };

  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
      <div className="flex items-center justify-between gap-2 px-4 pt-6">
        <Text variant="size-16" type="body-600">
          Behavior Analysis
        </Text>
        <Button variant="primary" iconLeft={<PurpleIcon name="download" />} disabled={isLoading} onClick={exportTableHandler} isLoading={isPending}>
          Export
        </Button>
      </div>
      <DataTable
        columns={behaviorAnalysisColumns}
        data={data || []}
        isLoading={isLoading}
        skeleton={<DataTableSkeleton rows={10} />}
        emptyStateTitle="No Behavior found"
        emptyStateMessage="There are no Behavior to display. If it an error, please contact support."
      />
    </div>
  );
};
