import { useNavigate, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { SafTransferModal } from '~/components';
import { LinkButton } from '~/components/LinkButton';
import { SafPriorityModal } from '~/components/Modals/SafModals/SafPriorityModal';
import { ModalType } from '~/constants/modals';
import { AppRoutes } from '~/constants/routes/routes';
import { SafDetailsSideBar, SafDetailsSkeleton, SafDetailsTabs, SafTabsSkeleton } from '~/modules/SafListManager';
import { NewEmailDialog } from '~/modules/SafListManager/components';
import { useSafDetails } from '~/queries/saf/useSafDetails';

export const SafDetails = () => {
  const { safId } = useParams();
  const navigate = useNavigate();

  const { isLoading } = useSafDetails(safId as string);

  if (!safId) {
    navigate(AppRoutes.System.NotFound.Root.path, { replace: true });
  }

  return (
    <>
      <div className="flex w-full flex-col gap-6">
        <LinkButton to={AppRoutes.App.Safs.Root.path} iconName="chevron-left">
          Back to SAF List Manager
        </LinkButton>
        <AnimatePresence mode="wait">
          {isLoading
            ? (
                <motion.div
                  key="skeleton"
                  initial={{ opacity: 0, y: 0 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 30 }}
                  transition={{ duration: 0.3 }}
                  className="grid w-full grid-cols-1 gap-y-6 2xl:grid-cols-3 2xl:gap-x-6 2xl:gap-y-0"
                >
                  <SafDetailsSkeleton />
                  <SafTabsSkeleton />
                </motion.div>
              )
            : (
                <motion.div
                  key="details"
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -30 }}
                  transition={{ duration: 0.3 }}
                  className="grid w-full grid-cols-1 gap-y-6 2xl:grid-cols-3 2xl:gap-x-6 2xl:gap-y-0"
                >
                  <SafDetailsSideBar />
                  <SafDetailsTabs />
                </motion.div>
              )}
        </AnimatePresence>
      </div>
      <SafTransferModal safId={safId as string} />
      <SafPriorityModal safId={safId as string} />
      <NewEmailDialog modalName={ModalType.SAF_NEW_EMAIL} showSubject />
    </>
  );
};
