import { getAxiosInstance } from '../../api';
import { DASHBOARD_ENDPOINTS } from '../../constants/private-api';
import type { TDashboardSafSubmitterReportResponse } from '@purple/shared-types';

export const getSafSubmittersReport = async () => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TDashboardSafSubmitterReportResponse>(
    DASHBOARD_ENDPOINTS.GET_SAF_SUBMITTERS_REPORT,
  );
  return response.data;
};
