import type { TSafAreaCategoriesValues, TSafOwner } from '../saf';
import type { Prettify, ValueOf } from '../utils';
import type { IBasePaginationRequestParameters, IPaginatedResponse } from './general';
import type { TSchoolItem } from './schools';
import type { IUser } from './user';

export const TakeActionType = {
  ACADEMIC_CAREER_PLANNING_SESSION: 'academic_career_planning_session',
  CHECK_IN: 'check_in',
  COUNSELING_SESSION: 'counseling_session',
  DISTRICT_INITIATIVE: 'district_initiative',
  GUIDANCE_COUNSELING_LESSON: 'guidance_counseling_lesson',
  OBSERVATION: 'observation',
  CRISIS_RESPONSE_SESSION: 'crisis_response_session',
  REMINDER: 'reminder',
  RESOURCE: 'resource',
  SERVICE_LINK: 'service_link',
  STAKEHOLDER_COLLABORATIVE_MEETING: 'stakeholder_collaborative_meeting',
} as const;
export type TTakeActionType = ValueOf<typeof TakeActionType>;

export const ActionDetailsType = {
  ACADEMIC_CAREER_PLANNING_SESSION: 'academic_career_planning_session',
  ARD_504_MEETING: 'ard_504_meeting',
  ATTENDANCE_MEETING: 'attendance_meeting',
  CAFETERIA_COMMON_SPACE_OBSERVATION: 'cafeteria_common_space_observation',
  CASUAL_CLOTHING: 'casual_clothing',
  CLASSROOM_OBSERVATION: 'classroom_observation',
  COUNSELING_SESSION: 'counseling_session',
  DISCIPLINARY: 'disciplinary',
  DISTRICT_INITIATIVE: 'district_initiative',
  FACULTY_STAFF_CHECK_IN: 'faculty_staff_check_in',
  FAMILY_COUNSELING_SOCIAL_WORKERS: 'family_counseling_social_workers',
  FOOD: 'food',
  GAS_CARDS: 'gas_cards',
  GRADUATION_SUPPORT_MEETING: 'graduation_support_meeting',
  GUIDANCE_COUNSELING_LESSON: 'guidance_counseling_lesson',
  HALLWAY_OF_TRANSITION_OBSERVATION: 'hallway_of_transition_observation',
  HISD_SOUPER_PANTRY: 'hisd_souper_pantry',
  HYGIENE_PRODUCT: 'hygiene_product',
  IAT_RTI: 'iat_rti',
  IN_DISTRICT_TRANSPORTATION_SUPPORT: 'in_district_transportation_support',
  LAUNDRY_SERVICES: 'laundry_services',
  LPAC: 'lpac',
  METRO_CARD: 'metro_card',
  ON_CAMPUS_DROP_IN_GROUP: 'on_campus_drop_in_group',
  ON_CAMPUS_DROP_IN_INDIVIDUAL: 'on_campus_drop_in_individual',
  ON_CAMPUS_ONE_TIME_SESSION: 'on_campus_one_time_session',
  ON_CAMPUS_RECURRING: 'on_campus_recurring',
  OTHER: 'other',
  OUT_OF_DISTRICT_TRANSPORTATION_SUPPORT: 'out_of_district_transportation_support',
  PARENT_COMMUNICATION: 'parent_communication',
  PARENT_MEETING: 'parent_meeting',
  PLANNED: 'planned',
  REFERRAL_OUT_APPROVED: 'referral_out_approved',
  REFERRAL_OUT_NOT_APPROVED: 'referral_out_not_approved',
  RESOURCE_ROOM_TECHNOLOGY: 'resource_room_technology',
  ROBOTICS_KIT: 'robotics_kit',
  SA_DEPT_BP: 'sa_dept_bp',
  SA_DEPT_NEW_PARENT_KIT: 'sa_dept_new_parent_kit',
  SCHOOL_STAFF_SUPPORT_MEETING: 'school_staff_support_meeting',
  SCHOOL_SUPPLIES: 'school_supplies',
  THINKERY_ROOM: 'thinkery_room',
  SERVICE_LINK: 'service_link',
  SERVICE_PROVIDER: 'service_provider',
  SNACK: 'snack',
  STEM_KIT: 'stem_kit',
  STUDENT_AT_THE_CENTER: 'student_at_the_center',
  STUDENT_CHECK_IN: 'student_check_in',
  TRANSITION_MEETING: 'transition_meeting',
  UNDERGARMENT: 'undergarment',
  UNIFORM: 'uniform',
  URGENT: 'urgent',
} as const;

export type TActionDetailsType = ValueOf<typeof ActionDetailsType>;

export const ActionDetailsSubtype = {
  COURT_DOCKET_INTERVENTION: 'court_docket_intervention',
  EMAIL: 'email',
  ENROLLMENT_ASSISTANCE: 'enrollment_assistance',
  FOLLOW_UP: 'follow_up',
  HOME_VISIT: 'home_visit',
  SCHOOL_VISIT: 'school_visit',
  LETTER: 'letter',
  PHONE_CALL: 'phone_call',
  REQUESTED_BY_ADMIN: 'requested_by_admin',
  REQUESTED_BY_PARENT: 'requested_by_parent',
  REQUESTED_BY_STAFF: 'requested_by_staff',
  TEACHER_MEETING: 'teacher_meeting',
  TEAMS: 'teams',
  TEXT_MESSAGE: 'text_message',
  THIRD_PARTY_APP: 'third_party_app',
  TREX_SEARCH: 'trex_search',
  TRUANCY_INTERVENTION_REVIEW: 'truancy_intervention_review',
  TSDS_SEARCH: 'tsds_search',
} as const;

export type TActionDetailsSubtype = ValueOf<typeof ActionDetailsSubtype>;

export const TakeActionDocumentType = {
  GENERAL: 'general',
  TARGETED: 'targeted',
  ATTEMPTED: 'attempted',
} as const;
export type TTakeActionDocumentType = ValueOf<typeof TakeActionDocumentType>;

export const TakeActionLinkType = {
  INDIVIDUAL: 'individual',
  GROUP: 'group',
} as const;
export type TTakeActionLinkType = ValueOf<typeof TakeActionLinkType>;

export const TakeActionGroupType = {
  NEW: 'new',
  EXISTING: 'existing',
} as const;
export type TCheckInGroupType = ValueOf<typeof TakeActionGroupType>;

export const ACTIONS_EXPORT_VIEWS = {
  STUDENT_ACTIONS_ATTENDANCE_IMPACT_ANALYSIS_ACTIONS: 'student_actions_attendance_impact_analysis_actions',
  STUDENT_ACTIONS_ATTENDANCE_IMPACT_ANALYSIS_ATTENDANCE: 'student_actions_attendance_impact_analysis_attendance',
  STUDENT_ACTIONS_SUPPORT_BY_TYPE: 'student_actions_support_by_type',
  STUDENT_ACTIONS_SUPPORT_BY_PROGRAM: 'student_actions_support_by_program',
  STUDENT_ACTIONS_SUPPORT_BY_NEED: 'student_actions_support_by_need',
  STUDENT_ACTIONS_SUPPORT_BY_ROLE: 'student_actions_support_by_role',
} as const;

export type TActionsExportViews = ValueOf<typeof ACTIONS_EXPORT_VIEWS>;

export type TActionGroupsRequestParameters = IBasePaginationRequestParameters & {
  record_action_type: TTakeActionType;
};

export type TActionBasicChoiceItem<TIdType = string> = {
  /**
   * The ID of the item.
   */
  id: TIdType;
  /**
   * The name of the item.
   */
  name: string;
};

export type TGroupItem = TActionBasicChoiceItem;

export type TActionGroupsResponse = IPaginatedResponse<TGroupItem[]>;

export type TActionChoicesRequestParameters = Omit<IBasePaginationRequestParameters, 'search'> & {
  record_action_type: TTakeActionType;
  students?: string | string[] | null;
  priority_list?: string | null;
  enabled?: boolean;
};

export type TActionServiceCategory = {
  id: string;
  name: TSafAreaCategoriesValues;
};

export type TActionFocusArea = TActionBasicChoiceItem<number>;

export type TActionUser = Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar'>;

export type TActionChoiceCreated = TActionUser;

export type TActionPurpleParticipant = TActionUser & {
  schools: TSchoolItem[];
};

export type TActionChoiceItem = {
  type?: Record<string, string>;
  subtype?: Record<string, string>;
  service_categories?: TActionServiceCategory[];
  created_by: TActionChoiceCreated[];
  focus_areas?: TActionFocusArea[];
  level_of_distress?: Record<string, string>;
  suicide_screening_assessment_result?: Record<string, string>;
  session_participants?: Record<string, string>;
  purple_user_participants?: TActionPurpleParticipant[];
  threat_assessment_result?: Record<string, string>;
  crisis_types?: Record<string, string>;
};

export type TActionServiceProvider = {
  id: string;
  name: string;
};

export type TActionChoicesResponse = TActionChoiceItem;

export type TAction = {
  /**
   * The ID of the action.
   */
  id: string;
  /*
   * Required if select priority list
   */
  priority_list?: string;
  /**
   * Required if selected single student.
   */
  student?: string;
  /**
   * Required if multiple students selected.
   */
  students?: string[];
  record_action_type: TTakeActionType;
  document_as: TTakeActionDocumentType;
  link_as?: TTakeActionLinkType;
  /**
   * The name of the group is required if the link_as is 'group' and group is new.
   */
  action_group_name?: string;
  /**
   * The ID of the group is required if the link_as is 'group' and group is existing.
   */
  action_group?: string | null;
  title: string;
  type?: string;
  subtype?: string;
  tags?: string[];
  date_and_time: string;
  duration?: number;
  quantity?: number;
  focus_areas?: number[];
  level_of_distress_start_of_session?: number;
  level_of_distress_end_of_session?: number;
  number_of_resources_selected?: number;
  is_suicide_screening_administered?: boolean;
  suicide_screening_assessment_result?: string;
  is_parent_acknowledgement_of_risk_form?: boolean;
  crisis_types?: string[];
  is_law_enforcement_notified?: boolean;
  is_CPS_notified?: boolean;
  is_parent_caregiver_notified?: boolean;
  parent_caregiver_conversation_summary?: string;
  is_administration_notified?: boolean;
  who_was_consulted?: string;
  is_threat_assessment_administered?: boolean;
  threat_assessment_result?: string | null;
  risk_level_result_explanation?: string;
  is_parent_caregiver_notified_of_assessment?: boolean;
  is_weapons_accessible_to_the_individual?: boolean;
  follow_up_plan?: string;
  your_consultants?: string[];
  /**
   * IDs of purple users.
   */
  purple_user_participants?: string[];
  /**
   * String values of user titles.
   * @example student, adult_advocate
   */
  session_participants?: string[];
  student_goals?: string;
  /**
   * List of service categories IDs.
   */
  service_categories?: string[];
  goal_or_progress_towards_goal?: string;
  shared_note?: {
    /**
     * JSON string representing the content of the note in the rich editor format.
     */
    text: string;
  };
  private_note?: {
    /**
     * JSON string representing the content of the note in the rich editor format.
     */
    text: string;
  };
  /**
   * Field is required if the action is created from `SAF` page.
   */
  saf?: string | null;
  created_at?: string;
};

export type TActionBody = Omit<TAction, 'id'>;

export type TActionSaf = {
  id: string;
  saf_number: string;
};

export type TActionScreenersAndResults = {
  is_parent_acknowledgement_of_risk_form: boolean;
  is_suicide_screening_administered: boolean | null;
  level_of_distress_end_of_session: string;
  level_of_distress_start_of_session: string;
  suicide_screening_assessment_result: string | null;
};

export type TActionDetailsItem = {
  student?: {
    id: string;
    full_name: string;
  };
  school?: TSchoolItem;
  document_as?: TTakeActionDocumentType;
  type?: string;
  subtype?: string;
  created_by?: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar'>;
  service_categories?: TActionServiceCategory[];
  focus_areas?: TActionFocusArea[];
  service_provider?: TActionServiceProvider;
  quantity?: number;
  number_of_resources_selected?: number;
  saf?: TActionSaf | null;
};

export type TActionCrisisDetails = {
  crisis_types: string[];
  is_law_enforcement_notified: boolean;
  is_CPS_notified: boolean;
  is_parent_caregiver_notified: boolean;
  is_administration_notified: boolean;
  your_consultants: TActionUser[];
  is_threat_assessment_administered: boolean;
  threat_assessment_result: string | null;
  is_parent_caregiver_notified_of_assessment: boolean;
  is_weapons_accessible_to_the_individual: boolean;
};

export type TActionDetails = {
  id: string;
  district: number;
  record_action_type: Exclude<TTakeActionType, 'service_link'>;
  document_as: TTakeActionDocumentType;
  title: string;
  tags: string[];
  action_group: string | null;
  details: TActionDetailsItem;
  goal_or_progress_towards_goal?: string;
  student_goals?: string;
  screeners_and_results?: TActionScreenersAndResults;
  date_and_time_details: {
    date_and_time: string;
    duration: number;
    end_date_and_time: string | null;
  };
  follow_up_plan?: string | null;
  parent_caregiver_conversation_summary?: string | null;
  risk_level_result_explanation?: string | null;
  crisis_details?: TActionCrisisDetails;
  created_at: string;
};

export type TActionDetailsResponse = TActionDetails;

export type TActionDetailsUpdateBody = Prettify<Partial<TAction> & Pick<TAction, 'id'>>;
export type TActionDetailsUpdateResponse = Prettify<Partial<TAction> & Pick<TAction, 'id'>>;

export type TActionExportFileParameters = {
  view?: TActionsExportViews;
  search?: string | null;
  ordering?: string | null;
  record_action_type?: string | null;
  document_as?: string | null;
  time_period?: string | null;
  // add here more query parameters for other actions requests
};

export type TActionPurpleUserParticipantsRequest = IBasePaginationRequestParameters & {
  ordering?: string;
};

export type TActionPurpleUserParticipant = Pick<IUser, 'id' | 'full_name'> & {
  title: string | null;
};
export type TActionPurpleUserParticipantsResponse = IPaginatedResponse<TActionPurpleUserParticipant[]>;

export type TActionBatchPurpleUserParticipantsBody = {
  purple_user_participants: string[];
};

export type TActionBatchStudentsBody = {
  students: string[];
};

export type TMyGroupActionsRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string | null;
  record_action_type?: TTakeActionType | null;
};
export type TMyGroupAction = Required<
  Pick<TAction, 'id' | 'created_at' | 'record_action_type' | 'title' | 'duration'>
> & {
  /**
   * Name of the group container.
   * Alias for the `action_group_name` field.
   */
  name: string;
};
export type TMyGroupActionsResponse = Prettify<IPaginatedResponse<TMyGroupAction[]>>;

export type TGroupActionDetailsItem = {
  document_as?: TTakeActionDocumentType;
  name: string;
  title: string;
  service_categories?: TActionServiceCategory[];
  focus_areas?: TActionFocusArea[];
  created_by?: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar'>;
  type?: string;
  subtype?: string;
  number_of_resources_selected?: number;
  created_at: string;
};

export type TGroupActionDetails = {
  id: string;
  record_action_type: Exclude<TTakeActionType, 'service_link'>;
  document_as: TTakeActionDocumentType;
  details: TGroupActionDetailsItem;
  goal_or_progress_towards_goal?: string | null;
  student_goals?: string | null;
  date_and_time_details: {
    date_and_time: string;
    duration: number;
    end_date_and_time: string | null;
  };
  screeners_and_results?: TActionScreenersAndResults;
  crisis_details?: TActionCrisisDetails;
  follow_up_plan?: string | null;
  parent_caregiver_conversation_summary?: string | null;
  risk_level_result_explanation?: string | null;
};

export type TGroupActionDetailsResponse = TGroupActionDetails;

export type TGroupActionDetailsUpdateBody = Prettify<Partial<TAction> & Pick<TAction, 'id'>>;

export type TGroupActionHistoryRequestParameters = IBasePaginationRequestParameters & {
  groupActionId: string;
};
export type TGroupActionHistory = {
  id: number;
  field: string;
  original_value: string | null;
  new_value: string;
  user: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar'>;
  user_pk_as_string: string;
  datetime: string;
};
export type TGroupActionHistoryResponse = IPaginatedResponse<TGroupActionHistory[]>;

export type TGroupActionRecurrenceBody = {
  id: string;
  date_and_time: string;
  duration: number;
  title: string;
  students: string[];
  shared_note?: {
    /**
     * JSON string representing the content of the note in the rich editor format.
     */
    text: string;
  };
  private_note?: {
    /**
     * JSON string representing the content of the note in the rich editor format.
     */
    text: string;
  };
};

export type TSafLinkedActionsRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string | null;
  document_as?: string | null;
};

export type TSafLinkedAction = {
  id: string;
  title: string;
  document_as: TTakeActionDocumentType;
  date_and_time: string;
  created_by: TSafOwner;
};

export type TSafLinkedActions = IPaginatedResponse<TSafLinkedAction[]>;

export type TActionTypes = TTakeActionType[];
