import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, Text } from '@purple/ui';

type TYearStudentNavigationProperties = {
  onNavigate: (date: Date) => void;
  currentDate: Date;
};

const YearStudentNavigation = ({ onNavigate, currentDate }: TYearStudentNavigationProperties) => {
  const prevYearHandler = () => {
    const newDate = new Date(currentDate);
    newDate.setFullYear(newDate.getFullYear() - 1);
    onNavigate(newDate);
  };

  const nextYearHandler = () => {
    const newDate = new Date(currentDate);
    newDate.setFullYear(newDate.getFullYear() + 1);
    onNavigate(newDate);
  };

  const label = useMemo(() => {
    if (!currentDate) {
      return '';
    }

    const startYear = currentDate.getFullYear();
    const endYear = currentDate.getFullYear() + 1;
    return `${startYear}-${endYear}`;
  }, [currentDate]);

  return (
    <div className="flex items-center gap-2">
      <Button
        type="button"
        variant="tertiary"
        size="icon_32"
        iconLeft={<PurpleIcon name="chevron-left" className="size-[20px] shrink-0" aria-hidden="true" />}
        onClick={prevYearHandler}
      />
      <Text variant="size-14" type="body-600" className="text-grey-950">{label}</Text>
      <Button
        type="button"
        variant="tertiary"
        size="icon_32"
        iconLeft={<PurpleIcon name="chevron-right" className="size-[20px] shrink-0" aria-hidden="true" />}
        onClick={nextYearHandler}
      />
    </div>
  );
};

export { YearStudentNavigation };
