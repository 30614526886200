import { formatDate, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';
import type { TStudentSafItem } from '@purple/shared-types';

type TOptions = {
  hasSafDescription?: boolean;
  hasSafResolution?: boolean;
};

type TUseStudentSafsColumns = (options: TOptions) => ColumnDef<TStudentSafItem>[];

export const useStudentSafsColumns: TUseStudentSafsColumns = ({ hasSafDescription, hasSafResolution }) => {
  const columns: ColumnDef<TStudentSafItem>[] = [
    {
      accessorKey: 'saf_number',
      header: () => (
        <Text variant="size-10" type="body-600" className="whitespace-nowrap uppercase text-grey-600">
          saf number
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Text variant="size-12" type="body-500" className="text-grey-950">
          {original.saf_number && original.saf_number.length > 0 ? original.saf_number : '—'}
        </Text>
      ),
    },
    {
      accessorKey: 'subject',
      header: () => (
        <Text variant="size-10" type="body-600" className="uppercase text-grey-600">
          subject
        </Text>
      ),
      cell: ({ row }) => (
        <Text variant="size-12" type="body-500" className="text-grey-950">
          {row.original.subject && row.original.subject.length > 0 ? row.original.subject : '—'}
        </Text>
      ),
      size: 250,
    },
    {
      accessorKey: 'needs',
      header: () => (
        <Text variant="size-10" type="body-600" className="uppercase text-grey-600">
          needs
        </Text>
      ),
      cell: ({ row }) => (
        <Text variant="size-12" type="body-500" className="text-grey-950">
          {row.original.needs?.length > 0 ? row.original.needs.map(({ name }) => name).join(', ') : '—'}
        </Text>
      ),
      size: 200,
    },
    {
      accessorKey: 'date_time_opened',
      header: () => (
        <Text variant="size-10" type="body-600" className="uppercase text-grey-600">
          date/time opened
        </Text>
      ),
      cell: ({ row }) => (
        <Text variant="size-12" type="body-500" className="text-grey-950">
          {row.original.date_time_opened && row.original.date_time_opened.length > 0 ? formatDate(row.original.date_time_opened) : '—'}
        </Text>
      ),
    },
    {
      accessorKey: 'priority',
      header: () => (
        <Text variant="size-10" type="body-600" className="uppercase text-grey-600">
          priority
        </Text>
      ),
      cell: ({ row }) => (
        <Text variant="size-12" type="body-500" className="text-grey-950">
          {row.original.priority ? snakeToCamelWithSpaces(row.original.priority) : '—'}
        </Text>
      ),
    },
    ...(hasSafResolution
      ? [{
          accessorKey: 'resolution',
          header: () => (
            <Text variant="size-10" type="body-600" className="uppercase text-grey-600">
              resolution
            </Text>
          ),
          cell: ({ row }: { row: { original: TStudentSafItem } }) => (
            <Text variant="size-12" type="body-500" className="text-grey-950">
              {row.original.resolution_details ? snakeToCamelWithSpaces(row.original.resolution_details) : '—'}
            </Text>
          ),
        }]
      : []),
    ...(hasSafDescription
      ? [{
          accessorKey: 'description',
          header: () => (
            <Text variant="size-10" type="body-600" className="uppercase text-grey-600">
              description
            </Text>
          ),
          cell: ({ row }: { row: { original: TStudentSafItem } }) => (
            <Text variant="size-12" type="body-500" className="text-grey-950">
              {row.original.description ? row.original.description : '—'}
            </Text>
          ),
          size: 250,
        }]
      : []),

  ];
  return columns;
};
