import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pie, PieChart } from 'recharts';
import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { ReadableSafSubmitterType } from '@purple/shared-types';
import { getRandomColor } from '@purple/shared-utils';
import {
  AccessDenied,
  Button,
  Card,
  CardContent,
  CardFooter,
  CardTitle,
  EMPTY_CHART_DATA,
  ReChartContainer,
  ReChartTooltip,
  ReChartTooltipContent,
} from '@purple/ui';
import { ChartLegend } from '~/components/ChartLegend';
import { AppRoutes } from '~/constants/routes/routes';
import { CHART_COLORS } from '../constants';
import { SafSubmittersSkeleton } from './SafSubmittersSkeleton';
import type React from 'react';
import type { TDashboardSAFSubmitters, TSafSubmitterType } from '@purple/shared-types';
import type { TChartConfig } from '@purple/ui';

type TSafSubmittersProperties = {
  /**
   * School SAF submitters data for the selected year.
   */
  data?: TDashboardSAFSubmitters;
  /**
   * Indicates if the data is loading.
   * @default false
   */
  isLoading?: boolean;
};

export const SafSubmitters: React.FC<TSafSubmittersProperties> = (props) => {
  const { data, isLoading = false } = props;

  const { hasPermissions } = usePermissions();

  const navigate = useNavigate();

  const hasUserPermissions = hasPermissions(APP_PERMISSIONS.CAN_ACCESS_SAF_SUBMITTER_VISUAL);
  const hasParallelPermission = hasPermissions(APP_PERMISSIONS.CAN_ACCESS_NEW_SAFS_VISUAL);

  const chartConfig: TChartConfig = useMemo(
    () =>
      data
        ? Object.keys(data)
            .filter((label) => label !== 'total')
            .reduce(
              (accumulator, name, index) => ({
                ...accumulator,
                [name]: {
                  label: name,
                  color: CHART_COLORS[index] ?? getRandomColor(),
                },
              }),
              {
                value: {
                  label: 'Providers',
                },
              },
            )
        : {},
    [data],
  );
  const chartData = useMemo(
    () =>
      data
        ? Object.entries(data)
            .filter(([label]) => label !== 'total')
            .map(([name, value]) => ({
              name: ReadableSafSubmitterType[name as TSafSubmitterType],
              value,
              fill: chartConfig[name]!.color!,
              color: chartConfig[name]!.color!,
            }))
        : [],
    [data, chartConfig],
  );
  const total = useMemo(() => chartData.reduce((accumulator, item) => accumulator + item.value, 0), [chartData]);
  const isNoData = useMemo(() => total === 0, [total]);

  const learnMoreClickHandler = () => {
    navigate(AppRoutes.App.SafSubmittersReport.Root.makePath());
  };

  if (isLoading) {
    return <SafSubmittersSkeleton />;
  }

  if (!hasUserPermissions && !hasParallelPermission) {
    return (
      <div className="border-grey-200 shadow-custom-medium flex min-h-[364px] w-full flex-col rounded-lg border bg-white">
        <AccessDenied accessDeniedMessage="Sorry, you don't have access to this widget" />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Card className="shadow-custom-medium gap-6 px-4 py-6">
      <CardTitle className="text-base font-semibold">SAF Submitters</CardTitle>
      <CardContent className="flex items-center gap-8">
        <ReChartContainer config={chartConfig} className="aspect-square max-h-[164px] w-full shrink-0 basis-[164px]">
          <PieChart>
            <ReChartTooltip cursor={false} content={<ReChartTooltipContent hideLabel />} />
            <Pie outerRadius={164 / 2} data={isNoData ? EMPTY_CHART_DATA : chartData} dataKey="value" nameKey="name" />
          </PieChart>
        </ReChartContainer>
        <div className="flex w-full flex-col gap-6">
          <p className="inline-flex flex-col">
            <strong className="text-grey-title text-4xl font-extrabold">{total}</strong>
            <span className="text-grey-600 text-sm font-medium">Total Submitters</span>
          </p>
          <ChartLegend chartData={chartData} />
        </div>
      </CardContent>
      <CardFooter className="mt-auto justify-center">
        <Button type="button" variant="primary" onClick={learnMoreClickHandler}>
          Learn More
        </Button>
      </CardFooter>
    </Card>
  );
};
