import { format } from 'date-fns';
import { TakeActionDocumentType, TakeActionLinkType } from '@purple/shared-types';
import { ReadableTakeActionDocumentType, ReadableTakeActionLinkType, ReadableTakeActionType } from '../constants';
import type { TTakeActionDocumentType, TTakeActionLinkType, TTakeActionType } from '@purple/shared-types';

/**
 * Constructs the title for a take action.
 *
 * @param options - The options for constructing the title.
 * @param options.documentType - The type of the document.
 * @param options.link - The type of the link.
 * @param options.actionType - The type of the action.
 * @param options.userName - The name of the user.
 * @param options.date - The date of the action.
 * @returns The constructed title for the take action.
 */
export const constructTakeActionTitle = ({
  documentType,
  link,
  actionType,
  userName,
  date,
}: {
  documentType: TTakeActionDocumentType;
  actionType: TTakeActionType;
  userName: string;
  link?: TTakeActionLinkType;
  date?: string;
}) => {
  const atDate = date ? format(new Date(date), 'MM/dd/yyyy') : format(new Date(), 'MM/dd/yyyy');

  const parts = [
    documentType && documentType !== TakeActionDocumentType.GENERAL
      ? ReadableTakeActionDocumentType[documentType]
      : null,
    link && link === TakeActionLinkType.GROUP ? ReadableTakeActionLinkType[link] : null,
    actionType ? ReadableTakeActionType[actionType] : null,
    userName ? `by ${userName}` : null,
    `on ${atDate}`,
  ];

  const finalString = parts.filter(Boolean).join(' ');

  return finalString;
};
