import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDateShortMonthWithTime } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import { LinkButton } from '~/components/LinkButton';
import { ActionsColumn } from './ActionsColumn';
import type { ColumnDef } from '@tanstack/react-table';
import type { TUserFile } from '@purple/shared-types';

export const useProfileFilesColumns = () => {
  const columnHelper = createColumnHelper<TUserFile>();

  const columns: ColumnDef<TUserFile, string>[] = useMemo(() => {
    const data = [
      columnHelper.accessor(() => 'title', {
        id: 'title',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-nowrap text-grey-600">
            TITLE
          </Text>
        ),
        cell: ({ row }) => (
          <LinkButton to={row.original.file} target="_blank">
            {row.original.title}
          </LinkButton>
        ),
        size: 300,
      }),
      columnHelper.accessor(() => 'updated_at', {
        id: 'updated_at',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-nowrap text-grey-600">
            LAST MODIFIED
          </Text>
        ),
        cell: ({ row }) => {
          const { updated_at } = row.original;
          return (
            <Text variant="size-14" type="body-500" className="text-nowrap text-grey-950">
              {updated_at ? formatDateShortMonthWithTime(updated_at) : '-'}
            </Text>
          );
        },
      }),
      columnHelper.accessor(() => 'size', {
        id: 'size',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-nowrap text-grey-600">
            SIZE
          </Text>
        ),
        cell: ({ row }) => {
          const { size: sizeMb } = row.original;
          const sizeMbFixed = +sizeMb.toFixed(2);
          return (
            <Text variant="size-14" type="body-500" className="text-nowrap text-grey-950">
              {sizeMbFixed}
              {' '}
              MB
            </Text>
          );
        },
      }),
      columnHelper.accessor(() => 'actions_column', {
        id: 'actions_column',
        header: () => null,
        cell: ({ row }) => {
          return <ActionsColumn data={row.original} />;
        },
        size: 20,
      }),
    ];
    return data;
  }, [columnHelper]);

  return columns;
};
