import React from 'react';

import { useNavigate } from 'react-router-dom';
import { DISTRICT_STATUS } from '@purple/shared-types';
import { AppRoutes } from '~/constants/routes/routes';
import { useGetDistrictsCheck } from '~/queries';
import { useGetUserPermissions } from '~/queries/permissions';
import { useImpersonateToken } from './useImpersonateToken';
import { useUser } from './useUser';

export const useAppInit = () => {
  const navigate = useNavigate();

  useImpersonateToken();

  const { isLoading: isDistrictsCheckLoading, data: districtData } = useGetDistrictsCheck();
  const { isLoading: isUserInfoLoading, isSuccess: isUserInfoSuccess } = useUser();
  const { isLoading: isPermissionsLoading, data: userPermissions } = useGetUserPermissions({
    enabled: isUserInfoSuccess,
  });

  const isLoading = React.useMemo(
    () => isUserInfoLoading || isDistrictsCheckLoading || isPermissionsLoading,
    [isUserInfoLoading, isDistrictsCheckLoading, isPermissionsLoading],
  );

  if (districtData && districtData.status !== DISTRICT_STATUS.PUBLISHED) {
    navigate(AppRoutes.System.NotFound.Root.path, { replace: true });
  }

  return { isLoading, userPermissions };
};
