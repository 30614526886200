import { getAxiosInstance } from '../../../api';
import { STUDENTS_ENDPOINTS } from '../../../constants/private-api';
import type { TTotalIncidentRequestParameters } from '@purple/shared-types';

export const getExportIncidents = async ({ studentId, token, ...parameters }: TTotalIncidentRequestParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<Blob>(STUDENTS_ENDPOINTS.EXPORT_STUDENT_TOTAL_INCIDENTS_TABLE_DATA(studentId), {
    params: parameters,
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return response.data;
};
