import { z } from 'zod';
import { SafSubmitterType } from '@purple/shared-types';
import { unionOfLiterals, zodAlwaysRefine } from '@purple/shared-utils';
import type { TSafFieldSettingsResponse } from '@purple/shared-types';

export const generateSafFormSchema = (fieldSettingsData: TSafFieldSettingsResponse) => {
  const transformedSettings = fieldSettingsData.field_settings.reduce((acc: { [key: string]: boolean }, setting) => {
    acc[setting.field_name] = setting.is_required;
    return acc;
  }, {});

  const safFormSchema = zodAlwaysRefine(
    z.object({
      // Helper property to determine the submitter type.
      submitter: unionOfLiterals(Object.values(SafSubmitterType)),
      // Step 1
      myself: z.boolean(),
      anonymous: z.boolean(),
      firstName: z
        .string({
          invalid_type_error: 'First name must be a string.',
        })
        .trim()
        .max(128, {
          message: 'First name must be less than 128 characters.',
        }),
      lastName: z
        .string({
          invalid_type_error: 'Last name must be a string.',
        })
        .trim()
        .max(128, {
          message: 'Last name must be less than 128 characters.',
        }),
      email: z
        .string({
          invalid_type_error: 'Email must be a string.',
        })
        .trim()
        .max(128, {
          message: 'Email must be less than 128 characters.',
        }),
      phone: z.string(),
      organization: z
        .string({
          invalid_type_error: 'Organization or campus name must be a string.',
        })
        .trim(),
      // Step 2
      studentFirstName: z
        .string({
          invalid_type_error: 'Student first name must be a string.',
        })
        .trim()
        .max(128, {
          message: 'Student first name must be less than 128 characters.',
        }),
      studentLastName: z
        .string({
          invalid_type_error: 'Student last name must be a string.',
        })
        .trim()
        .max(128, {
          message: 'Student last name must be less than 128 characters.',
        }),
      studentMiddleName: z
        .string({
          invalid_type_error: 'Student middle name must be a string.',
        })
        .trim()
        .max(128, {
          message: 'Student middle name must be less than 128 characters.',
        }),
      studentId: z
        .string({
          invalid_type_error: 'Student ID must be a string.',
        })
        .trim(),
      studentSchool: z
        .string({
          invalid_type_error: 'Student school must be a string.',
        })
        .trim(),
      studentGrade: z
        .string({
          invalid_type_error: 'Student grade must be a string.',
        })
        .trim(),
      studentParentPhone: z.string(),
      // Step 3
      needs: z
        .string({
          invalid_type_error: 'Need ID must be a string.',
          required_error: 'Need is required.',
        })
        .trim(),
      details: z
        .string({
          invalid_type_error: 'Details must be a string.',
        })
        .trim(),
    }),
  ).superRefine(async (data, context) => {
    // STEP 1
    if (!data.anonymous && transformedSettings.submitter__first_name && data.firstName === '') {
      context.addIssue({
        path: ['firstName'],
        code: z.ZodIssueCode.custom,
        message: 'First name is required.',
      });
    }
    if (!data.anonymous && transformedSettings.submitter__last_name && data.lastName === '') {
      context.addIssue({
        path: ['lastName'],
        code: z.ZodIssueCode.custom,
        message: 'Last name is required.',
      });
    }
    if (!data.anonymous && transformedSettings.submitter__email && data.email === '') {
      context.addIssue({
        path: ['email'],
        code: z.ZodIssueCode.custom,
        message: 'Email is required.',
      });
    }
    if (!data.anonymous && transformedSettings.submitter__phone && data.phone === '') {
      context.addIssue({
        path: ['phone'],
        code: z.ZodIssueCode.custom,
        message: 'Phone is required.',
      });
    }
    if (!data.anonymous && transformedSettings.submitter_org_or_campus && data.organization === '') {
      context.addIssue({
        path: ['organization'],
        code: z.ZodIssueCode.custom,
        message: 'Organization or campus name is required.',
      });
    }

    // STEP 2
    if (transformedSettings.submitted_student_first_name && data.studentFirstName === '') {
      context.addIssue({
        path: ['studentFirstName'],
        code: z.ZodIssueCode.custom,
        message: 'First name is required.',
      });
    }
    if (transformedSettings.submitted_student_last_name && data.studentLastName === '') {
      context.addIssue({
        path: ['studentLastName'],
        code: z.ZodIssueCode.custom,
        message: 'Last name is required.',
      });
    }
    if (transformedSettings.submitted_student_middle_name && data.studentMiddleName === '') {
      context.addIssue({
        path: ['studentMiddleName'],
        code: z.ZodIssueCode.custom,
        message: 'Middle name is required.',
      });
    }
    if (transformedSettings.submitted_student_id && data.studentId === '') {
      context.addIssue({
        path: ['studentId'],
        code: z.ZodIssueCode.custom,
        message: 'Student ID is required.',
      });
    }
    if (transformedSettings.submitted_school && data.studentSchool === '') {
      context.addIssue({
        path: ['studentSchool'],
        code: z.ZodIssueCode.custom,
        message: 'School is required.',
      });
    }
    if (transformedSettings.submitted_student_grade && data.studentGrade === '') {
      context.addIssue({
        path: ['studentGrade'],
        code: z.ZodIssueCode.custom,
        message: 'Grade is required.',
      });
    }
    if (transformedSettings.submitted_student_parent_phone && data.studentParentPhone === '') {
      context.addIssue({
        path: ['studentParentPhone'],
        code: z.ZodIssueCode.custom,
        message: 'Phone is required.',
      });
    }

    // STEP 3
    if (transformedSettings
      .select_what_this_saf_is_for && (data.needs === '' || !data.needs)) {
      context.addIssue({
        path: ['needs'],
        code: z.ZodIssueCode.custom,
        message: 'Need is required.',
      });
    }
    if (transformedSettings
      .description && (data.details === '' || !data.details)) {
      context.addIssue({
        path: ['details'],
        code: z.ZodIssueCode.custom,
        message: 'Detail is required.',
      });
    }
  });
  return safFormSchema;
};
