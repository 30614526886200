import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSafFieldSettings } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TSafSubmitterType } from '@purple/shared-types';

export const useSafFieldSettings = ({ submitter_type }: {
  submitter_type: TSafSubmitterType;
}) => {
  const { isError, ...props } = useQuery({
    queryKey: [SAF_QUERY_KEYS.FIELD_SETTINGS, submitter_type],
    queryFn: () => getSafFieldSettings({ submitter_type }),
    enabled: !!submitter_type,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Error', 'Failed to fetch SAF field settings');
    }
  }, [isError]);

  return { ...props, isError };
};
