import { getAxiosInstance } from '../../api';
import { DASHBOARD_ENDPOINTS } from '../../constants/private-api';
import type { TDashboardNewStudentsPayload, TDashboardNewStudentsResponse } from '@purple/shared-types';

export const getDashboardNewStudents = async (parameters: TDashboardNewStudentsPayload) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TDashboardNewStudentsResponse>(
    DASHBOARD_ENDPOINTS.GET_DASHBOARD_NEW_STUDENTS,
    {
      params: parameters,
    },
  );
  return response.data;
};
