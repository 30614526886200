import { useMemo } from 'react';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { formatDateShortMonth, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import {
  ActionsColumn,
  MainActionColumn,
  PriorityColumn,
  StatusColumn,
  StudentNameColumn,
  SubmittedDateColumn,
  SubmitterColumn,
} from './components';
import type { TSafListManagerDto } from '@purple/shared-types';

type TUseSafListColumnsProperties = {
  onSafIdChange: (safId: string) => void;
};

export const useSafListColumns = ({ onSafIdChange }: TUseSafListColumnsProperties) => {
  const columnHelper = createColumnHelper<TSafListManagerDto>();

  const columns: ColumnDef<TSafListManagerDto, string>[] = useMemo(() => {
    const data = [
      columnHelper.accessor(() => 'action', {
        id: 'action',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap">
            ACTION
          </Text>
        ),
        cell: ({ row }) => <MainActionColumn data={row.original} />,
      }),
      columnHelper.accessor(() => 'student_name', {
        id: 'student_name',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap pl-4">
            STUDENT NAME
          </Text>
        ),
        cell: ({ row }) => <StudentNameColumn data={row.original} />,
      }),
      columnHelper.accessor(() => 'priority', {
        id: 'priority',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap text-center">
            PRIORITY
          </Text>
        ),
        cell: ({ row }) => (
          <div className="flex justify-center">
            <PriorityColumn data={row.original} />
          </div>
        ),
      }),
      columnHelper.accessor(() => 'submitter', {
        id: 'submitter',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap">
            SUBMITTER
          </Text>
        ),
        cell: ({ row }) => {
          return <SubmitterColumn data={row.original} />;
        },
      }),
      columnHelper.accessor(() => 'submitter_type', {
        id: 'submitter_type',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap">
            SUBMITTER TYPE
          </Text>
        ),
        cell: ({ row }) => {
          return (
            <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-2">
              {snakeToCamelWithSpaces(row.original.submitter_type) || '-'}
            </Text>
          );
        },
      }),
      columnHelper.accessor(() => 'status', {
        id: 'status',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap text-center">
            STATUS
          </Text>
        ),
        cell: ({ row }) => (
          <div className="flex justify-center">
            <StatusColumn data={row.original} />
          </div>
        ),
      }),
      columnHelper.accessor(() => 'submitted_data', {
        id: 'submitted_data',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap">
            SUBMITTED DATE
          </Text>
        ),
        cell: ({ row }) => <SubmittedDateColumn data={row.original} />,
      }),
      columnHelper.accessor(() => 'prioritized_on', {
        id: 'prioritized_on',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap">
            PRIORITIZED ON
          </Text>
        ),
        cell: ({ row }) => {
          const { priority_assigned_at } = row.original;
          return (
            <Text variant="size-14" type="body-500" className="text-grey-950 text-nowrap">
              {priority_assigned_at ? formatDateShortMonth(priority_assigned_at) : '-'}
            </Text>
          );
        },
      }),
      columnHelper.accessor(() => 'saf_owner', {
        id: 'saf_owner',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap">
            SAF OWNER
          </Text>
        ),
        cell: ({ row }) => {
          const { current_owner } = row.original;
          const fullName = current_owner ? current_owner.full_name : '-';
          return (
            <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-2">
              {fullName}
            </Text>
          );
        },
      }),
      columnHelper.accessor(() => 'actions_column', {
        id: 'actions_column',
        header: () => null,
        cell: ({ row }) => {
          return <ActionsColumn data={row.original} onSafIdChange={onSafIdChange} />;
        },
      }),
    ];
    return data;
  }, [columnHelper, onSafIdChange]);

  return columns;
};
