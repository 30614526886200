import { useMemo, useRef } from 'react';
import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { ALLOWED_ACTIVITY_FILE_EXTENSIONS, MAX_ACTIVITY_FILE_SIZE } from '@purple/shared-utils';
import {
  Button,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
// hooks
import { useSearch, useSort } from '@purple/hooks';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
// helpers
import { showErrorToast } from '~/shared/lib';
// types
import type React from 'react';

const FILES_SORT_SELECT_OPTIONS = [
  {
    label: 'Title (ASC)',
    value: 'title',
  },
  {
    label: 'Title (DESC)',
    value: '-title',
  },
  {
    label: 'Owner (ASC)',
    value: 'created_by__last_name',
  },
  {
    label: 'Owner (DESC)',
    value: '-created_by__last_name',
  },
  {
    label: 'Last Modified (ASC)',
    value: 'updated_at',
  },
  {
    label: 'Last Modified (DESC)',
    value: '-updated_at',
  },
  {
    label: 'Size (ASC)',
    value: 'size',
  },
  {
    label: 'Size (DESC)',
    value: '-size',
  },
] as const;

type TFileHeaderSectionProperties = {
  onFileUpload?: (file: File) => void;
};

export const FilesHeaderSection: React.FC<TFileHeaderSectionProperties> = (props) => {
  const { onFileUpload } = props;

  const { openModal } = useModal(ModalType.UPLOAD_FILE_PREVIEW);
  const { hasPermissions } = usePermissions();

  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onSearchChange, onClearSearch } = useSearch();

  const inputReference = useRef<HTMLInputElement>(null);

  const hasSortValue = useMemo(() => Boolean(sort), [sort]);

  const fileUploadHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    inputReference.current?.click();
  };

  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const [file] = files ?? [];
    if (!file) return;

    if (file.size > MAX_ACTIVITY_FILE_SIZE) {
      showErrorToast('System message', `File size exceeds the limit of ${MAX_ACTIVITY_FILE_SIZE / 1024 / 1024}MB`);
      inputReference.current!.value = '';
      return;
    }

    onFileUpload?.(file);
    openModal();
    inputReference.current!.value = '';
  };

  return (
    <div className="flex w-full items-center justify-between px-4">
      <div className="flex gap-4">
        <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
          <RadixSelectTrigger
            hasClearButton={hasSortValue}
            onClearCallback={onSortClear}
            triggerContainerClassName="min-w-[210px]"
          >
            <RadixSelectValue placeholder="Sort" />
          </RadixSelectTrigger>
          <RadixSelectContent className="w-max">
            {FILES_SORT_SELECT_OPTIONS.map((option) => (
              <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                {option.label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        <SearchInput
          value={search}
          onChange={onSearchChange}
          onClear={onClearSearch}
          placeholder="Search by title or owner"
          className="w-max-[300px]"
        />
      </div>
      {hasPermissions(APP_PERMISSIONS.CAN_ACCESS_ADD_FILES_AND_ATTACHMENTS) && (
        <Button type="button" onClick={fileUploadHandler} iconLeft={<PurpleIcon name="upload" />}>
          Upload File
        </Button>
      )}
      <input
        type="file"
        className="hidden"
        accept={ALLOWED_ACTIVITY_FILE_EXTENSIONS}
        ref={inputReference}
        onChange={fileChangeHandler}
      />
    </div>
  );
};
