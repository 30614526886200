import { forwardRef, useImperativeHandle, useRef } from 'react';
import * as htmlToImage from 'html-to-image';
import { cn } from '@purple/ui';
import { showErrorToast } from '~/shared/lib';
import type { ReactNode } from 'react';

const DEFAULT_FILE_NAME = 'downloaded-content';
const DEFAULT_SCALE = 1;
const DEFAULT_QUALITY = 0.92;

/**
 * @property {number} [scale=1] A scaling factor for image resolution.
 * @property {number} [quality=0.92] Image quality (0–1); mainly for JPEG.
 * @property {'png'|'jpeg'} [format='png'] Output format.
 */
type DownloadOptions = {
  scale?: number;
  quality?: number;
  format?: 'png' | 'jpeg';
};

type DownloadableWrapperProperties = {
  children: ReactNode;
  fileName?: string;
  containerClassName?: string;
};

export type DownloadableWrapperReference = {
  downloadImage: (options?: DownloadOptions) => Promise<void>;
};

const DownloadableWrapper = forwardRef<DownloadableWrapperReference, DownloadableWrapperProperties>(
  ({ fileName = DEFAULT_FILE_NAME, children, containerClassName }, ref) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      downloadImage: async (options) => {
        if (!wrapperRef.current) {
          showErrorToast('System Message', 'Failed to find the content element.');
          return;
        }

        try {
          wrapperRef.current.querySelector('[data-type=download-button]')?.setAttribute('style', 'display: none');
          wrapperRef.current.querySelector('[data-type=view-report-button]')?.setAttribute('style', 'display: none');
          const { scale = DEFAULT_SCALE, quality: optionsQuality = DEFAULT_QUALITY, format: optionsFormat } = options || {};

          const imageMethod = optionsFormat === 'jpeg' ? htmlToImage.toJpeg : htmlToImage.toPng;
          const dataUrl = await imageMethod(wrapperRef.current, {
            quality: optionsQuality,
            pixelRatio: scale,
          });

          // Trigger download
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = `${fileName}.${optionsFormat}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Download failed:', error);
          showErrorToast('System Message', 'Failed to download the content');
        } finally {
          wrapperRef.current.querySelector('[data-type=download-button]')?.removeAttribute('style');
          wrapperRef.current.querySelector('[data-type=view-report-button]')?.removeAttribute('style');
        }
      },
    }));

    return (
      <>
        {children}
        <div
          className={cn('absolute top-[-200vw] left-[-200vh] z-[-9999]', containerClassName)}
        >
          <div ref={wrapperRef}>
            {children}
          </div>
        </div>
      </>
    );
  },
);

DownloadableWrapper.displayName = 'DownloadableWrapper';

export default DownloadableWrapper;
