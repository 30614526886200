// import { useMemo, useState } from 'react';
// import { TAGS_TYPES } from '@purple/shared-types';
// import { Skeleton } from '@purple/ui';
// import { ChoiceTag } from '~/components';
// import { useCustomTags } from '~/queries';
import { StudentStatusBadge } from '../StudentStatusBadge';

// const DEFAULT_AMOUNT_OF_VISIBLE_TAGS = 3;

type StudentLabelsProperties = {
  id: string;
  isStudentActive: boolean;
};

const StudentLabels = ({ isStudentActive }: StudentLabelsProperties) => {
  // TODO: Uncomment this block when tags for students are available
  // const [visibleTagsAmount, setVisibleTagsAmount] = useState<number>(DEFAULT_AMOUNT_OF_VISIBLE_TAGS);

  // const { data: tags, isFetching: isTagsFetching } = useCustomTags({
  //   content_type: TAGS_TYPES.STUDENT,
  //   object_id: id,
  // });
  // const studentTags = useMemo(() => tags?.results ?? [], [tags]);

  // const tagViewClickHandler = () => {
  //   setVisibleTagsAmount(
  //     (prev) => (prev === DEFAULT_AMOUNT_OF_VISIBLE_TAGS
  //       ? studentTags.length
  //       : DEFAULT_AMOUNT_OF_VISIBLE_TAGS),
  //   );
  // };

  // if (isTagsFetching) {
  //   return (
  //     <div className="flex flex-wrap items-center justify-start gap-2">
  //       <Skeleton className="h-6 w-20 rounded-full" />
  //       <Skeleton className="h-6 w-20 rounded-full" />
  //       <Skeleton className="h-6 w-20 rounded-full" />
  //     </div>
  //   );
  // }

  return (
    <div className="flex flex-row flex-wrap gap-2">
      <StudentStatusBadge isActive={isStudentActive} />
      {/* {studentTags
        .slice(0, visibleTagsAmount)
        .map(({ id, name, color }) => (
          <li key={id}>
            <ChoiceTag color={color}>{name}</ChoiceTag>
          </li>
        ))}
      {studentTags.length > DEFAULT_AMOUNT_OF_VISIBLE_TAGS && (
        <li>
          <button
            type="button"
            className="inline-flex h-7 cursor-pointer items-center justify-center px-2 py-0.5 text-sm font-semibold text-brand-blue-700 outline-none transition-colors duration-200 hover:text-brand-blue-600 focus:text-brand-blue-600 focus-visible:text-brand-blue-600 active:text-brand-blue-800 active:transition-none"
            onClick={tagViewClickHandler}
          >
            {visibleTagsAmount === DEFAULT_AMOUNT_OF_VISIBLE_TAGS
              ? 'View All'
              : 'Show Less'}
          </button>
        </li>
      )} */}
    </div>
  );
};

export { StudentLabels };
