import { cn, Text } from '@purple/ui';
import type { FC } from 'react';
import type { ColorRanges } from '@purple/shared-types';

type TLegendComponentProperties = {
  colorRange: ColorRanges;
  containerClassName?: string;
  colorIndicatorClassName?: string;
  labelsClassName?: string;
};

const LegendComponent: FC<TLegendComponentProperties> = ({ colorRange, labelsClassName, containerClassName, colorIndicatorClassName }) => {
  return (
    <div className={cn('flex gap-4', containerClassName)}>
      <div className="flex items-center gap-1">
        <div className={cn('size-2 rounded-full bg-other-green print:size-2 print:[print-color-adjust:exact]', colorIndicatorClassName)} />
        <Text type="body-500" variant="size-12" className={cn('text-grey-600', labelsClassName)}>
          Green (
          {colorRange.green[0]}
          -
          {colorRange.green[1]}
          )
        </Text>
      </div>
      <div className="flex items-center gap-1">
        <div className={cn('size-2 rounded-full bg-brand-yellow print:size-2 print:[print-color-adjust:exact]', colorIndicatorClassName)} />
        <Text type="body-500" variant="size-12" className={cn('text-grey-600', labelsClassName)}>
          Yellow (
          {colorRange.yellow[0]}
          -
          {colorRange.yellow[1]}
          )
        </Text>
      </div>
      <div className="flex items-center gap-1">
        <div className={cn('size-2 rounded-full bg-other-rose-2 print:size-2 print:[print-color-adjust:exact]', colorIndicatorClassName)} />
        <Text type="body-500" variant="size-12" className={cn('text-grey-600', labelsClassName)}>
          Red (
          {colorRange.red[0]}
          +)
        </Text>
      </div>
    </div>
  );
};

export { LegendComponent };
