import { getAxiosInstance } from '../../api';
import { DASHBOARD_ENDPOINTS } from '../../constants/private-api';

export const getSafSubmittersReportExport = async () => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<Blob>(
    DASHBOARD_ENDPOINTS.GET_SAF_SUBMITTERS_REPORT_EXPORT,
    {
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    },
  );
  return response.data;
};
