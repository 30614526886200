import { format, parse, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

// Example: formatDate('2024-07-15T12:17:57.713Z') => 'July 15, 2024'
export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

// Example: formatDate('2024-09-15T12:17:57.713Z') => 'Sep 15, 2024'
export const formatDateShortMonth = (dateString: string) => {
  return format(parseISO(dateString), 'MMM dd, yyyy');
};

// Example: formatDate('2024-09-15T12:17:57.713Z') => 'Sep 15, 2024, 10:00 PM'
export const formatDateShortMonthWithTime = (dateString: string) => {
  return format(parseISO(dateString), 'MMM dd, yyyy, h:mm a');
};

// Example: formatDateWithTime('2022-09-04T02:00:00Z') => 'September 3, 2022, 10:00 PM'
export const formatDateWithTime = (dateString: string) => {
  const date = new Date(dateString);

  const optionsDate: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
  const optionsTime: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'UTC' };

  const datePart = date.toLocaleDateString('en-US', optionsDate);
  const timePart = date.toLocaleTimeString('en-US', optionsTime);

  return `${datePart}, ${timePart}`;
};

// Example: formatDateWithTime('2022-09-04T02:00:00Z', 'America/New_York') => 'September 3, 2022, 10:00 PM'
export const formatDateWithTimezone = (dateString: string, timeZone: string) => {
  const zonedDate = toZonedTime(dateString, timeZone);

  const formattedDate = format(zonedDate, 'MMMM d, yyyy, h:mm a');
  return formattedDate;
};

// Example: formatDateToLocalTime('2022-09-04T02:00:00Z') => 'September 3, 2022, 10:00 PM'
export const formatDateToLocalTime = (dateString?: string | null) => {
  if (!dateString) return '—';
  const date: Date = new Date(dateString);

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const zonedDate: Date = toZonedTime(date, timeZone);

  return format(zonedDate, 'MMMM d, yyyy, h:mm a');
};

/**
 * @param timezone - Timezone string
 * @returns - GMT offset string
 */
export const getGMTOffset = (timezone: string): string => {
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    timeZoneName: 'short',
    hour: '2-digit',
    minute: '2-digit',
  });

  const parts = formatter.formatToParts(new Date());
  const gmtPart = parts.find((part) => part.type === 'timeZoneName');

  return gmtPart ? gmtPart.value : '';
};

/**
 *
 * @param timeString - Time string in the format 'HH:MM:SS'
 * @returns - Date object with the current date and the time from the time string
 */
export const timeStringToDate = (timeString: string) => {
  const currentDate = new Date();
  const [hours, minutes, seconds] = timeString.split(':').map(Number);

  const formattedDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    hours,
    minutes,
    seconds,
  );

  return formattedDate;
};

/**
 * @param date - Date object
 * @returns - Time string in the format 'HH:MM:SS'
 */
export const dateToTimeString = (date: Date) => {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

/**
 * Convert time string with such format 'HH:MM:SS' to 'hh:mm a'
 * @param day - Day of the week (e.g. 'Monday')
 * @param startTime - Start time in the format 'HH:MM:SS'
 * @param endTime - End time in the format 'HH:MM:SS'
 * @returns - Formatted business hour string (e.g. 'Mon, 10:00 AM – 6:00 PM')
 */
export const formatBusinessHour = (day: string, startTime: string, endTime: string) => {
  const formatTime = (time: string) => format(parse(time, 'HH:mm:ss', new Date()), 'hh:mm a');
  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(endTime);
  return `${format(parse(day.toLowerCase(), 'eeee', new Date()), 'eee')}, ${formattedStartTime} – ${formattedEndTime}`;
};

/**
 * Convert date strings to holiday date string with such format 'name, MMM DD, YYYY'
 * @param name - Holiday name
 * @param startDate - Start date string (e.g. '2024-12-25T00:00:00Z')
 * @param endDate - End date string (e.g. '2024-12-25T00:00:00Z')
 * @returns - Formatted holiday date string (e.g. 'Christmas, Dec 25, 2024')
 */
export const formatHolidayDate = (name: string, startDate: string, endDate: string) => {
  return `${name}, ${formatDateShortMonth(startDate)} – ${formatDateShortMonth(endDate)}`;
};

// Example: covertFullDateToDateWithoutTime('2025-01-08T22:00:00.000Z') => '2025-01-08'
export const covertFullDateToDateWithoutTime = (dateStr: Date | string) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
