import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { PurpleIcon } from '@purple/icons';
import { LANGUAGE_QUERY_NAME, type TSafTextTranslationsView } from '@purple/shared-types';
import { Banner, Button, cn } from '@purple/ui';
import { SafLinkExtention } from '~/components';
import { useArabicLanguage } from '../hooks';

type TSafBannerProperties = {
  /**
   * The data object that contains the translations.
   */
  data: TSafTextTranslationsView | undefined;
};

export const SafBanner = ({ data }: TSafBannerProperties) => {
  const [hideBanner, setHideBanner] = useState<boolean>(false);

  const { isArabic } = useArabicLanguage();

  const [searchParameters] = useSearchParams();
  const currentLanguage = searchParameters.get(LANGUAGE_QUERY_NAME) || 'en';

  const acknowledgementText = useMemo(() => {
    return (data?.acknowledgement_text as Record<string, string>)?.[currentLanguage] || '';
  }, [data, currentLanguage]);

  const closeBannerClickHandler = () => {
    setHideBanner(true);
  };

  const acknowledgementEditorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      SafLinkExtention.configure({
        autolink: true,
        defaultProtocol: 'https',
      }),
      Placeholder.configure({
        placeholder: () => 'Enter your disclaimer text here...',
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: acknowledgementText,
    editable: false,
  });

  useEffect(() => {
    acknowledgementEditorConfig?.commands.setContent(acknowledgementText);
  }, [acknowledgementText, acknowledgementEditorConfig]);

  if (hideBanner) {
    return null;
  }

  return (
    <Banner
      className={cn({
        '[&>button]:left-4 [&>button]:right-[unset]': isArabic,
      })}
    >
      <PurpleIcon
        name="information-circle"
        className={cn('h-5 w-5', {
          'absolute right-4 top-3': isArabic,
        })}
      />
      <Button
        type="button"
        aria-label="Close banner"
        variant="primary"
        size="icon_32"
        iconLeft={<PurpleIcon name="X" />}
        className={cn('z-10 bg-brand-blue-500 text-white')}
        onClick={closeBannerClickHandler}
      />
      <EditorContent
        editor={acknowledgementEditorConfig}
        disabled
        className="minimal-tiptap-editor flex size-full cursor-text flex-col p-0 font-primary text-sm font-medium text-white"
      />
    </Banner>
  );
};
