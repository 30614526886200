import { type FC, memo } from 'react';
import { CartesianGrid, LabelList, Line, LineChart, XAxis, YAxis } from 'recharts';
import { getTransformObjectArrayToLabelValuePairs } from '@purple/shared-utils';
import { Card, CardContent, CardHeader, ReChartContainer, ReChartLegend, ReChartLegendContent, Text } from '@purple/ui';
import type { ChartConfig } from '@purple/ui';

type TLineChartSectionProperties = {
  actions: Array<Record<string, number>>;
  attendance: Array<Record<string, number>>;
  isAnimationActive?: boolean;
};

export const LineChartSection: FC<TLineChartSectionProperties> = memo(({ actions, attendance, isAnimationActive = false }) => {
  const attendanceData = getTransformObjectArrayToLabelValuePairs(actions);
  const actionsData = getTransformObjectArrayToLabelValuePairs(attendance);
  const chartData = attendanceData.map((item, index) => ({
    month: item.label,
    attendance: item.value,
    actions: actionsData[index]?.value ?? 0,

  }));

  const chartConfig = {
    actions: {
      label: 'Attendance',
      color: '#4554A5',
    },
    attendance: {
      label: 'Action',
      color: '#F89B3F',
    },
  } satisfies ChartConfig;

  return (
    <Card className="col-span-2 px-1 py-2">
      <CardHeader className="flex flex-row items-center justify-between gap-2 space-y-0 rounded-none">
        <Text variant="size-10" type="body-600">
          Attendance Impact Analysis
        </Text>
      </CardHeader>
      <CardContent className="mt-1">
        <ReChartContainer config={chartConfig} className="aspect-auto h-[190px] w-full">
          <LineChart
            accessibilityLayer
            data={chartData}
            margin={{
              top: 20,
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid stroke="#eee" vertical strokeDasharray="3 3" />
            <XAxis
              dataKey="month"
              tickLine={false}
              axisLine={false}
              interval={1}
              tickMargin={10}
              className="text-[10px] text-grey-title"
            />
            <YAxis
              width={15}
              orientation="left"
              axisLine={false}
              tickLine={false}
              tickMargin={5}
              className="text-[10px] text-grey-title"
              label={{ value: 'Action, qty', position: 'top', dx: 10, dy: -7 }}
              dataKey="attendance"
              yAxisId="attendance"
              domain={['dataMin', 'dataMax + 3']}
            />
            <YAxis
              width={20}
              orientation="right"
              axisLine={false}
              tickLine={false}
              tickMargin={5}
              dataKey="actions"
              className="text-[10px] text-grey-title"
              label={{ value: 'Attendance, %', position: 'top', dx: -16, dy: -7 }}
              yAxisId="actions"
              domain={['dataMin', 'dataMax + 3']}
            />
            <Line
              dataKey="attendance"
              type="monotoneX"
              stroke="var(--color-actions)"
              strokeWidth={1.2}
              dot={{
                fill: 'var(--color-actions)',
              }}
              yAxisId="attendance"
              isAnimationActive={isAnimationActive}
            >
              <LabelList
                position="bottom"
                offset={7}
                className="fill-[#4554A5] font-primary text-[10px] font-semibold"
              />
            </Line>
            <Line
              dataKey="actions"
              type="monotoneX"
              stroke="var(--color-attendance)"
              strokeWidth={1.2}
              yAxisId="actions"
              dot={{
                fill: 'var(--color-attendance)',
              }}
              isAnimationActive={isAnimationActive}
            >
              <LabelList
                position="top"
                offset={7}
                className="fill-[#F89B3F] font-primary text-[10px] font-semibold"
              />
            </Line>
            <ReChartLegend content={<ReChartLegendContent className="flex justify-start pt-0" />} />
          </LineChart>
        </ReChartContainer>
      </CardContent>
    </Card>
  );
});
