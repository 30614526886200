import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueries } from '@tanstack/react-query';
import { districtsCheck, getStudentActionsList, getStudentDashboard, getStudentDetails, getStudentTrendsAndFlags } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS, DISTRICTS_QUERY_KEYS, getTransformObjectArrayToLabelValuePairs, STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { Card, CardContent, CardHeader, StackedBarChart, Text } from '@purple/ui';
import { CustomizedGroupTick, LegendComponent } from '~/components';
import { convertPlot } from '~/queries/student-trends-and-flags/converter';
import { createConfigAndData, processChartData } from '../../constants';
import { LineChartSection } from '../LineChartSection';
import { PageHeader } from '../PageHeader';
import { PieChartSection } from '../PieChartSection';
import { StudentCardSection } from '../StudentCardSection';
import { StudentDetailsSection } from '../StudentDetailsSection';

export const StudentVisualDashboardPrintView = () => {
  const [searchParams] = useSearchParams();

  const studentId = searchParams.get('studentId');
  const token = searchParams.get('token');

  const subdomain = useMemo(() => window.location.hostname.split('.')[0], []);

  const studentDetailsQueryParameters = useMemo(() => ({
    limit: 1,
    offset: 0,
    token,
  }), [token]);

  const { districtData, hasAnyError, isAllSuccess, isLoading, studentActions, studentDashboardData, studentDetails, trendsFlagsData } = useQueries({
    queries: [
      {
        queryKey: [DISTRICTS_QUERY_KEYS.CHECK],
        queryFn: () => districtsCheck(subdomain!),
        enabled: !!subdomain,
      },
      {
        queryKey: [STUDENTS_QUERY_KEYS.GET_DASHBOARD, studentId],
        queryFn: () => getStudentDashboard(studentId!, token!),
        enabled: !!studentId && !!token,
      },
      {
        queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_DETAILS, studentId],
        queryFn: () => getStudentDetails(studentId!, token!),
        enabled: !!studentId && !!token,
      },
      {
        queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_SCHOOL_LIST, { studentId, studentDetailsQueryParameters }],
        queryFn: () => getStudentActionsList({ studentId: studentId!, queryParameters: studentDetailsQueryParameters }),
        enabled: !!studentId && !!token,
      },
      {
        queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_TRENDS_AND_FLAGS, studentId],
        queryFn: () => getStudentTrendsAndFlags(studentId!, token!),
        enabled: !!studentId && !!token,
      },
    ],
    combine: (result) => ({
      districtData: result[0].data,
      studentDashboardData: result[1].data,
      studentDetails: result[2].data,
      studentActions: result[3].data,
      trendsFlagsData: result[4].data,
      isLoading: result.some((d) => d.isLoading),
      hasAnyError: result.some((d) => d.isError),
      isAllSuccess: result.every((d) => d.isSuccess),
    }),
  });

  const hasAllData = districtData && studentDashboardData && studentDetails && studentActions && trendsFlagsData;

  if (isLoading || hasAnyError || !token || !hasAllData) return null;

  const convertedSupportByTypeData = getTransformObjectArrayToLabelValuePairs(studentDashboardData.circle_of_support.support_by_type);
  const convertedSupportByProgramData = getTransformObjectArrayToLabelValuePairs(studentDashboardData.circle_of_support.support_by_program);
  const convertedSupportByRoleData = getTransformObjectArrayToLabelValuePairs(studentDashboardData.circle_of_support.support_by_role);
  const convertedSupportByNeedData = getTransformObjectArrayToLabelValuePairs(studentDashboardData.circle_of_support.support_by_need);

  const transformedSupportByTypeData = processChartData(convertedSupportByTypeData);
  const transformedSupportByProgramData = processChartData(convertedSupportByProgramData);
  const transformedSupportByRoleData = processChartData(convertedSupportByRoleData);
  const transformedSupportByNeedData = processChartData(convertedSupportByNeedData);

  const { config: supportByTypeConfig, data: supportByTypeData } = createConfigAndData(transformedSupportByTypeData);
  const { config: supportByProgramTypeConfig, data: supportByProgramTypeData } = createConfigAndData(transformedSupportByProgramData);
  const { config: supportByRoleConfig, data: supportByRoleData } = createConfigAndData(transformedSupportByRoleData);
  const { config: supportByNeedConfig, data: supportByNeedData } = createConfigAndData(transformedSupportByNeedData);

  return (
    <div className="mx-auto w-full max-w-[794px] bg-white px-4 pt-4" id={isAllSuccess ? 'download-ready' : ''}>
      <PageHeader districtInfo={districtData} />
      <div className="pt-4">
        <StudentDetailsSection studentDetailsData={studentDetails} />
        <StudentCardSection total_safs={studentDashboardData.insight.total_safs} totals={studentActions.totals} />
        <section className="flex flex-col gap-2">
          <Text variant="size-12" type="body-600">Visual Dashboard</Text>
          <div className="grid grid-cols-2 gap-x-3 gap-y-2">
            <LineChartSection actions={studentDashboardData.attendance.actions} attendance={studentDashboardData.attendance.attendance} />
            <Card className="col-span-2 px-1 py-2">
              <CardHeader className="flex flex-row items-center justify-between gap-2 space-y-0 rounded-none">
                <Text variant="size-10" type="body-600">
                  Color Trends
                </Text>
              </CardHeader>
              <CardContent className="mt-1 h-[190px]">
                <StackedBarChart
                  barSize={30}
                  legend={() => <LegendComponent colorRange={trendsFlagsData.flags_plot.colors} containerClassName="gap-2" labelsClassName="text-[10px]" colorIndicatorClassName="size-2" />}
                  customXAxis={<CustomizedGroupTick fontSize={8} />}
                  data={convertPlot(trendsFlagsData.flags_plot.filtered_cycle_progress, trendsFlagsData.flags_plot.colors)}
                  barChartMargins={{ right: 5 }}
                  yAxisTick={{ fontSize: 10, fontWeight: 400, textAnchor: 'start', dx: -15 }}
                  yAxisWidth={30}
                  xAxisLabelSettings={{ value: 'Month', position: 'left', offset: 14, fill: '#4E617B', dy: 0, dx: 15, fontSize: 8, fontWeight: 400 }}

                />
              </CardContent>
            </Card>
            <div className="col-span-2 grid break-after-page grid-cols-2 gap-x-3 gap-y-2">
              <PieChartSection title="Support by Type" chartData={supportByTypeData} chartConfig={supportByTypeConfig} />
              <PieChartSection title="Support by Program Type" chartData={supportByProgramTypeData} chartConfig={supportByProgramTypeConfig} />
            </div>
            <PieChartSection title="Support by Role" chartData={supportByRoleData} chartConfig={supportByRoleConfig} />
            <PieChartSection title="Support by Need" chartData={supportByNeedData} chartConfig={supportByNeedConfig} />
          </div>
        </section>
      </div>
    </div>
  );
};
