import { useMemo } from 'react';
import { TAGS_TYPES, type TSchoolInfo } from '@purple/shared-types';
import { getInitialsFromName, isFieldExist } from '@purple/shared-utils';
import { Avatar, AvatarFallback, AvatarImage, Heading, InfoItem, Skeleton, Text } from '@purple/ui';
import { ChoiceTag, InfoItemValue } from '~/components';
import { useCustomTags } from '~/queries';

type TSchoolInfoHeaderProperties = React.PropsWithChildren<{
  data: TSchoolInfo;
}>;

const SchoolInfoHeader = ({ data }: TSchoolInfoHeaderProperties) => {
  const {
    id,
    name,
    description,
    logo,
    principal,
    wraparound_resource_manager,
    wraparound_resource_specialist_1,
    wraparound_resource_specialist_2,
  } = data;

  const { data: tags, isFetching: isTagsFetching } = useCustomTags({
    content_type: TAGS_TYPES.SCHOOL,
    object_id: id,
  });
  const schoolTags = useMemo(() => tags?.results ?? [], [tags]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-3">
        <Avatar size={60}>
          <AvatarImage src={logo || undefined} alt={name} className="object-cover" />
          <AvatarFallback className="bg-grey-200">{getInitialsFromName(name ?? '- -')}</AvatarFallback>
        </Avatar>
        <div className="flex flex-col gap-1">
          <Heading tag="h2" variant="size-18" type="heading-600">
            {name}
          </Heading>
          <div className="flex flex-wrap gap-2">
            {isTagsFetching && (
              <>
                <Skeleton className="h-6 w-20 rounded-full" />
                <Skeleton className="h-6 w-20 rounded-full" />
                <Skeleton className="h-6 w-20 rounded-full" />
              </>
            )}
            {schoolTags.map(({ color, name, id }) => (
              <ChoiceTag key={id} color={color}>{name}</ChoiceTag>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {isFieldExist(principal) && (
          <InfoItem label="Principal">
            <InfoItemValue>{principal?.full_name}</InfoItemValue>
          </InfoItem>
        )}
        {isFieldExist(wraparound_resource_manager) && (
          <InfoItem label="Resource Manager">
            <InfoItemValue>{wraparound_resource_manager?.full_name}</InfoItemValue>
          </InfoItem>
        )}
        {/*
         * Right now Resource Specialists are two separated field but it can be more here.
         * It can be changed on BE in some future tasks
         */}
        {isFieldExist(wraparound_resource_specialist_1) && (
          <InfoItem label="Resource Specialist">
            <InfoItemValue>{wraparound_resource_specialist_1?.full_name}</InfoItemValue>
          </InfoItem>
        )}
        {isFieldExist(wraparound_resource_specialist_2) && (
          <InfoItem label="Resource Specialist">
            <InfoItemValue>{wraparound_resource_specialist_2?.full_name}</InfoItemValue>
          </InfoItem>
        )}
        <div className="flex flex-col gap-1">
          <Text variant="size-16" type="body-600" className="text-grey-950">
            Description
          </Text>
          <Text variant="size-16" type="body-400" className="text-grey-600">
            {description || '-'}
          </Text>
        </div>
      </div>
    </div>
  );
};

export { SchoolInfoHeader };
