import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';
import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { cutGradeName } from '@purple/shared-utils';
import {
  AccessDenied,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  ReChartContainer,
  ReChartTooltip,
  ReChartTooltipContent,
} from '@purple/ui';
import { PERIOD_SELECT_OPTIONS, TIME_PERIODS } from '~/constants/options';
import { AppRoutes } from '~/constants/routes/routes';
import { useDashboardNewStudents } from '~/queries';
import { NewStudentsSkeleton } from './NewStudentsSkeleton';
import type { TChartConfig } from '@purple/ui';

const chartConfig = {
  students: {
    label: 'Students',
    color: '#6EC75F',
  },
} satisfies TChartConfig;

export const NewStudents: React.FC = () => {
  const navigate = useNavigate();
  const { hasPermissions } = usePermissions();

  const [newStudentPeriod, setNewStudentPeriod] = useState<string | undefined>(TIME_PERIODS.last_30_days);

  const hasUserPermissions = hasPermissions(APP_PERMISSIONS.CAN_ACCESS_NEW_STUDENTS_VISUAL);

  const { data: newStudentsData, isFetching } = useDashboardNewStudents({ enabled: hasUserPermissions, time_period: newStudentPeriod });

  const chartData = useMemo(
    () =>
      newStudentsData?.map((item) => ({
        name: cutGradeName(item.grade),
        students: item.number_of_students,
      })) || [],
    [newStudentsData],
  );

  const total = useMemo(() => chartData?.reduce((accumulator, item) => accumulator + item.students, 0), [chartData]);

  const learnMoreClickHandler = () => {
    navigate(AppRoutes.App.NewStudentsReport.Root.makePath(newStudentPeriod));
  };

  if (!hasUserPermissions) {
    return (
      <div className="flex min-h-[364px] w-full flex-col rounded-lg border border-grey-200 bg-white shadow-custom-medium">
        <AccessDenied accessDeniedMessage="Sorry, you don't have access to this widget" />
      </div>
    );
  }

  if (isFetching) {
    return <NewStudentsSkeleton />;
  }

  return (
    <Card className="gap-4 px-4 py-6 shadow-custom-medium">
      <CardHeader className="flex-row items-center justify-between gap-2 space-y-0">
        <CardTitle className="text-base font-semibold">New Students</CardTitle>
        <CardDescription className="text-grey-600">
          <RadixSelect onValueChange={setNewStudentPeriod} value={newStudentPeriod}>
            <RadixSelectTrigger
              hasClearButton={Boolean(newStudentPeriod)}
              onClearCallback={() => setNewStudentPeriod(undefined)}
              triggerContainerClassName="w-[180px]"
            >
              <RadixSelectValue placeholder="Time Period" />
            </RadixSelectTrigger>
            <RadixSelectContent>
              {PERIOD_SELECT_OPTIONS.map(({ label, value }) => (
                <RadixSelectItem key={value} value={value}>
                  {label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
        </CardDescription>
      </CardHeader>
      <CardContent className="flex-col items-center gap-4">
        <p className="inline-flex w-full flex-col">
          <strong className="text-4xl font-extrabold text-grey-title">{total}</strong>
          <span className="text-sm font-medium text-grey-600">Total Students</span>
        </p>
        <ReChartContainer config={chartConfig} className="max-h-[144px] w-full shrink-0">
          <BarChart data={[...chartData]}>
            <CartesianGrid vertical={false} strokeDasharray="3 3" strokeWidth={1} shapeRendering="crispEdges" />
            <YAxis
              dataKey="students"
              width={40}
              tickSize={40}
              interval={0}
              tickCount={5}
              tick={{ fontSize: 10, fontWeight: 500, textAnchor: 'left' }}
              tickLine={false}
              axisLine={false}
            />
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              interval={0}
              tick={{ fontSize: 10, fontWeight: 500 }}
              label={{
                value: 'Grade',
                position: 'left',
                className: 'fill-grey-600',
                fontSize: 10,
                dx: 0,
                dy: -4,
                lineHeight: 12,
                fontWeight: 400,
              }}
            />
            <ReChartTooltip cursor={false} content={<ReChartTooltipContent hideLabel />} />
            <Bar dataKey="students" fill="var(--color-students)" radius={2} />
          </BarChart>
        </ReChartContainer>
      </CardContent>
      <CardFooter className="mt-auto justify-center">
        <Button type="button" variant="primary" onClick={learnMoreClickHandler}>
          Learn More
        </Button>
      </CardFooter>
    </Card>
  );
};
