import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { districtsCheck } from '@purple/shared-services';
import { DISTRICT_STATUS, type TDistrictInfo, type TDistrictStatus } from '@purple/shared-types';
import { DISTRICTS_QUERY_KEYS, getSubdomain } from '@purple/shared-utils';
import { AppRoutes } from '~/constants/routes/routes';
import { useAppDispatch } from '~/hooks/redux';
import { setDistrict } from '~/store/features/district';
import type { AxiosError } from '@purple/shared-services';

export const useGetDistrictsCheck = (enabled = true) => {
  const subdomain = getSubdomain();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isError, error, data, isLoading, isSuccess } = useQuery<TDistrictInfo, AxiosError<{
    status: TDistrictStatus;
    detail: string;
  }>>({
    queryKey: [DISTRICTS_QUERY_KEYS.CHECK],
    queryFn: () => districtsCheck(subdomain),
    enabled: !!subdomain && enabled,
  });

  useEffect(() => {
    if (data && isSuccess) {
      // TODO apply entity converter
      dispatch(setDistrict(data));
    }
  }, [data, dispatch, isSuccess]);

  useEffect(() => {
    if (isError) {
      if (error.response && error.response.status === 403 && error.response?.data?.status === DISTRICT_STATUS.DEACTIVATED) {
        navigate(AppRoutes.System.DeactivatedDistrict.Root.path);
      } else if (error.response && error.response.status === 401) {
        navigate(AppRoutes.Auth.SignIn.Root.path);
      } else {
        navigate(AppRoutes.System.NotFound.Root.path);
      }
    }
  }, [isError, error, navigate]);

  return { isLoading, isError, data };
};
