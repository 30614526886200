import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getTotalIncidentsAttendance } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TTotalIncidentRequestParameters } from '@purple/shared-types';

export const useAttendanceAnalysisReport = (parameters: TTotalIncidentRequestParameters) => {
  const { isError, ...rest } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_TOTAL_INCIDENTS_ATTENDANCE_TABLE_DATA, parameters],
    queryFn: () => getTotalIncidentsAttendance(parameters),
    select: (data) => Object.entries(data).map(([key, value]) => ({
      month: key,
      current: value.current_year,
      previous: value.previous_year,
    })),
    enabled: !!parameters.studentId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'Failed to load Attendance Analysis report');
    }
  }, [isError]);

  return { isError, ...rest };
};
