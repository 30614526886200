import type { TFiltersConfig } from '@purple/ui';

export const filterConfig: TFiltersConfig = {
  categories: [
    {
      label: 'Color Trend',
      value: 'color_trend',
      filters: [
        {
          label: 'Red',
          value: 'red',
        },
        {
          label: 'Yellow',
          value: 'yellow',
        },
        {
          label: 'Green',
          value: 'green',
        },
      ],
    },
  ],
};

export const TOTAL_FLAGS_SORT_SELECT_OPTIONS = [
  {
    label: 'Attendance (ASC)',
    value: 'attendance_cc',
  },
  {
    label: 'Attendance (DESC)',
    value: '-attendance_cc',
  },
  {
    label: 'Behavior (ASC)',
    value: 'behavior_cc',
  },
  {
    label: 'Behavior (DESC)',
    value: '-behavior_cc',
  },
  {
    label: 'Total Flags Number (ASC)',
    value: 'total_flags_cc',
  },
  {
    label: 'Total Flags Number (DESC)',
    value: '-total_flags_cc',
  },
] as const;
