import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getTotalFlags } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TStudentTotalFlagsRequestParameters } from '@purple/shared-types';

export const useTotalFlagsReport = (parameters: TStudentTotalFlagsRequestParameters) => {
  const { isError, ...rest } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_TOTAL_FLAGS_TABLE_DATA, parameters],
    queryFn: () => getTotalFlags(parameters),
    enabled: !!parameters.studentId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'Failed to load total flags report');
    }
  }, [isError]);

  return { isError, ...rest };
};
