import { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DonutChartCard,
  Heading,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Skeleton,
} from '@purple/ui';
import DownloadableWrapper from '~/components/DownloadableWrapper/DownloadableWrapper';
import { useSchoolOverview, useSchoolOverviewLinkedStudents } from '~/queries';
import { OverviewStatisticCard, SchoolDonutChartWrapper } from './components';
import { LINKED_STUDENTS_CHART_TYPE_OPTIONS } from './constants';
import type { DownloadableWrapperReference } from '~/components/DownloadableWrapper/DownloadableWrapper';

export const Overview: React.FC = () => {
  const { schoolId } = useParams();

  const safChartRef = useRef<DownloadableWrapperReference>(null);
  const actionsChartRef = useRef<DownloadableWrapperReference>(null);
  const programsChartRef = useRef<DownloadableWrapperReference>(null);
  const colorTrentChartRef = useRef<DownloadableWrapperReference>(null);
  const activeStudentsChartRef = useRef<DownloadableWrapperReference>(null);
  const subpopulationsChartRef = useRef<DownloadableWrapperReference>(null);

  const [selectedLinkedType, setSelectedLinkedType] = useState<string>(LINKED_STUDENTS_CHART_TYPE_OPTIONS[0].value);

  const {
    data: schoolOverviewData,
    isLoading: isSchoolDataLoading,
    isError: isSchoolDataError,
  } = useSchoolOverview(schoolId as string);

  const {
    data: linkedStudentsData,
    isLoading: isSchoolDataLinkedStudentsLoading,
    isError: isSchoolDataLinkedStudentsError,
  } = useSchoolOverviewLinkedStudents({
    schoolId: schoolId as string,
    chart_type: selectedLinkedType,
  });

  const { safs, actions, programs, color_trend, subpopulations, chronically_absent, ada } = schoolOverviewData || {};
  const { linked_students } = linkedStudentsData || {};

  const downloadSafChartHandler = useCallback(() => {
    safChartRef.current?.downloadImage({
      scale: 2,
      quality: 1,
      format: 'png',
    });
  }, [safChartRef]);

  const downloadActionsChartHandler = useCallback(() => {
    actionsChartRef.current?.downloadImage({
      scale: 2,
      quality: 1,
      format: 'png',
    });
  }, [actionsChartRef]);

  const downloadProgramsChartHandler = useCallback(() => {
    programsChartRef.current?.downloadImage({
      scale: 2,
      quality: 1,
      format: 'png',
    });
  }, [programsChartRef]);

  const downloadColorTrendChartHandler = useCallback(() => {
    colorTrentChartRef.current?.downloadImage({
      scale: 2,
      quality: 1,
      format: 'png',
    });
  }, [colorTrentChartRef]);

  const downloadActiveStudentsChartHandler = useCallback(() => {
    activeStudentsChartRef.current?.downloadImage({
      scale: 2,
      quality: 1,
      format: 'png',
    });
  }, [activeStudentsChartRef]);

  const downloadSubpopulationsChartHandler = useCallback(() => {
    subpopulationsChartRef.current?.downloadImage({
      scale: 2,
      quality: 1,
      format: 'png',
    });
  }, [subpopulationsChartRef]);

  return (
    <div className="flex flex-col gap-2 p-4 py-6">
      <div className="flex">
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          School Data Overview
        </Heading>
      </div>
      <div className="grid grid-flow-row grid-cols-2 gap-4">
        {isSchoolDataLoading
          ? (
              <Skeleton className="h-[136px] w-full" />
            )
          : (
              <OverviewStatisticCard
                title="Chronically Absent"
                value={chronically_absent as number}
                onViewReport={() => null}
                betterIs="decrease"
              />
            )}
        {isSchoolDataLoading
          ? (
              <Skeleton className="h-[136px] w-full" />
            )
          : (
              <OverviewStatisticCard title="ADA" value={ada as number} onViewReport={() => null} betterIs="increase" />
            )}
      </div>
      <div className="grid grid-flow-row grid-cols-2 gap-4">
        <SchoolDonutChartWrapper
          isLoading={isSchoolDataLoading || isSchoolDataError}
          className="h-[334px] w-full"
        >
          <DownloadableWrapper ref={safChartRef} fileName={`SAFs for School ${schoolId}`}>
            <DonutChartCard
              onClickDownload={downloadSafChartHandler}
              onClickViewReport={() => null}
              data={safs || []}
              title="SAFs"
              actionTitle="View Report"
            />
          </DownloadableWrapper>
        </SchoolDonutChartWrapper>
        <SchoolDonutChartWrapper
          isLoading={isSchoolDataLoading || isSchoolDataError}
          className="h-[334px] w-full"
        >
          <DownloadableWrapper ref={actionsChartRef} fileName={`Actions for School ${schoolId}`}>
            <DonutChartCard
              onClickDownload={downloadActionsChartHandler}
              onClickViewReport={() => null}
              data={actions || []}
              title="Actions"
              actionTitle="View Report"
            />
          </DownloadableWrapper>
        </SchoolDonutChartWrapper>
        <SchoolDonutChartWrapper
          isLoading={isSchoolDataLoading || isSchoolDataError}
          className="h-[334px] w-full"
        >
          <DownloadableWrapper ref={programsChartRef} fileName={`Programs for School ${schoolId}`}>
            <DonutChartCard
              onClickDownload={downloadProgramsChartHandler}
              onClickViewReport={() => null}
              data={programs || []}
              title="Programs"
              actionTitle="View Report"
            />
          </DownloadableWrapper>
        </SchoolDonutChartWrapper>
        <SchoolDonutChartWrapper
          isLoading={isSchoolDataLoading || isSchoolDataError}
          className="h-[334px] w-full"
        >
          <DownloadableWrapper ref={colorTrentChartRef} fileName={`Color Trend for School ${schoolId}`}>
            <DonutChartCard
              onClickDownload={downloadColorTrendChartHandler}
              onClickViewReport={() => null}
              data={color_trend || []}
              title="Color Trend"
              actionTitle="View Report"
            />
          </DownloadableWrapper>
        </SchoolDonutChartWrapper>
        <SchoolDonutChartWrapper
          isLoading={isSchoolDataLinkedStudentsLoading || isSchoolDataLinkedStudentsError}
          className="h-[408px] w-full"
          loaderVariant="spinner"
        >
          <DownloadableWrapper ref={activeStudentsChartRef} fileName={`Active Students for School ${schoolId}`}>
            <DonutChartCard
              onClickDownload={downloadActiveStudentsChartHandler}
              onClickViewReport={() => null}
              data={linked_students || []}
              title="Active Students"
              actionTitle="View Report"
              content={(
                <div className="max-w-[270px] px-4">
                  <RadixSelect onValueChange={setSelectedLinkedType} defaultValue={selectedLinkedType}>
                    <RadixSelectTrigger>
                      <RadixSelectValue placeholder="Select type" />
                    </RadixSelectTrigger>
                    <RadixSelectContent>
                      {LINKED_STUDENTS_CHART_TYPE_OPTIONS.map(({ label, value }) => (
                        <RadixSelectItem key={value} value={value}>
                          {label}
                        </RadixSelectItem>
                      ))}
                    </RadixSelectContent>
                  </RadixSelect>
                </div>
              )}
            />
          </DownloadableWrapper>
        </SchoolDonutChartWrapper>
        <SchoolDonutChartWrapper
          isLoading={isSchoolDataLoading || isSchoolDataError}
          className="h-[334px] w-full"
        >
          <DownloadableWrapper ref={subpopulationsChartRef} fileName={`Subpopulations for School ${schoolId}`}>
            <DonutChartCard
              onClickDownload={downloadSubpopulationsChartHandler}
              onClickViewReport={() => null}
              data={subpopulations || []}
              title="Subpopulations"
              actionTitle="View Report"
            />
          </DownloadableWrapper>
        </SchoolDonutChartWrapper>
      </div>
    </div>
  );
};
