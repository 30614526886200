import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSafTextTranslations } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import { convertSafTextTranslationsToView } from './converter';

export const useSafTextTranslations = () => {
  const { isError, data, ...props } = useQuery({
    queryKey: [SAF_QUERY_KEYS.TEXT_TRANSLATIONS],
    queryFn: () => getSafTextTranslations(),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Error', 'Failed to fetch SAF translations');
    }
  }, [isError]);

  return { ...props, data: data ? convertSafTextTranslationsToView(data) : data, isError };
};
