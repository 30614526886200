import { type FC, memo } from 'react';
import { Card, CardData, CardDataValue, CardTitle, Text } from '@purple/ui';

type TStudentCardSectionProps = {
  total_safs: number;
  totals: {
    general: number;
    targeted: number;
    attempted: number;
  };
};

export const StudentCardSection: FC<TStudentCardSectionProps> = memo(({ total_safs, totals }) => {
  return (
    <section className="my-2.5 flex flex-col gap-2">
      <Text variant="size-12" type="body-600">Student Data</Text>
      <div className="grid grid-cols-4 gap-x-2">
        <Card className="p-2">
          <CardTitle className="text-xs">Total SAFs</CardTitle>
          <CardData value={total_safs}>
            <CardDataValue className="text-lg text-grey-title" />
          </CardData>
        </Card>
        <Card className="p-2">
          <CardTitle className="text-xs">General Actions</CardTitle>
          <CardData value={totals.general}>
            <CardDataValue className="text-lg text-grey-title" />
          </CardData>
        </Card>
        <Card className="p-2">
          <CardTitle className="text-xs">Targeted Actions</CardTitle>
          <CardData value={totals.targeted}>
            <CardDataValue className="text-lg text-grey-title" />
          </CardData>
        </Card>
        <Card className="p-2">
          <CardTitle className="text-xs">Attempted Actions</CardTitle>
          <CardData value={totals.attempted}>
            <CardDataValue className="text-lg text-grey-title" />
          </CardData>
        </Card>
      </div>
    </section>
  );
});
