import { z } from 'zod';

export const tagSchema = z.object({
  id: z.string(),
  name: z.string().trim().min(1, { message: 'Tag name is required' }).max(100, { message: 'Tag name is too long' }),
  color: z.string().trim().min(1, { message: 'Tag color is required' }).max(100, { message: 'Tag color is too long' }),
});

export const tagsSchema = z.object({
  tags: z.array(tagSchema),
});
