import * as z from 'zod';
import { ACCEPTED_SCHOOL_FILE_TYPES, MAX_SCHOOL_FILE_SIZE, multiSelectOptions } from '@purple/shared-utils';

export const uploadSchoolFileSchema = z.object({
  file: z
    .custom<File>((value) => value instanceof File, {
      message: 'Invalid file type.',
    })
    .refine(
      (file) => ACCEPTED_SCHOOL_FILE_TYPES.has(file?.type),
      'Only .jpg, .jpeg, .png and .pdf formats are supported.',
    )
    .refine((file) => file?.size <= MAX_SCHOOL_FILE_SIZE, 'Unable to upload a file that is more than 10MB.'),
  is_private: z.boolean().default(false),
  viewers: multiSelectOptions.default([]),
});
