import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getActionTypes } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TActionTypes } from '@purple/shared-types';
import type { TCustomUseQueryOptions } from '~/types';

export const useActionTypes = (
  options?: TCustomUseQueryOptions<TActionTypes, AxiosError>,
) => {
  const query = useQuery({
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_TYPES],
    queryFn: getActionTypes,
    ...options,
  });

  useEffect(() => {
    if (query.isError) {
      showErrorToast('System Error', 'Failed to fetch action types. Please try again later.');
    }
  }, [query.isError]);

  return query;
};
