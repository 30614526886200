import { createApiInstance, getAxiosInstance } from '@purple/shared-services';
import { useAppDispatch } from '~/hooks';
import { resetApp } from '~/store';

type IApiProviderProperties = {
  children: React.ReactNode;
};
export const ApiProvider = ({ children }: IApiProviderProperties) => {
  const dispatch = useAppDispatch();

  createApiInstance({
    baseURL: import.meta.env.VITE_API_URL,
    interceptorsErrorCallback: () => {
      // Reset the hole app state
      dispatch(resetApp());
      // Full clear the local storage
      localStorage.clear();
    },
  });

  const instance = getAxiosInstance();

  if (!instance) {
    throw new Error('Axios instance is not created');
  }

  return children;
};
