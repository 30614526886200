import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { Text } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import type { ColumnDef } from '@tanstack/react-table';
import type { TDashboardNewStudentsItem } from '@purple/shared-types';

export const useReportColumns = (time_period: string) => {
  const columnHelper = createColumnHelper<TDashboardNewStudentsItem>();

  const columns: ColumnDef<TDashboardNewStudentsItem, string>[] = useMemo(() => {
    const data = [
      columnHelper.accessor(() => 'grade', {
        id: 'grade',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap uppercase">
            Grade
          </Text>
        ),
        cell: ({ row }) => (
          <Link to={AppRoutes.App.GradeReport.Root.makePath(time_period, row.original.grade)}>
            <Text variant="size-14" type="body-500" className="text-brand-blue-700 line-clamp-2">
              {row.original.grade || '-'}
            </Text>
          </Link>
        ),
      }),
      columnHelper.accessor(() => 'number_of_students', {
        id: 'number_of_students',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap uppercase">
            # of student
          </Text>
        ),
        cell: ({ row }) => (
          <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-2">
            {row.original.number_of_students || '-'}
          </Text>
        ),
      }),
    ];
    return data;
  }, [columnHelper, time_period]);

  return columns;
};
