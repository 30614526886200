import { Link } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { Button, Text } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { AppRoutes } from '~/constants/routes/routes';
import { useSafSubmittersReport, useSafSubmittersReportExport } from '~/queries';
import { showSuccessToast } from '~/shared/lib';
import { useReportColumns } from './useReportColumns';

const SafSubmittersReport = () => {
  const { hasPermissions } = usePermissions();
  const hasUserPermissions = hasPermissions(APP_PERMISSIONS.CAN_ACCESS_NEW_STUDENTS_VISUAL);

  const { data: reportData, isFetching } = useSafSubmittersReport({
    enabled: hasUserPermissions,
  });

  const { mutate: exportReport, isPending: isExportingReport } = useSafSubmittersReportExport();

  const columns = useReportColumns();

  const exportClickHandler = () => {
    if (exportReport) {
      exportReport(undefined, {
        onSuccess: () => {
          showSuccessToast('System message', 'The file has been successfully exported and downloaded to the device');
        },
      });
    }
  };

  return (
    <div className="flex w-full flex-1 flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="text-brand-blue-700 size-4 shrink-0" />}
        className="h-auto p-1 font-semibold"
        asChild
      >
        <Link to={AppRoutes.App.Home.Root.path}>
          Back to Dashboard
        </Link>
      </Button>

      <div className="border-grey-200 shadow-custom-heavy col-span-2 flex w-full flex-col gap-0 rounded-lg border bg-white">
        <div className="flex items-center justify-between gap-4 px-4 py-6">
          <Text variant="size-16" type="body-600" className="text-gray-title">
            SAF Submitters
          </Text>
          <Button iconLeft={<PurpleIcon name="download" />} onClick={exportClickHandler} isLoading={isExportingReport}>Export</Button>
        </div>
        <div className="flex flex-col gap-5">
          <div>
            <DataTable
              columns={columns}
              data={reportData || []}
              isLoading={isFetching}
              emptyStateTitle="No SAFs submitters found"
              emptyStateMessage="There are no SAFs submitters to display. If it an error, please contact support."
              skeleton={<DataTableSkeleton rows={5} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { SafSubmittersReport };
