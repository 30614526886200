import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { Text } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import type { ColumnDef } from '@tanstack/react-table';
import type { TDashboardGradeStudentsItem } from '@purple/shared-types';

export const useReportColumns = () => {
  const columnHelper = createColumnHelper<TDashboardGradeStudentsItem>();

  const columns: ColumnDef<TDashboardGradeStudentsItem, string>[] = useMemo(() => {
    const data = [
      columnHelper.accessor(() => 'student', {
        id: 'student',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-nowrap uppercase text-grey-600">
            Student
          </Text>
        ),
        cell: ({ row }) => (
          <Link to={AppRoutes.App.Students.StudentDetails.Root.makePath(row.original.id, 'dashboard')}>
            <Text variant="size-14" type="body-500" className="line-clamp-2 text-brand-blue-700">
              {row.original.full_name || '-'}
            </Text>
          </Link>
        ),
      }),
      columnHelper.accessor(() => 'student_status', {
        id: 'student_status',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-nowrap uppercase text-grey-600">
            Status
          </Text>
        ),
        cell: ({ row }) => (
          <Text variant="size-14" type="body-500" className="line-clamp-2 text-grey-950">
            {row.original.student_status || '-'}
          </Text>
        ),
      }),
    ];
    return data;
  }, [columnHelper]);

  return columns;
};
