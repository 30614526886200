import { type FC, useCallback, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import { PRINT_OPTIONS, TakeActionType } from '@purple/shared-types';
import { PRINT_QUERY_KEYS, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Button, Checkbox, cn, Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, ScrollArea, Separator } from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { TIME_PERIODS } from '~/constants/options';
import { useModal } from '~/hooks';
import { usePrint } from '~/queries';
import { showErrorToast } from '~/shared/lib';
import { GroupCheckBoxes } from './GroupCheckBoxes';
import { studentDataPrintSettingsModalSchema } from './schema';
import type { TStudentDataPrintSettingsSchema } from './schema';

export const StudentPrintSettingsModal: FC = () => {
  const { studentId } = useParams();
  const { isOpen, closeModal } = useModal(ModalType.PRINT_STUDENT_SETTINGS);

  const formId = useId();

  const { mutate, isPending } = usePrint(PRINT_QUERY_KEYS.PRINT_STUDENT_DATA);

  const actionTypesOptions = useMemo(() => Object.values(TakeActionType), []);
  const colorTrendsOptions = useMemo(() => ['total_flags', 'attendance_trends', 'behavior_analysis'], []);
  const safsOptions = useMemo(() => ['description', 'resolution'], []);

  const defaultValues: TStudentDataPrintSettingsSchema = useMemo(() => ({
    general_category: true,
    targeted_category: true,
    attempted_category: true,
    color_trends_category: true,
    safs_category: true,
    time_period: TIME_PERIODS.this_academic_year,
    is_private: false,
    student_color_trends: colorTrendsOptions,
    safs: safsOptions,
    general: actionTypesOptions,
    targeted: actionTypesOptions,
    attempted: actionTypesOptions,
  }), [actionTypesOptions, colorTrendsOptions, safsOptions]);

  const form = useForm<TStudentDataPrintSettingsSchema>({
    mode: 'onChange',
    resolver: zodResolver(studentDataPrintSettingsModalSchema),
    defaultValues,
  });

  const clearFormHandler = useCallback(() => {
    form.reset({
      general_category: false,
      targeted_category: false,
      attempted_category: false,
      color_trends_category: false,
      safs_category: false,
      time_period: TIME_PERIODS.this_academic_year,
      is_private: false,
      student_color_trends: [],
      safs: [],
      general: [],
      targeted: [],
      attempted: [],
    });
  }, [form]);

  const closeModalHandler = useCallback(() => {
    closeModal();
    form.reset(defaultValues);
  }, [closeModal, form, defaultValues]);

  const printStudentInformationData = useCallback((formValues: TStudentDataPrintSettingsSchema) => {
    if (!studentId) {
      showErrorToast('System message', `Unable to print student information with the provided student id: ${studentId}`);
      return;
    }
    const testRequestObject = {
      variant: PRINT_OPTIONS.STUDENT_DATA,
      studentId,
      time_period: formValues.time_period,

      ...(formValues.is_private && { is_private: 'true' }),
      ...(formValues.student_color_trends.length && { student_color_trends: formValues.student_color_trends.join(',') }),
      ...(formValues.safs.length && { safs: formValues.safs.join(',') }),
      ...(formValues.general && { general: formValues.general.join(',') }),
      ...(formValues.targeted && { targeted: formValues.targeted.join(',') }),
      ...(formValues.attempted && { attempted: formValues.attempted.join(',') }),
    };
    mutate({
      ...testRequestObject,
      landscape: 'true',
      scale: 1,
    }, {
      onSuccess: () => {
        closeModalHandler();
      },
    });
  }, [mutate, studentId, closeModalHandler]);

  return (
    <Dialog open={isOpen} onOpenChange={closeModalHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
        <DialogHeader className="flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle className="leading-7 tracking-normal">Print Student Profile</DialogTitle>
            <DialogDescription className="text-grey-600">Select information from the Student Profile which you like to print.</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
          <Form providerProps={form} className="grid grid-cols-2 gap-4 p-6" id={formId} onSubmit={form.handleSubmit(printStudentInformationData)}>
            <FormField
              control={form.control}
              name="time_period"
              render={({ field, fieldState }) => (
                <FormItem className="col-span-2">
                  <FormLabel required>Time Period</FormLabel>
                  <RadixSelect onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <RadixSelectTrigger
                        className={cn({
                          'border-error-main': !!fieldState.error,
                        })}
                      >
                        <RadixSelectValue placeholder="Select Time Period" />
                      </RadixSelectTrigger>
                    </FormControl>
                    <RadixSelectContent>
                      {Object.keys(TIME_PERIODS).map((key) => (
                        <RadixSelectItem key={key} value={key}>
                          {snakeToCamelWithSpaces(key)}
                        </RadixSelectItem>
                      ))}
                    </RadixSelectContent>
                  </RadixSelect>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="col-span-2 space-y-2">
              <FormField
                control={form.control}
                name="is_private"
                render={({ field }) => (
                  <FormItem className="flex items-center gap-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        {...field}
                        value={field.value.toString()}
                        checked={field.value}
                        className="size-5"
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="text-sm font-medium text-grey-title">
                      My Notes
                    </FormLabel>
                  </FormItem>
                )}
              />
              {/* Other checkboxes if present */}
            </div>
            <GroupCheckBoxes
              categoryName="color_trends_category"
              categoryTitle="Color Trends"
              subcategoryName="student_color_trends"
              options={colorTrendsOptions}
            />
            <GroupCheckBoxes
              categoryName="safs_category"
              categoryTitle="SAFs"
              subcategoryName="safs"
              options={safsOptions}
            />
            <GroupCheckBoxes
              categoryName="general_category"
              categoryTitle="Actions (General)"
              subcategoryName="general"
              options={actionTypesOptions}
            />
            <GroupCheckBoxes
              categoryName="targeted_category"
              categoryTitle="Actions (Targeted)"
              subcategoryName="targeted"
              options={actionTypesOptions}
            />
            <GroupCheckBoxes
              categoryName="attempted_category"
              categoryTitle="Actions (Attempted)"
              subcategoryName="attempted"
              options={actionTypesOptions}
            />
          </Form>
        </ScrollArea>
        <Separator />
        <DialogFooter className="flex justify-between">
          <Button variant="secondary" onClick={clearFormHandler}>
            Clear All
          </Button>
          <div className="flex items-center gap-4">
            <Button variant="tertiary" onClick={closeModalHandler}>Cancel</Button>
            <Button type="submit" form={formId} isLoading={isPending}>Print</Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
