import { NoDataAvailable, Text } from '@purple/ui';
import { NoteItem } from './NoteItem';
import type { FC } from 'react';
import type { TNoteStudentPrintItem } from '@purple/shared-types';

type TStudentNotesSectionProps = {
  studentNotesData: TNoteStudentPrintItem[];
};

export const StudentNotesSection: FC<TStudentNotesSectionProps> = ({ studentNotesData }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col">
        <Text variant="size-14" type="body-600" className="text-grey-title">Notes</Text>
        <Text variant="size-10" type="body-400" className="text-grey-800">
          {`Total ${studentNotesData.length} records`}
        </Text>
      </div>
      {studentNotesData.length > 0
        ? studentNotesData.map((note) => (
            <NoteItem key={note.id} note={note} />
          ))
        : (
            <NoDataAvailable iconName="pencil" iconSize={18} title="No Data Found" description="There are no notes to display" />
          )}
    </div>
  );
};
