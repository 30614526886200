export const ALL_ACTIVITIES_SORT_SELECT_OPTIONS = [
  {
    label: 'Name (ASC)',
    value: 'name',
  },
  {
    label: 'Name (DESC)',
    value: '-name',
  },
  {
    label: 'Type (ASC)',
    value: 'community_activity_type__name',
  },
  {
    label: 'Type (DESC)',
    value: '-community_activity_type__name',
  },
  {
    label: 'Created By (ASC)',
    value: 'created_by__last_name',
  },
  {
    label: 'Created By (DESC)',
    value: '-created_by__last_name',
  },
  {
    label: 'Date Created (ASC)',
    value: 'created_at',
  },
  {
    label: 'Date Created (DESC)',
    value: '-created_at',
  },
] as const;

export const CRISIS_SORT_SELECT_OPTIONS = [
  {
    label: 'Activity Name (ASC)',
    value: 'name',
  },
  {
    label: 'Activity Name (DESC)',
    value: '-name',
  },
  {
    label: 'Crisis Type (ASC)',
    value: 'crisis_type',
  },
  {
    label: 'Crisis Type (DESC)',
    value: '-crisis_type',
  },
  {
    label: 'Grade Level (ASC)',
    value: 'crisis_grade_level',
  },
  {
    label: 'Grade Level (DESC)',
    value: '-crisis_grade_level',
  },
] as const;
