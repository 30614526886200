import { useCallback, useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { convertToLabel, convertToValue } from '@purple/shared-utils';
import {
  AppFilters,
  Button,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  Heading,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
import { useSchoolFilterChoices } from '~/queries';
import { SCHOOL_FLAG_TYPE_OPTIONS, SCHOOL_YEARS, SchoolFlagType } from '../constants';
import type React from 'react';
import type { TSchoolItem } from '@purple/shared-types';
import type { TFiltersConfig } from '@purple/ui';
import type { TSchoolFlagType } from '../constants';

type TSchoolDataHeaderProperties = {
  /**
   * Currently selected flag in the parent component.
   */
  selectedFlag: TSchoolFlagType;
  /**
   * Currently selected year in the parent component.
   */
  selectedYear: string;
  /**
   * Callback function to update selected year in the parent component.
   * @param year - Selected year.
   */
  onYearChange?: (year: string) => void;
  /**
   * Callback function to update selected flag in the parent component.
   * @param flag - Selected flag.
   */
  onFlagChange?: (flag: TSchoolFlagType) => void;
};

export const SchoolDataHeader: React.FC<TSchoolDataHeaderProperties> = (props) => {
  const { selectedFlag, selectedYear, onYearChange, onFlagChange } = props;

  const { data: filterChoices } = useSchoolFilterChoices();

  const filterConfig: TFiltersConfig = useMemo(
    () => ({
      categories:
        Object.entries(filterChoices ?? {})
          .flatMap<[string, Pick<TSchoolItem, 'id' | 'name'>[] | Record<string, string>]>(([key, value]) =>
            (key === 'school' && Array.isArray(value) && value.length > 1) || key !== 'school' ? [[key, value]] : [],
          )
          .map(([categoryLabel, filters]) => ({
            label: convertToLabel(categoryLabel),
            value: convertToValue(categoryLabel),
            filters: Array.isArray(filters)
              ? filters.map(({ id, name }) => ({ label: name, value: id }))
              : Object.entries(filters).map(([value, label]) => ({
                  label: convertToLabel(label),
                  value,
                })),
          })) ?? [],
    }),
    [filterChoices],
  );

  const flagSelectChangeHandler = useCallback(
    (flag: TSchoolFlagType) => {
      onFlagChange?.(flag);
    },
    [onFlagChange],
  );

  const yearSelectChangeHandler = useCallback(
    (year: string) => {
      onYearChange?.(year);
    },
    [onYearChange],
  );

  const printSchoolDataHandler = useCallback(() => {
    console.log('To be done'); // eslint-disable-line no-console
  }, []);

  return (
    <div className="flex w-full flex-col gap-4">
      <Heading variant="size-22" type="heading-600" tag="h2" className="text-grey-title">
        School Data
      </Heading>
      <div className="flex w-full flex-nowrap items-center justify-between gap-4">
        <div className="flex flex-nowrap items-center justify-start gap-4">
          <AppFilters config={filterConfig} />
          <RadixSelect value={selectedFlag} onValueChange={flagSelectChangeHandler}>
            <RadixSelectTrigger triggerContainerClassName="min-w-[200px]">
              <RadixSelectValue placeholder="Flag Type" />
            </RadixSelectTrigger>
            <RadixSelectContent className="w-auto" sideOffset={0}>
              {SCHOOL_FLAG_TYPE_OPTIONS.map(({ label, value }) => (
                <RadixSelectItem key={value} value={value} disabled={value !== SchoolFlagType.ATTENDANCE}>
                  {label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
        </div>
        <div className="flex flex-nowrap items-center justify-start gap-4">
          <ComboBox modal>
            <ComboBoxTrigger placeholder="Select year" selectedLabel={`Year: ${selectedYear}`} />
            <ComboBoxContent
              align="end"
              searchPlaceholder="Search"
              emptyContent="Year not found."
              className="min-w-[240px]"
            >
              {SCHOOL_YEARS.map(({ label, value }) => (
                <ComboBoxItem
                  key={value}
                  value={value}
                  selected={value === selectedYear}
                  onSelect={yearSelectChangeHandler}
                >
                  {label}
                </ComboBoxItem>
              ))}
            </ComboBoxContent>
          </ComboBox>
          <Button
            onClick={printSchoolDataHandler}
            variant="secondary"
            size="icon_40"
            iconLeft={<PurpleIcon name="printer" className="size-5" />}
          />
        </div>
      </div>
    </div>
  );
};
