import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { convertBooleanToText, cutGradesNames, isFieldExist } from '@purple/shared-utils';
import { InfoItem } from '@purple/ui';
import { InfoItemValue } from '~/components';
import type { TSchoolDetails } from '@purple/shared-types';

type TDetailsViewProperties = {
  data: TSchoolDetails;
};

const DetailsView = ({ data }: TDetailsViewProperties) => {
  const {
    name,
    school_level,
    grades,
    subtype,
    school_office,
    school_division,
    subgroup,
    is_active,
    phone,
    website,
    school_code,
    super_neighborhood,
  } = data;

  const formatPhoneNumber = (phoneNumber: string | null) => {
    // Parse the phone number string
    if (!phoneNumber) return '–';
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');

    if (parsedNumber) {
      // Format the number as (XXX) XXX-XXXX
      return parsedNumber.formatNational();
    }

    return phoneNumber;
  };

  const schoolLevelLabel = school_level.length > 0 ? school_level.map((level) => level).join(', ') : '-';
  const gradesLabel
    = grades.length > 0
      ? cutGradesNames(grades)
          .map((grade) => grade)
          .join(', ')
      : '-';

  return (
    <div className="flex flex-col gap-4">
      <InfoItem label="School Name">
        <InfoItemValue>{name}</InfoItemValue>
      </InfoItem>
      <InfoItem label="School Level">
        <InfoItemValue>{schoolLevelLabel}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Active Grades">
        <InfoItemValue>{gradesLabel}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Subtype">
        <InfoItemValue>{subtype}</InfoItemValue>
      </InfoItem>
      {isFieldExist(school_office) && (
        <InfoItem label="School Office">
          <InfoItemValue>{school_office}</InfoItemValue>
        </InfoItem>
      )}
      {isFieldExist(school_division) && (
        <InfoItem label="School Division">
          <InfoItemValue>{school_division}</InfoItemValue>
        </InfoItem>
      )}
      <InfoItem label="Subgroup">
        <InfoItemValue>{subgroup}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Neighborhood">
        <InfoItemValue>{super_neighborhood}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Active Site">
        <InfoItemValue>{convertBooleanToText(is_active)}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Phone Number">
        <InfoItemValue>{formatPhoneNumber(phone)}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Website">
        <InfoItemValue to={website} target="_blank">
          {website}
        </InfoItemValue>
      </InfoItem>
      <InfoItem label="School Code">
        <InfoItemValue to={school_code}>{school_code}</InfoItemValue>
      </InfoItem>
    </div>
  );
};

export { DetailsView };
