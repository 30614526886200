import { getAxiosInstance } from '../../api';
import { DASHBOARD_ENDPOINTS } from '../../constants/private-api';
import type { TDashboardGradeStudentsPayload } from '@purple/shared-types';

export const getDashboardGradeStudentsExport = async (parameters: TDashboardGradeStudentsPayload) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<Blob>(
    DASHBOARD_ENDPOINTS.GET_DASHBOARD_NEW_STUDENTS_GRADES_EXPORT,
    {
      params: parameters,
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    },
  );
  return response.data;
};
