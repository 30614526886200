export const PURPLE_USER_PARTICIPANT_SORT = [
  {
    label: 'Last Name (ASC)',
    value: 'last_name',
  },
  {
    label: 'Last Name (DESC)',
    value: '-last_name',
  },
  {
    label: 'Title (ASC)',
    value: 'title',
  },
  {
    label: 'Title (DESC)',
    value: '-title',
  },
] as const;
