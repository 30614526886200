export const NOTES_SORT_SELECT_OPTIONS = [
  {
    label: 'Title (ASC)',
    value: 'title',
  },
  {
    label: 'Title (DESC)',
    value: '-title',
  },
  {
    label: 'Created By (ASC)',
    value: 'created_by__last_name',
  },
  {
    label: 'Created By (DESC)',
    value: '-created_by__last_name',
  },
  {
    label: 'Last Modified Date (ASC)',
    value: 'updated_at',
  },
  {
    label: 'Last Modified Date (DESC)',
    value: '-updated_at',
  },
  {
    label: 'Last Modified By (ASC)',
    value: 'last_modified_by__last_name',
  },
  {
    label: 'Last Modified By (DESC)',
    value: '-last_modified_by__last_name',
  },
] as const;

export const LINKED_STUDENTS_SORT_SELECT_OPTIONS = [
  {
    label: 'Last Name (ASC)',
    value: 'last_name',
  },
  {
    label: 'Last Name (DESC)',
    value: '-last_name',
  },
  {
    label: 'Grade (ASC)',
    value: 'grade',
  },
  {
    label: 'Grade (DESC)',
    value: '-grade',
  },
  {
    label: 'School Name (ASC)',
    value: 'school_name',
  },
  {
    label: 'School Name (DESC)',
    value: '-school_name',
  },
] as const;
