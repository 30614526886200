import { useMutation } from '@tanstack/react-query';
import { getPrintData } from '@purple/shared-services';
import { showErrorToast } from '~/shared/lib';
import type { ValueOf } from '@purple/shared-types';
import type { PRINT_QUERY_KEYS } from '@purple/shared-utils';

export type TPrintKeysVariants = ValueOf<typeof PRINT_QUERY_KEYS>;

export const usePrint = (mutationKey: TPrintKeysVariants) => {
  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: getPrintData,
    onSuccess: (blob) => {
      const blobURL = URL.createObjectURL(blob);
      const newWindow = window.open(blobURL, '_blank');
      if (newWindow) {
        newWindow.focus();
      } else {
        const link = document.createElement('a');
        link.href = blobURL;
        link.download = `${mutationKey}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showErrorToast('System message', 'Could not generate the print preview. Downloading PDF instead');
      }
    },
    onError: () => {
      showErrorToast('System message', 'Could not generate the print preview. Please try again');
    },
  });
};
