import { useMutation } from '@tanstack/react-query';
import { getExportIncidents } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useExportBehaviorAnalysisReport = (studentId?: string) => {
  return useMutation({
    mutationKey: [STUDENTS_QUERY_KEYS.EXPORT_STUDENT_TOTAL_INCIDENTS_REPORT, studentId],
    mutationFn: getExportIncidents,
    onError: () => {
      showErrorToast('System message', 'Failed to export the table');
    },
    onSuccess: (response) => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = `Behavior Analysis Report for ${studentId}.xlsx`;
      document.body.append(link);
      link.click();
      link.remove();
      // Clean up the URL to prevent memory leaks
      window.URL.revokeObjectURL(url);
      showSuccessToast('System message', 'Exported document downloaded successfully. Please check your downloads folder.');
    },
  });
};
