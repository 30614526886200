import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { ReadableSafSubmitterTypeShortForm, type TDashboardSafSubmitterItem } from '@purple/shared-types';
import { Text } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';

export const useReportColumns = () => {
  const columnHelper = createColumnHelper<TDashboardSafSubmitterItem>();

  const columns: ColumnDef<TDashboardSafSubmitterItem, string>[] = useMemo(() => {
    const data = [
      columnHelper.accessor(() => 'submitter_type', {
        id: 'submitter_type',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap uppercase">
            Submitter type
          </Text>
        ),
        cell: ({ row }) => (
          <Text variant="size-14" type="body-500" className="text-grey-950 whitespace-nowrap">
            {ReadableSafSubmitterTypeShortForm[row.original.submitter_type as keyof typeof ReadableSafSubmitterTypeShortForm] || '-'}
          </Text>
        ),
      }),
      columnHelper.accessor(() => 'for_last_week', {
        id: 'for_last_week',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap uppercase">
            # FOR LAST WEEK
          </Text>
        ),
        cell: ({ row }) => (
          <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-2 text-center">
            {row.original.for_last_week}
          </Text>
        ),
      }),
      columnHelper.accessor(() => 'for_last_3_weeks', {
        id: 'for_last_3_weeks',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap uppercase">
            # FOR LAST 3 WEEKS
          </Text>
        ),
        cell: ({ row }) => (
          <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-2 text-center">
            {row.original.for_last_3_weeks}
          </Text>
        ),
      }),
      columnHelper.accessor(() => 'for_last_month', {
        id: 'for_last_month',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap uppercase">
            # FOR LAST MONTH
          </Text>
        ),
        cell: ({ row }) => (
          <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-2 text-center">
            {row.original.for_last_month}
          </Text>
        ),
      }),
      columnHelper.accessor(() => 'for_last_3_months', {
        id: 'for_last_3_months',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap uppercase">
            # FOR LAST 3 MONTH
          </Text>
        ),
        cell: ({ row }) => (
          <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-2 text-center">
            {row.original.for_last_3_months}
          </Text>
        ),
      }),
      columnHelper.accessor(() => 'for_last_6_months', {
        id: 'for_last_6_months',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap uppercase">
            # FOR LAST 6 MONTH
          </Text>
        ),
        cell: ({ row }) => (
          <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-2 text-center">
            {row.original.for_last_6_months}
          </Text>
        ),
      }),
      columnHelper.accessor(() => 'for_this_academic_year', {
        id: 'for_this_academic_year',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600 text-nowrap uppercase">
            # FOR THIS ACADEMIC YEAR
          </Text>
        ),
        cell: ({ row }) => (
          <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-2 text-center">
            {row.original.for_this_academic_year}
          </Text>
        ),
      }),
    ];

    return data;
  }, [columnHelper]);

  return columns;
};
