import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getUsersSearch } from '@purple/shared-services';
import { USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TUserSearchRequestParameters } from '@purple/shared-types';

export const useUsersSearch = (parameters?: TUserSearchRequestParameters & { enabled?: boolean }) => {
  const { search = '', action_choices, enabled, with_self, status } = parameters ?? {};

  const { isError, ...props } = useQuery({
    queryKey: [USER_QUERY_KEYS.SEARCH, search, action_choices, status],
    queryFn: () => getUsersSearch({ search, action_choices, with_self, status }),
    enabled,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch list of users', 'Please try again later');
    }
  }, [isError]);

  return { ...props, isError };
};
