import { createSlice } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../../white-list';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { IUser } from '@purple/shared-types';

export type TUserState = {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: IUser;
};

const initialState: TUserState = {
  isAuthenticated: false,
  isLoading: true,
  user: {
    id: '',
    email: '',
    username: null,
    full_name: null,
    avatar: null,
    last_login: null,
    ecosystem_default_view: 'list',
    schools: [],
    date_joined: '',
    // TODO: use Role enum instead of string when it will be done
    role: '',
    prounitas_roles: [],
    notifications: [],
    impersonator_id: null,
  },
};

export const userSlice = createSlice({
  name: AppReduxReducer.USER,
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setUser, setAuthenticated, setIsLoading } = userSlice.actions;
export const userReducer = userSlice.reducer;
