import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type {
  IStudentAcademics,
  IStudentDemographic,
  IStudentDetails,
  IStudentHealth,
  IStudentHomeInformation,
  IStudentSchoolHistoryItem,
} from '@purple/shared-types';

export type IStudentDetailsResponse = {
  id: string;
  details: IStudentDetails;
  home_information: IStudentHomeInformation;
  demographic: IStudentDemographic;
  academics: IStudentAcademics;
  health: IStudentHealth;
  school_history: IStudentSchoolHistoryItem[];
  custom_fields: Record<string, string | number | boolean>;
};

export const getStudentDetails = async (studentId: string, token?: string) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<IStudentDetailsResponse>(
    replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_DETAILS, studentId),
    { ...(token && { headers: { Authorization: `Bearer ${token}` } }) },
  );
  return response.data;
};
