import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { postActivityChoices } from '@purple/shared-services';
import {
  SAF_AREA_SUBCATEGORIES_LABELS,
  type TActivityChoiceItem,
  type TActivityChoicesRequestBody,
} from '@purple/shared-types';
import { ACTIVITY_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useActivityChoices = ({ enabled, ...body }: TActivityChoicesRequestBody & { enabled?: boolean }) => {
  const wasRendered = useRef<boolean>(false);
  const [data, setData] = useState<TActivityChoiceItem | null>(null);

  const {
    data: mutateData,
    mutate: fetchActivityChoices,
    isError,
    ...rest
  } = useMutation({
    mutationKey: [ACTIVITY_QUERY_KEYS.GET_ACTIVITY_CHOICES],
    mutationFn: postActivityChoices,
    onSuccess: setData,
    onError: () => {
      showErrorToast('Failed to fetch activity choices', 'Please try again later');
    },
  });

  useEffect(() => {
    if (enabled && !wasRendered.current) {
      wasRendered.current = true;
      fetchActivityChoices(body);
    }
  }, [body, enabled, wasRendered, fetchActivityChoices]);

  const typeOptions = useMemo(
    () => (data?.type ? Object.entries(data.type).map(([value, label]) => ({ value, label })) : []),
    [data?.type],
  );

  const serviceCategoriesOptions = useMemo(
    () =>
      data?.service_categories?.map((category) => ({
        label: SAF_AREA_SUBCATEGORIES_LABELS[category.name],
        value: category.id,
      })) ?? [],
    [data?.service_categories],
  );

  const stakeholdersServedOptions = useMemo(
    () =>
      data?.stakeholders_served
        ? Object.entries(data.stakeholders_served).map(([value, label]) => ({ value, label }))
        : [],
    [data?.stakeholders_served],
  );

  const gradeLevelsOptions = useMemo(
    () => (data?.grade_levels ? Object.entries(data.grade_levels).map(([value, label]) => ({ value, label })) : []),
    [data?.grade_levels],
  );

  const campusContactTitlesOptions = useMemo(
    () =>
      data?.campus_contact_titles
        ? Object.entries(data.campus_contact_titles).map(([value, label]) => ({ value, label }))
        : [],
    [data?.campus_contact_titles],
  );

  const crisisTypesOptions = useMemo(
    () => (data?.crisis_types ? Object.entries(data.crisis_types).map(([value, label]) => ({ value, label })) : []),
    [data?.crisis_types],
  );

  const crisisGradesOptions = useMemo(
    () => (data?.crisis_grades ? Object.entries(data.crisis_grades).map(([value, label]) => ({ value, label })) : []),
    [data?.crisis_grades],
  );

  const topicsOptions = useMemo(
    () => (data?.topics ? Object.entries(data.topics).map(([value, label]) => ({ value, label })) : []),
    [data?.topics],
  );

  return {
    data,
    mutateData,
    isError,
    typeOptions,
    serviceCategoriesOptions,
    stakeholdersServedOptions,
    gradeLevelsOptions,
    campusContactTitlesOptions,
    crisisTypesOptions,
    crisisGradesOptions,
    topicsOptions,
    ...rest,
  };
};
