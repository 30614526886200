import { useTabs } from '@purple/hooks';
import { AppTabs } from '@purple/ui';
import { homeTabsComponents } from './homeTabsComponent';

export const HomeTabs: React.FC = () => {
  const { activeTab, tabChangeHandler, isReady } = useTabs({
    tabs: homeTabsComponents,
  });

  return (
    <AppTabs tabs={homeTabsComponents} value={activeTab} isReady={isReady} onValueChange={tabChangeHandler} />
  );
};
