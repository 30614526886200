import { forwardRef } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { LocalStorageKey } from '@purple/shared-utils';
import { Button, Text } from '@purple/ui';
import { useCurrentUser } from '~/hooks';
import { useLogoutMutation } from '~/queries';
import { showErrorToast } from '~/shared/lib';

export const ImpersonateBanner = forwardRef<HTMLDivElement>((_, ref) => {
  const [, , removeToken] = useLocalStorage<string>(
    LocalStorageKey.Auth.Token.ImpersonateToken,
    '',
    { serializer: (value) => value, deserializer: (value) => value, initializeWithValue: false },
  );
  const [refresh, , removeRefresh] = useLocalStorage<string>(
    LocalStorageKey.Auth.Token.ImpersonateRefresh,
    '',
    { serializer: (value) => value, deserializer: (value) => value, initializeWithValue: false },
  );

  const { user } = useCurrentUser();
  const { mutate: logout, isPending } = useLogoutMutation({
    disableReset: true,
  });

  const stopImpersonatingClickHandler = () => {
    if (!refresh) {
      return showErrorToast('System message', 'Could not stop impersonating. Refresh token is missing');
    };
    logout({ refresh }, {
      onSuccess: () => {
        removeToken();
        removeRefresh();
        window.close();
      },
    });
  };

  if (user.impersonator_id === null) return null;

  return (
    <div ref={ref} className="sticky top-0 z-10 flex min-h-14 w-full flex-wrap items-center justify-center gap-4 bg-warning-main px-4 py-3">
      <Text tag="strong" type="body-500" variant="size-16" className="text-white">
        You’re Logged In as PurpleSENSE User [
        {user.full_name}
        ]
      </Text>
      <Button
        type="button"
        variant="table"
        size="small"
        isLoading={isPending}
        className="border-0"
        onClick={stopImpersonatingClickHandler}
      >
        Stop Impersonating
      </Button>
    </div>
  );
});
ImpersonateBanner.displayName = 'ImpersonateBanner';
