import { formatDateShortMonth, formatDateToLocalTime } from '@purple/shared-utils';
import { Button, NumberBadge, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { StatusToBadgeVariantMap } from './constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TSafCorrespondenceListItem } from '@purple/shared-types';

type TUseSafEmailColumns = (options?: {
  onSubjectClick?: (emailId: string) => void;
}) => ColumnDef<TSafCorrespondenceListItem>[];

export const useSafEmailColumns: TUseSafEmailColumns = (options) => {
  const { onSubjectClick } = options ?? {};

  return [
    {
      accessorKey: 'subject',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Subject
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="link"
              className="max-w-[200px] hover:border-transparent hover:bg-transparent hover:text-brand-blue-800 active:border-transparent active:bg-transparent active:text-brand-blue-900 active:opacity-60"
              onClick={() => onSubjectClick?.(original.id)}
            >
              <span className="block w-full truncate">{original.subject || '–'}</span>
            </Button>
          </TooltipTrigger>
          <TooltipPortal>
            {original.subject && (
              <TooltipContent align="start" withArrow={false}>
                {original.subject}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { className: 'min-w-[240px] max-w-[240px] w-[240px]' },
    },
    {
      accessorKey: 'from',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          From
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text variant="size-14" type="body-500" className="max-w-[200px] text-grey-950">
              <span className="block w-full truncate">{original.from_email || '–'}</span>
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            {original.from_email && (
              <TooltipContent align="start" withArrow={false}>
                {original.from_email}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { className: 'min-w-[180px] max-w-[180px] w-[180px]' },
    },
    {
      accessorKey: 'to',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          To
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text variant="size-14" type="body-500" className="min-w-[100px] max-w-[140px] text-grey-950">
              <span className="block w-full truncate">{original.to_email || '–'}</span>
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            {original.to_email && (
              <TooltipContent align="start" withArrow={false}>
                {original.to_email}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { className: 'min-w-[180px] max-w-[180px] w-[180px]' },
    },
    {
      accessorKey: 'date',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Date
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text variant="size-14" type="body-500" className="min-w-[100px] text-grey-950">
              <span className="block w-full truncate">
                {original.created_at ? formatDateShortMonth(original.created_at) : '–'}
              </span>
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            {original.created_at && (
              <TooltipContent align="start" withArrow={false}>
                {formatDateToLocalTime(original.created_at)}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { className: 'min-w-[140px] w-full' },
    },
    {
      accessorKey: 'status',
      header: () => (
        <Text variant="size-12" type="body-600" className="max-w-[120px] text-center uppercase text-grey-600">
          Status
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <NumberBadge variant={StatusToBadgeVariantMap[original.status]} className="rounded-full capitalize">
              {original.status ?? '–'}
            </NumberBadge>
          </TooltipTrigger>
          <TooltipPortal>
            {original.status && (
              <TooltipContent align="start" withArrow={false} className="capitalize">
                {original.status}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { className: 'max-w-[160px] min-w-[160px] w-[160px] text-center' },
    },
  ];
};
