import { useMemo } from 'react';
import { Pie, PieChart } from 'recharts';
import { getRandomColor } from '@purple/shared-utils';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  EMPTY_CHART_DATA,
  ReChartContainer,
  ReChartTooltip,
  ReChartTooltipContent,
} from '@purple/ui';
import { ChartLegend } from '~/components/ChartLegend';
import { CHART_COLORS } from '../constants';
import { NewProvidersSkeleton } from './NewProvidersSkeleton';
import type React from 'react';
import type { TDashboardNewProviders } from '@purple/shared-types';
import type { TChartConfig } from '@purple/ui';

type TNewProvidersProperties = {
  /**
   * School severe satisfactory attendance data for the selected year.
   */
  data?: TDashboardNewProviders;
  /**
   * Indicates if the data is loading.
   * @default false
   */
  isLoading?: boolean;
};

export const NewProviders: React.FC<TNewProvidersProperties> = (props) => {
  const { data, isLoading = false } = props;

  const chartConfig: TChartConfig = useMemo(
    () =>
      data
        ? Object.keys(data)
            .filter((label) => label !== 'total')
            .reduce(
              (accumulator, name, index) => ({
                ...accumulator,
                [name]: {
                  label: name,
                  color: CHART_COLORS[index] ?? getRandomColor(),
                },
              }),
              {
                value: {
                  label: 'Providers',
                },
              },
            )
        : {},
    [data],
  );

  const chartData = useMemo(
    () =>
      data
        ? Object.entries(data)
            .filter(([label]) => label !== 'total')
            .map(([name, value]) => ({
              name,
              value,
              fill: chartConfig[name]!.color!,
              color: chartConfig[name]!.color!,
            }))
        : [],
    [data, chartConfig],
  );
  const total = useMemo(() => chartData.reduce((accumulator, item) => accumulator + item.value, 0), [chartData]);
  const isNoData = useMemo(() => total === 0, [total]);

  if (isLoading) {
    return <NewProvidersSkeleton />;
  }

  return (
    <Card className="col-span-1 gap-8 px-4 py-6 pb-4 shadow-custom-medium">
      <CardHeader className="flex-row items-center justify-between gap-2 space-y-0">
        <CardTitle className="text-base font-semibold">New Providers</CardTitle>
        <CardDescription className="text-grey-600">Last 30 days</CardDescription>
      </CardHeader>
      <CardContent className="items-center justify-between gap-8">
        <ReChartContainer config={chartConfig} className="aspect-square max-h-[164px] w-full shrink-0 basis-[164px]">
          <PieChart>
            <ReChartTooltip cursor={false} content={<ReChartTooltipContent hideLabel />} />
            <Pie outerRadius={164 / 2} data={isNoData ? EMPTY_CHART_DATA : chartData} dataKey="value" nameKey="name" />
          </PieChart>
        </ReChartContainer>
        <div className="flex w-full flex-col gap-6">
          <p className="inline-flex flex-col">
            <strong className="text-4xl font-extrabold text-grey-title">{total}</strong>
            <span className="text-sm font-medium text-grey-600">Total Providers</span>
          </p>
          <ChartLegend chartData={chartData} className="gap-3" />
        </div>
      </CardContent>
      <CardFooter className="mt-auto justify-center">
        <Button type="button" variant="tertiary">
          View All
        </Button>
      </CardFooter>
    </Card>
  );
};
