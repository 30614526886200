import type { TSafAreaCategoriesValues } from '../saf';
import type { Prettify, ValueOf } from '../utils';
import type { IBasePaginationRequestParameters, IPaginatedResponse } from './general';
import type { TReminderDaysOfWeek, TReminderWeeklyInterval } from './reminder';
import type { TSchool } from './schools';
import type { IUser } from './user';

export const ActivityType = {
  CRISIS_CALL_TRACKER: 'crisis_call_tracker',
  CAMPUS_PROPOSAL_MEETING: 'campus_proposal_meeting',
  CLASSROOM_GUIDANCE_LESSON: 'classroom_guidance_lesson',
  HISD_SOUPER_FOOD_PARTY_EVENT: 'hisd_souper_food_pantry_event',
  COMMUNITY: 'community',
  NON_COUNSELING_RELATED: 'non_counseling_related',
  COUNSELING_RELATED: 'counseling_related',
  CRISIS_RESPONSE_BEHAVIORAL_SUPPORT: 'crisis_response',
} as const;
export type TActivityType = ValueOf<typeof ActivityType>;

export const CrisisActivityType = {
  SUICIDE_IDEATION: 'suicide_ideation',
  SELF_HARM: 'self_harm',
  THREAT_ASSESSMENT: 'threat_assessment',
  STAFF_CRISIS: 'staff_crisis',
  SCHOOL_WIDE: 'school_wide',
  FOLLOW_UP_CONSULTATION: 'follow_up_consultation',
  BEHAVIOR_CRISIS: 'behavior_crisis',
} as const;
export type TCrisisActivityType = ValueOf<typeof CrisisActivityType>;

export type TActivityTypeEntity = {
  id: string;
  name: TActivityType;
};

export const ActivityWeeklyOccurrence = {
  EVERY: 'every',
  ALTERNATE: 'alternate',
  FIRST: 'first',
  SECOND: 'second',
  THIRD: 'third',
  FOURTH: 'fourth',
  FIFTH: 'fifth',
} as const;
export type TActivityWeeklyOccurrence = ValueOf<typeof ActivityWeeklyOccurrence>;

export type TActivity = {
  id: string;
  created_by?: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar' | 'title'> | null;
  name: string;
  community_activity_type: TActivityTypeEntity;
  date_and_time: string | null;
  duration: number;
  attachments: number;
};

export type TActivityStats = Prettify<{
  activity_by_type?: Partial<Record<TActivityType, number | null>>;
  time_spent_by_type?: Partial<Record<TActivityType, number | null>>;
}>;

export type TActivityListQueryParameters = Prettify<
  IBasePaginationRequestParameters & {
    ordering?: string | null;
    school?: string | null;
    community_activity_type?: string | null;
    time_period?: string | null;
    created_by?: string | null;
  }
>;
export type TActivityListResponse = IPaginatedResponse<TActivity[]> & TActivityStats;

export type TActivityBulkDeletePayload = {
  activities: string[];
};

export type TActivityRecurringGroup = {
  id: number;
  days_of_week: TReminderDaysOfWeek[];
  weekly_interval: TActivityWeeklyOccurrence;
  start_date_and_time: string;
  end_date_and_time: string;
};

export type TActivityDetails = {
  type: string;
  date_and_time: string;
  duration: number;
  supported_school?:
    | (Pick<TSchool, 'id' | 'name'> & {
      grades: string[];
    })
    | null;
  community_activity_type: TActivityTypeEntity;
  school: Pick<TSchool, 'id' | 'name'> & {
    grades: string[];
  };
  created_by: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar'>;
  recurring_group: TActivityRecurringGroup | null;
  service_categories?: TActivityServiceCategory[];
  stakeholders_served?: string[];
  number_of_people_served?: number;
  quantity_of_resources_provided?: number | null;
  bags_provided_count?: number;
  participants_count?: number | null;
  grade_level?: string[];
  campus_contact_name?: string;
  campus_contact_title?: string;
  contact_email?: string;
  contact_phone_number?: string;
  description_of_crisis?: string;
  crisis_type?: TCrisisActivityType[];
  assigned_to?: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar' | 'title'> | null;
  session_participant?: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar' | 'title'>;
  crisis_grade_level?: string;
  topic?: string | null;
};

export type TActivityInfo = {
  id: string;
  name: string;
  description?: string | null;
  details: TActivityDetails;
};

export type TActivityDetailsResponse = TActivityInfo;

export type TActivityHistoryRequestParameters = IBasePaginationRequestParameters & {
  activityId: string;
};

export type TActivityHistory = {
  id: number;
  field: string;
  original_value: string | null;
  new_value: string;
  user: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar'>;
  user_pk_as_string: string;
  datetime: string;
};
export type TActivityHistoryResponse = IPaginatedResponse<TActivityHistory[]>;

export type TActivityServiceCategory = {
  id: string;
  name: TSafAreaCategoriesValues;
};

export type TActivityChoiceItem = {
  type?: Record<string, string>;
  service_categories?: TActivityServiceCategory[];
  stakeholders_served?: Record<string, string>;
  grade_levels?: Record<string, string>;
  campus_contact_titles?: Record<string, string>;
  crisis_types?: Record<string, string>;
  crisis_grades?: Record<string, string>;
  topics?: Record<string, string>;
};

export type TActivityChoicesRequestBody = {
  community_activity_type: TActivityType;
};
export type TActivityChoiceResponse = TActivityChoiceItem;

export type TActivityDetailsUpdateBody = {
  id: string | number;
  description?: string;
  name?: string;
  details: {
    school: string;
    supported_school?: string | null;
    type?: string;
    duration?: number;
    date_and_time?: string;
    service_categories?: string[];
    stakeholders_served?: string[];
    number_of_people_served?: number;
    quantity_of_resources_provided?: number | null;
    bags_provided_count?: number;
    participants_count?: number | null;
    grade_level?: string[];
    campus_contact_name?: string;
    campus_contact_title?: string;
    contact_email?: string;
    contact_phone_number?: string;
    description_of_crisis?: string;
    crisis_type?: string[];
    assigned_to?: string | null;
    crisis_grade_level?: string | null;
    session_participant?: string;
    topic?: string | null;
  };
  start_date_and_time?: string;
  end_date_and_time?: string;
  days_of_week?: TReminderDaysOfWeek[];
  weekly_interval?: TActivityWeeklyOccurrence;
};
export type TActivityDetailsUpdateResponse = TActivityInfo;

export type TCrisisActivity = {
  id: string;
  name: string;
  crisis_type: TCrisisActivityType[];
  crisis_grade_level: string | null;
  school: { id: string; name: string; grades: string[] } | null;
  contact_email: string | null;
  contact_phone_number: string | null;
  campus_contact_title: string | null;
  campus_contact_name: string | null;
  assigned_to: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar' | 'title'> | null;
};

export type TCrisisActivityStats = Prettify<{
  crisis_by_type?: Partial<Record<TCrisisActivityType, number | null>>;
  crisis_by_region?: Partial<Record<TCrisisActivityType, number | null>>;
  crisis_by_months?: Partial<Record<string, number | null>>;
}>;

export type TCrisisActivityListQueryParameters = Prettify<
  IBasePaginationRequestParameters & {
    ordering?: string | null;
    school?: string | null;
    crisis_type?: string | null;
    crisis_grade_level?: string | null;
    time_period?: string | null;
  }
>;

export type TCrisisActivityListResponse = IPaginatedResponse<TCrisisActivity[]> & TCrisisActivityStats;

export type TActivitiesFiltersParameters = {
  view_name: 'crisis-view' | 'activities';
};

export type TActivitiesFiltersResponse = Partial<{
  school: { id: string; name: string }[];
  created_by: { id: string; full_name: string }[];
  grades: string[];
  crisis_types: string[];
}>;

export type TActivityChoicesRequestParameters = {
  community_activity_type: TActivityType;
  enabled?: boolean;
};

export type TActivityStakeholderServed = {
  community_members: string;
  dept_colleague: string;
  parents_or_caregivers: string;
  students: string;
  teachers_and_staff: string;
};

export type TActivityBodyRequest = {
  name?: string;
  community_activity_type?: string;
  type?: string;
  end_date_and_time?: string;
  school?: string;
  supported_school?: string;
  date_and_time?: string;
  start_date_and_time?: string;
  tags?: string[];
  duration?: number;
  description?: string;
  attachments?: File[];
  stakeholders_served?: string[];
  service_category?: string[];
  number_of_people_served?: number;
  quantity_of_resources_provided?: number;
  bags_provided_count?: number;
  days_of_week?: TReminderDaysOfWeek[];
  weekly_interval?: TReminderWeeklyInterval;
  enable_recurring?: boolean;
  bags_provided_to_other_school?: boolean;
  participants_count?: number;
  grade_level?: string[];
  campus_contact_title?: string;
  campus_contact_name?: string;
  contact_phone_number?: string;
  contact_email?: string;
  crisis_type?: string[];
  crisis_grade_level?: string;
  description_of_crisis?: string;
  assigned_to?: string;
  topic?: string;
  session_participant?: string;
  crisis_mode_of_support?: string;
  crisis_internal_comments?: string;
};
