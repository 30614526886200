import { getAxiosInstance } from '../../../api';
import { STUDENTS_ENDPOINTS } from '../../../constants/private-api';
import type { TStudentTotalFlagsRequestParameters, TStudentTotalFlagsResponse } from '@purple/shared-types';

export const getTotalFlags = async ({ token, studentId, ...parameters }: TStudentTotalFlagsRequestParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TStudentTotalFlagsResponse>(STUDENTS_ENDPOINTS.GET_STUDENT_TOTAL_FLAGS_TABLE_DATA(studentId), {
    params: parameters,
    ...(token && { headers: { Authorization: `Bearer ${token}` } }),
  });
  return response.data;
};
