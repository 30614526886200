import { Text } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';
import type { TTotalIncidentTransformed } from '@purple/shared-types';

export const behaviorAnalysisColumns: ColumnDef<TTotalIncidentTransformed>[] = [
  {
    accessorKey: 'month',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Month
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {row.original.month ? row.original.month : '—'}
      </Text>
    ),
  },
  {
    accessorKey: 'current',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Current Year Absences
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {row.original.current ? `${row.original.current} absences` : '0 absences'}
      </Text>
    ),
  },
  {
    accessorKey: 'previous',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Previous Year Absences
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {row.original.previous ? `${row.original.previous} absences` : '0 absences'}
      </Text>
    ),
  },
];
