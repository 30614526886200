import { useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format as formatLocale } from 'date-fns/format';
import { getDay as getDayLocale } from 'date-fns/getDay';
import { enUS } from 'date-fns/locale/en-US';
import { parse as parseLocale } from 'date-fns/parse';
import { startOfWeek as startOfWeekLocale } from 'date-fns/startOfWeek';
import { cn } from '@purple/ui';
import { CustomEvent } from './CustomEvent';
import { CustomToolbar } from './CustomToolbar';
import type { IStudentMonthEvent } from '@purple/shared-types';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format: formatLocale,
  parse: parseLocale,
  startOfWeek: startOfWeekLocale,
  getDay: getDayLocale,
  locales,
});

type MonthStudentCalendarProperties = {
  calendarSwitcher: React.ReactNode;
  events: IStudentMonthEvent[];
  currentDate: Date;
  onNavigate: (date: Date) => void;
  className?: string;
};

const MonthStudentCalendar = ({
  currentDate,
  onNavigate,
  calendarSwitcher,
  events,
  className,
}: MonthStudentCalendarProperties) => {
  const now = new Date();
  const maxDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  // Override the default styles for the calendar buttons
  // to prevent the cursor from changing to a pointer
  // when hovering over the day number buttons.
  // It done by adding a style tag to the head of the document because
  // the Calendar component does not provide a way to override the styles for this tag.
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .rbc-row-content .rbc-button-link {
        cursor: default !important;
        text-decoration: none !important;
        color: inherit !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <Calendar
      date={currentDate}
      onNavigate={onNavigate}
      defaultView="month"
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      components={{
        event: CustomEvent,
        toolbar: (props) => <CustomToolbar leftContent={calendarSwitcher} {...props} maxDate={maxDate} />,
      }}
      className={cn('bg-white', className)}
      popup
      max={maxDate}
      views={['month']}
    />
  );
};

export { MonthStudentCalendar };
