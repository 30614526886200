import type { IBasePaginationRequestParameters, IPaginatedResponse, IUser } from './api';
import type { ValueOf } from './utils';

export const TAGS_TYPES = {
  NOTE: 'note',
  STUDENT: 'student',
  ACTION: 'action',
  ACTION_GROUP: 'actiongroup',
  SCHOOL: 'school',
  COMMUNITY_ACTIVITY: 'communityactivity',
  COMMUNITY_ACTIVITY_TYPE: 'communityactivitytype',
  RECORD_ACTION_TYPE: 'recordactiontype',
} as const;

export type TTagType = ValueOf<typeof TAGS_TYPES>;

export type TCreateCustomTagPayload = {
  name: string;
  color: string;
  content_type: TTagType;
  district_id: number;
  object_id?: string;
  is_applied_to_all_instances?: boolean;
};

export type TCustomTag = {
  id: string;
  name: string;
  color: string;
  updated_at: string;
  created_by: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar' | 'title'>;
  last_modified_by: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar' | 'title'>;
};

export type TCustomTagsListRequestParameters = IBasePaginationRequestParameters & {
  content_type: TTagType;
  district?: string | number;
  object_id?: string | number | null;
};

export type TCustomTagListResponse = IPaginatedResponse<TCustomTag[]>;

export type TUpdateCustomTagPayload = {
  id: string;
  name?: string;
  color?: string;
};

export type TUpdateCustomTagResponse = TCustomTag;

export type TApplyCustomTagsPayload = {
  district_id?: number;
  object_id: string;
  content_type: TTagType;
  tags: string[];
};

export type TApplyCustomTagsResponse = TCustomTag[];

export type TDeleteTagPayload = {
  id: string;
  object_id: string;
  content_type: TTagType;
  district_id: number;
};
