import { getAxiosInstance } from '../../api';
import { NOTE_ENDPOINTS } from '../../constants/private-api';
import type { IPaginatedResponse, TNoteStudentPrintItem, TNoteStudentsPrintRequestParameters } from '@purple/shared-types';

export const getStudentNotesPrint = async ({ token, ...parameters }: TNoteStudentsPrintRequestParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<IPaginatedResponse<TNoteStudentPrintItem[]>>(NOTE_ENDPOINTS.STUDENT_PRINT, {
    params: parameters,
    ...(token && { headers: { Authorization: `Bearer ${token}` } }),
  });
  return response.data;
};
