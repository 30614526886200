import { useMutation } from '@tanstack/react-query';
import { type AxiosError, getDashboardGradeStudentsExport } from '@purple/shared-services';
import { DASHBOARD_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TDashboardGradeStudentsPayload } from '@purple/shared-types';

export const useDashboardGradeStudentsExport = () => {
  // NOTE: we use useMutation hook to export by clicking on the button (or another trigger)
  return useMutation<Blob, AxiosError, TDashboardGradeStudentsPayload>({
    mutationKey: [DASHBOARD_QUERY_KEYS.GET_DASHBOARD_GRADE_STUDENTS_EXPORT],
    mutationFn: getDashboardGradeStudentsExport,

    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Could not export the report. Check the provided information and try again');
      }
    },
    onSuccess: (data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);

      link.href = url;
      link.download = 'grade-students-report.xlsx';
      document.body.append(link);
      link.click();
      link.remove();

      // Clean up the URL object after the download is complete to avoid memory leaks
      window.URL.revokeObjectURL(url);
    },
  });
};
