import { useMemo } from 'react';
import { motion } from 'framer-motion';
import { PurpleIcon } from '@purple/icons';
import { BannerType, type TUserBanner } from '@purple/shared-types';
import { Banner, BannerTitle, Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { PurpleIconType } from '@purple/icons';

type TBannerItemProps = {
  banner: TUserBanner;
  onLearnMore?: (banner: TUserBanner) => void;
  onClose?: (banner: TUserBanner) => void;
};

export const BannerItem: React.FC<TBannerItemProps> = (props) => {
  const { banner, onLearnMore, onClose } = props;

  const iconName: PurpleIconType = useMemo(() => {
    switch (banner.type) {
      case BannerType.ALL_CORRECTED: {
        return 'check-circle';
      }
      case BannerType.ERROR: {
        return 'exclamation';
      }
      case BannerType.INFO: {
        return 'information-circle';
      }
      case BannerType.UPDATES: {
        return 'plus-circle';
      }
      case BannerType.MISCELLANEOUS: {
        return 'speakerphone';
      }
      default: {
        return 'information-circle';
      }
    }
  }, [banner.type]);

  const bannerVariant = useMemo(() => {
    switch (banner.type) {
      case BannerType.ALL_CORRECTED: {
        return 'success';
      }
      case BannerType.ERROR: {
        return 'error';
      }
      case BannerType.INFO: {
        return 'info';
      }
      case BannerType.UPDATES: {
        return 'updates';
      }
      case BannerType.MISCELLANEOUS: {
        return 'miscellaneous';
      }
      default: {
        return 'info';
      }
    }
  }, [banner.type]);

  return (
    <motion.li layout initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, x: 32 }}>
      <Banner variant={bannerVariant} className="w-full">
        <PurpleIcon
          name={iconName}
          className="size-5 text-inherit"
        />
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              iconLeft={<PurpleIcon name="X" />}
              className="z-10 border-0 text-white hover:bg-black/20 focus:bg-black/20 focus-visible:bg-black/20 active:bg-black/10"
              onClick={() => onClose?.(banner)}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>
              Close banner
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <BannerTitle className="mb-0 text-base font-normal">
          {banner.title}
          {banner.body && (
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="ml-2 h-auto rounded-md border-0 px-2 py-0.5 text-white hover:bg-black/20 focus:bg-black/20 focus-visible:bg-black/20 active:bg-black/10"
              onClick={() => onLearnMore?.(banner)}
            >
              Learn More
            </Button>
          )}
        </BannerTitle>
      </Banner>
    </motion.li>
  );
};
