// TooltipDay.tsx
import React from 'react';
import { format } from 'date-fns';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { getColorForEvent } from './../../constants';
import type { TStudentCalendarItemType } from '@purple/shared-types';
import type { YearEvent } from './../../types';

type TooltipDayProps = {
  day: Date;
  events: YearEvent[];
  selectedEventType: TStudentCalendarItemType;
};

export const TooltipDay: React.FC<TooltipDayProps> = ({ day, events, selectedEventType }) => {
  const dayKey = format(day, 'yyyy-MM-dd');
  const dayColor = getColorForEvent(events, selectedEventType);

  return (
    <Tooltip key={dayKey}>
      <TooltipTrigger asChild>
        <div className={`flex size-4 items-center justify-center gap-4 rounded-full ${dayColor}`} />
      </TooltipTrigger>
      {events.length > 0 && (
        <TooltipPortal>
          <TooltipContent>
            <ul>
              {events.map((event) => (
                <li key={`${dayKey}-${event.value}`} className="list-inside">
                  {event.value}
                </li>
              ))}
            </ul>
          </TooltipContent>
        </TooltipPortal>
      )}
    </Tooltip>
  );
};
