import { memo, useMemo } from 'react';
import { cn, Text } from '@purple/ui';
import AppLogo from '~/assets/images/default-district-logo.png';
import ProUnitasLogo from '~/assets/images/default-logo.png';
import type { FC } from 'react';
import type { TDistrictInfo } from '@purple/shared-types';

type TPageHeaderProps = {
  className?: string;
  districtInfo: TDistrictInfo;
};
export const PageHeader: FC<TPageHeaderProps> = memo(({ className, districtInfo }) => {
  const districtLogo = useMemo(() => districtInfo && districtInfo.logo ? districtInfo.logo : AppLogo, [districtInfo]);
  const districtName = useMemo(() => districtInfo && districtInfo.name ? districtInfo.name : 'Unidentified District', [districtInfo]);

  return (
    <div className={cn('flex items-center justify-between gap-2 border-b border-brand-blue-400 pb-2', className)}>
      <div className="flex items-center gap-1">
        <img src={districtLogo} alt="District Logo" className="aspect-square size-[30px] rounded-full object-contain" />
        <Text variant="size-10" type="body-600" className="text-grey-700">{districtName}</Text>
      </div>
      <div className="flex items-center gap-2">
        <img src={ProUnitasLogo} className="aspect-square size-[30px] rounded-full object-contain" />
        <Text variant="size-12" type="body-600" className="text-grey-600">ProUnitas</Text>
      </div>
    </div>
  );
});
