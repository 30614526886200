import { getAxiosInstance } from '../../../api';
import { STUDENTS_ENDPOINTS } from '../../../constants/private-api';
import type { TTotalIncident, TTotalIncidentRequestParameters } from '@purple/shared-types';

export const getTotalIncidents = async ({ studentId, token, ...parameters }: TTotalIncidentRequestParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TTotalIncident>(STUDENTS_ENDPOINTS.GET_STUDENT_TOTAL_INCIDENTS_TABLE_DATA(studentId), {
    params: parameters,
    ...(token && { headers: { Authorization: `Bearer ${token}` } }),
  });
  return response.data;
};
