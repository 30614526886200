import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { LANGUAGE_QUERY_NAME, type TSafTextTranslationsView } from '@purple/shared-types';
import { Disclaimer } from '@purple/ui';
import { SafLinkExtention } from '~/components';

type TSafDisclaimerProperties = {
  data: TSafTextTranslationsView | undefined;
};

export const SafDisclaimer = ({ data }: TSafDisclaimerProperties) => {
  const [searchParameters] = useSearchParams();
  const currentLanguage = searchParameters.get(LANGUAGE_QUERY_NAME) || 'en';

  const disclaimerText = useMemo(() => {
    return (data?.disclaimer_text as Record<string, string>)?.[currentLanguage] || '';
  }, [data, currentLanguage]);

  const specialEdText = useMemo(() => {
    return (data?.special_ed_text as Record<string, string>)?.[currentLanguage] || '';
  }, [data, currentLanguage]);

  const [hideDisclaimer, setHideDisclaimer] = useState<boolean>(false);

  const { t } = useTranslation('saf');

  const disclaimerEditorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      SafLinkExtention.configure({
        autolink: true,
        defaultProtocol: 'https',
      }),
      Placeholder.configure({
        placeholder: () => 'Enter your disclaimer text here...',
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: disclaimerText,
    editable: false,
  });

  const specialEdEditorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      SafLinkExtention.configure({
        autolink: true,
        defaultProtocol: 'https',
      }),
      Placeholder.configure({
        placeholder: () => 'Enter your disclaimer text here...',
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: specialEdText,
    editable: false,
  });

  useEffect(() => {
    disclaimerEditorConfig?.commands.setContent(disclaimerText);
  }, [disclaimerText, disclaimerEditorConfig]);

  useEffect(() => {
    specialEdEditorConfig?.commands.setContent(specialEdText);
  }, [specialEdText, specialEdEditorConfig]);

  const closeDisclaimerClickHandler = () => {
    setHideDisclaimer(true);
  };

  if (hideDisclaimer || !data) {
    return null;
  }

  return (
    <Disclaimer
      heading={t('disclaimer.title')}
      actionButtonLabel={t('disclaimer.accept')}
      onClose={closeDisclaimerClickHandler}
      onAction={closeDisclaimerClickHandler}
    >
      <div className="flex w-full flex-col gap-2">
        <EditorContent
          editor={disclaimerEditorConfig}
          disabled
          className="minimal-tiptap-editor flex size-full cursor-text flex-col p-0 font-primary text-sm font-medium text-grey-950"
        />
        <EditorContent
          editor={specialEdEditorConfig}
          disabled
          className="minimal-tiptap-editor flex size-full cursor-text flex-col p-0 font-primary text-sm font-medium text-grey-950"
        />
      </div>
    </Disclaimer>
  );
};
