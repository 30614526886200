import { useEffect } from 'react';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  ScrollArea,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
// hooks
import { useModal } from '~/hooks';
// helpers
import type { JSONContent } from '@tiptap/react';
// types
import type React from 'react';
import type { TUserBanner } from '@purple/shared-types';

type TBannerPreviewDialogProperties = {
  banner: TUserBanner | null;
  onSuccess?: (banner: TUserBanner) => void;
};

export const BannerPreviewDialog: React.FC<TBannerPreviewDialogProperties> = (props) => {
  const { banner, onSuccess } = props;

  const { isOpen, toggleModal } = useModal(ModalType.BANNER_DETAILS);

  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Placeholder.configure({
        placeholder: () => 'Enter your banner text here...',
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: banner?.body ? (JSON.parse(banner.body) as JSONContent) : '',
    editable: false,
    onCreate: ({ editor }) => {
      if (banner?.body) {
        editor.commands.setContent(JSON.parse(banner.body) as string);
      }
    },
  });

  useEffect(() => {
    if (banner?.body) {
      editorConfig?.commands.setContent(JSON.parse(banner.body) as string);
    }
  }, [banner?.body]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!banner) return null;

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-full max-w-[564px] flex-col">
        <DialogHeader className="flex-row items-start justify-between gap-2">
          <DialogTitle className="text-left text-lg font-semibold text-grey-title">
            {banner.title}
          </DialogTitle>
          <DialogDescription className="sr-only">
            By this information, you can understand what's happening in the system.
          </DialogDescription>
        </DialogHeader>
        <ScrollArea
          type="auto"
          className="flex size-full max-h-[640px] min-h-[80px] flex-col px-6 py-2"
          scrollBarClassName="p-2 w-[22px]"
        >
          <EditorContent
            editor={editorConfig}
            className="minimal-tiptap-editor flex size-full cursor-text flex-col border-none p-0 font-primary text-grey-950"
          />
          {banner.view_more_link && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  asChild
                  variant="link"
                  className="mt-4 inline-flex h-auto max-w-48 justify-start gap-1 p-0"
                  iconLeft={<PurpleIcon name="external-link" className="shrink-0" />}
                >
                  <a href={banner.view_more_link} target="_blank" rel="noreferrer">
                    <span className="line-clamp-1">
                      View More
                    </span>
                  </a>
                </Button>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>
                  {banner.view_more_link}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )}
        </ScrollArea>
        <DialogFooter>
          <Button type="button" variant="tertiary" onClick={() => toggleModal(false)}>
            Cancel
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={() => onSuccess?.(banner)}
          >
            Got It
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
