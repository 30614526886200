import { memo } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { formatDateWithTime } from '@purple/shared-utils';
import { cn, Text } from '@purple/ui';
import type { JSONContent } from '@tiptap/react';
import type { FC } from 'react';
import type { TNoteStudentPrintItem } from '@purple/shared-types';

type TNoteItemProps = {
  note: TNoteStudentPrintItem;
};

export const NoteItem: FC<TNoteItemProps> = memo(({ note }) => {
  const noteType = note.is_private ? 'My note' : 'Shared note';

  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
      }),
    ],
    content: note ? (JSON.parse(note.text) as JSONContent) : '',
    editable: false,
    onCreate: ({ editor }) => {
      if (note?.text) {
        editor.commands.setContent(JSON.parse(note.text) as string);
      }
    },
  });

  return (
    <div className="flex flex-col gap-2 border-grey-200 [&:not(:last-child)]:border-b [&:not(:last-child)]:pb-2">
      <div className="flex flex-col">
        <Text variant="size-14" type="body-500" className="text-grey-title">
          {/* {noteType} */}
          {note.title && note.title.length > 0 ? note.title : 'Note without title'}
        </Text>
        <Text variant="size-12" type="body-500" className="text-grey-700">
          {noteType}
          {' '}
          |
          {' '}
          {formatDateWithTime(note.created_at)}
        </Text>
      </div>
      <EditorContent
        editor={editorConfig}
        className={cn(
          'minimal-tiptap-editor flex h-full min-h-[30px] w-full flex-col rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 cursor-default border-none p-0',
        )}
      />
    </div>
  );
});
