import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { usePermissions } from '@purple/permissions';
import {
  Button,
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Separator,
} from '@purple/ui';
import { CREATE_ACTIVITY_LIST } from '~/constants/create-activity';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useCreateActivity } from '~/hooks/redux';
import { useActivitiesTypes } from '~/queries';
import { CampusProposalMeetingModal } from './CampusProposalMeetingModal';
import { ClassroomGuidanceLessonModal } from './ClassroomGuidanceLessonModal';
import { CommunityActivityModal } from './CommunityActivityModal';
import { CounselingRelatedActivityDutyModal } from './CounselingRelatedActivityDutyModal';
import { CreateActivityListSkeleton } from './CreateActivityListSkeleton';
import { CrisisCallTrackerModal } from './CrisisCallTrackerModal';
import { CrisisResponseBehavioralSupportActivityModal } from './CrisisResponseBehavioralSupportActivityModal';
import { HisdSouperFoodPantryEventModal } from './HisdSouperFoodPantryEventModal';
import { NonCounselingRelatedActivityDutyModal } from './NonCounselingRelatedActivityDutyModal/NonCounselingRelatedActivityDutyModal';
import type { TActivityType } from '@purple/shared-types';

type TCreateActivityModalProperties = {
  onSuccessfulActivity?: () => void;
};

export const CreateActivityModal = ({ onSuccessfulActivity }: TCreateActivityModalProperties) => {
  const { isOpen, toggleModal } = useModal(ModalType.CREATE_ACTIVITY);
  const { toggleActivityModal } = useCreateActivity();
  const { hasPermissions } = usePermissions();

  const { data: availableActivityTypes = [], isFetching } = useActivitiesTypes({
    enabled: isOpen,
  });

  const activityItems = useMemo(
    () => CREATE_ACTIVITY_LIST.filter(({ type, permissions }) => availableActivityTypes.some(({ name }) => name === type) && hasPermissions([...permissions])),
    [hasPermissions, availableActivityTypes],
  );

  const activityClickHandler = (action: TActivityType) => {
    toggleModal(false);
    toggleActivityModal(action);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={toggleModal}>
        <DialogContent className="w-[564px]">
          <DialogHeader className="flex-row items-center justify-between">
            <DialogTitle className="leading-7 tracking-normal">Create Activity</DialogTitle>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          <DialogDescription asChild className="flex p-6 pb-10">
            <Command className="outline-none">
              {isFetching
                ? (
                    <CreateActivityListSkeleton />
                  )
                : (
                    <CommandList className="border-grey-200 max-h-max rounded-lg border p-4 pb-2">
                      {activityItems.length > 0 && (
                        <CommandGroup heading="Activities">
                          {activityItems.map(({ type, label }) => (
                            <CommandItem
                              key={type}
                              value={type}
                              onSelect={() => activityClickHandler(type)}
                              className="text-grey-950 cursor-pointer px-3 py-2 text-base"
                            >
                              <span>{label}</span>
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      )}
                    </CommandList>
                  )}
            </Command>
          </DialogDescription>
        </DialogContent>
      </Dialog>
      <CounselingRelatedActivityDutyModal onSuccessfulActivity={onSuccessfulActivity} />
      <NonCounselingRelatedActivityDutyModal onSuccessfulActivity={onSuccessfulActivity} />
      <HisdSouperFoodPantryEventModal onSuccessfulActivity={onSuccessfulActivity} />
      <CommunityActivityModal onSuccessfulActivity={onSuccessfulActivity} />
      <ClassroomGuidanceLessonModal onSuccessfulActivity={onSuccessfulActivity} />
      <CampusProposalMeetingModal onSuccessfulActivity={onSuccessfulActivity} />
      <CrisisCallTrackerModal onSuccessfulActivity={onSuccessfulActivity} />
      <CrisisResponseBehavioralSupportActivityModal onSuccessfulActivity={onSuccessfulActivity} />
    </>
  );
};
