import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { LayoutGroup, motion } from 'framer-motion';
import { AppRoutes } from '~/constants/routes/routes';
import { useAppSelector, useCurrentUser } from '~/hooks';
import { MainHeader } from '~/layout/MainHeader';
import { districtSelector } from '~/store/features/district';
import { BannerSection } from '../BannerSection';
import { MainContainer } from '../MainContainer';

export const SiteLayout: React.FC = () => {
  const location = useLocation();

  const { isAuthenticated, isLoading, user } = useCurrentUser();

  const { logo, name } = useAppSelector(districtSelector) ?? {};

  if (!isAuthenticated && !isLoading && !user.id) {
    return <Navigate to={AppRoutes.Auth.SignIn.Root.path} replace state={{ from: location }} />;
  }

  return (
    <MainContainer>
      <MainHeader districtName={name} logo={logo} />
      <LayoutGroup>
        <BannerSection />
        <motion.main layout="position" key="main-container" className="flex w-full max-w-[1440px] justify-center gap-4 px-8 py-[30px]">
          <Outlet />
        </motion.main>
      </LayoutGroup>
    </MainContainer>
  );
};
