import { useMemo } from 'react';
import { convertBooleanToText, isFieldExist } from '@purple/shared-utils';
import { DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';
// hooks
import { useActionChoices } from '~/queries';
// components
import { GroupActionSectionHeader } from './GroupActionSectionHeader';
// types
import type React from 'react';
import type { TGroupActionDetails } from '@purple/shared-types';

type TGroupCrisisDetailsSectionProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupCrisisDetailsSection: React.FC<TGroupCrisisDetailsSectionProperties> = (props) => {
  const { groupAction } = props;

  const { crisisTypeOptions, treatAssessmentResultOptions } = useActionChoices({
    record_action_type: groupAction.record_action_type,
    enabled: true,
  });

  const crisisType = useMemo(
    () =>
      crisisTypeOptions
        .filter((option) => groupAction.crisis_details?.crisis_types.includes(option.value))
        .map((option) => option.label)
        .join(', '),
    [groupAction.crisis_details?.crisis_types, crisisTypeOptions],
  );
  const treatAssessmentResult = useMemo(
    () =>
      groupAction.crisis_details?.threat_assessment_result
        ? treatAssessmentResultOptions
            .filter((option) => option.value === groupAction.crisis_details?.threat_assessment_result)
            .map((option) => option.label)
            .join(', ')
        : null,
    [groupAction.crisis_details?.threat_assessment_result, treatAssessmentResultOptions],
  );
  const participants = useMemo(
    () =>
      Array.isArray(groupAction.crisis_details?.your_consultants)
      && groupAction.crisis_details.your_consultants.length > 0
        ? groupAction.crisis_details.your_consultants.map((consultant) => consultant.full_name).join(', ')
        : null,
    [groupAction.crisis_details?.your_consultants],
  );

  if (!isFieldExist(groupAction.crisis_details)) return null;

  return (
    <div className="flex w-full flex-col gap-2">
      <GroupActionSectionHeader disableEditing />
      <DescriptionList>
        {isFieldExist(groupAction.crisis_details?.crisis_types) && (
          <DescriptionItem>
            <DescriptionTerm>Type</DescriptionTerm>
            <DescriptionDetails className="max-w-[300px] text-right">{crisisType}</DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(groupAction.crisis_details?.is_law_enforcement_notified) && (
          <DescriptionItem>
            <DescriptionTerm>Law Enforcement Notified</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(groupAction.crisis_details?.is_law_enforcement_notified)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(groupAction.crisis_details?.is_CPS_notified) && (
          <DescriptionItem>
            <DescriptionTerm>CPS Notified</DescriptionTerm>
            <DescriptionDetails>{convertBooleanToText(groupAction.crisis_details?.is_CPS_notified)}</DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(groupAction.crisis_details?.is_parent_caregiver_notified) && (
          <DescriptionItem>
            <DescriptionTerm>Parents/Caregiver Notified</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(groupAction.crisis_details?.is_parent_caregiver_notified)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(groupAction.crisis_details?.is_administration_notified) && (
          <DescriptionItem>
            <DescriptionTerm>Administration Notified</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(groupAction.crisis_details?.is_administration_notified)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(groupAction.crisis_details?.your_consultants) && (
          <DescriptionItem>
            <DescriptionTerm>Consulted With</DescriptionTerm>
            <DescriptionDetails className="max-w-[300px] text-right">{participants ?? '-'}</DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(groupAction.crisis_details?.is_threat_assessment_administered) && (
          <DescriptionItem>
            <DescriptionTerm>Threat Assessment Administered</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(groupAction.crisis_details?.is_threat_assessment_administered)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(groupAction.crisis_details?.threat_assessment_result) && (
          <DescriptionItem>
            <DescriptionTerm>Threat Assessment Result</DescriptionTerm>
            <DescriptionDetails className="max-w-[300px] text-right">{treatAssessmentResult ?? '-'}</DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(groupAction.crisis_details?.is_parent_caregiver_notified_of_assessment) && (
          <DescriptionItem>
            <DescriptionTerm>Parent/Caregiver Notified of Assessment</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(groupAction.crisis_details?.is_parent_caregiver_notified_of_assessment)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(groupAction.crisis_details?.is_weapons_accessible_to_the_individual) && (
          <DescriptionItem>
            <DescriptionTerm>Weapons Accessible to this Individual</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(groupAction.crisis_details?.is_weapons_accessible_to_the_individual)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
      </DescriptionList>
    </div>
  );
};
