import { BannerType, type TUserBanner } from '@purple/shared-types';

export const makeErrorBannersToTheTop = (banners: TUserBanner[]): TUserBanner[] => [...banners].sort((a, b) => {
  if (a.type === BannerType.ERROR && b.type !== BannerType.ERROR) {
    return -1;
  }

  if (a.type !== BannerType.ERROR && b.type === BannerType.ERROR) {
    return 1;
  }

  return 0;
});
