import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getDashboardGradeStudents } from '@purple/shared-services';
import { DASHBOARD_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TDashboardGradeStudentsPayload, TDashboardGradeStudentsResponse } from '@purple/shared-types';

export const useDashboardGradeStudents = (parameters?: TDashboardGradeStudentsPayload & { enabled?: boolean }) => {
  const { enabled, ...params } = parameters ?? {};

  const { isError, error, ...props } = useQuery<TDashboardGradeStudentsResponse, AxiosError>({
    queryKey: [DASHBOARD_QUERY_KEYS.GET_DASHBOARD_GRADE_STUDENTS, params],
    queryFn: () => getDashboardGradeStudents(params),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('Failed to fetch dashboard grade students data', 'Please try again later');
    }
  }, [isError, error?.response]);

  return { ...props, isError };
};
