import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useKeyPress } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { ActivityType, TAGS_TYPES } from '@purple/shared-types';
import {
  Button,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  Form,
  FormField,
  FormItem,
  FormMessage,
  Heading,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Input,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { ChoiceTag } from '~/components';
import { ModalType } from '~/constants/modals';
import { useCurrentUser, useModal } from '~/hooks';
import { useCustomTags, useUpdateActivity, useUpdateRecurringActivity } from '~/queries';
import { showErrorToast } from '~/shared/lib';
import { ActivityDescriptionSection } from './ActivityDescriptionSection';
import { DeleteActivityDialog } from './DeleteActivityDialog';
import type React from 'react';
import type { TActivityInfo } from '@purple/shared-types';

const activityNameSchema = z.object({
  name: z.string().trim().min(1, 'Name is required'),
});

type TActivitySidebarHeaderProperties = {
  activity: TActivityInfo;
};

export const ActivitySidebarHeader: React.FC<TActivitySidebarHeaderProperties> = (props) => {
  const { activity } = props;

  const { openModal } = useModal(ModalType.DELETE_ACTIVITY);
  const { user } = useCurrentUser();
  const { data: tags } = useCustomTags({
    content_type: TAGS_TYPES.COMMUNITY_ACTIVITY,
    object_id: activity.id,
  });
  const { mutate: updateActivity, isPending } = useUpdateActivity();
  const { mutate: updateRecurringActivity, isPending: isRecurringPending } = useUpdateRecurringActivity();

  const [isRecurringDelete, setIsRecurringDelete] = useState<boolean>(false);
  const [isEditName, setIsEditName] = useState<boolean>(false);

  const allowedToUpdate = useMemo(() => user.id === activity.details.created_by.id, [user, activity]);
  const activityTags = useMemo(() => tags?.results ?? [], [tags]);
  const defaultValues = useMemo(() => ({ name: activity.name }), [activity]);

  const form = useForm<z.infer<typeof activityNameSchema>>({
    resolver: zodResolver(activityNameSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const individualSelectHandler = () => {
    setIsRecurringDelete(false);
    openModal();
  };

  const recurringSelectHandler = () => {
    setIsRecurringDelete(true);
    openModal();
  };

  const cancelEditClickHandler = () => {
    form.setValue('name', activity.name);
    setIsEditName(false);
  };

  const saveNameClickHandler = (formData: z.infer<typeof activityNameSchema>) => {
    if (isEditName && allowedToUpdate && !isPending) {
      updateActivity(
        {
          id: activity.id,
          name: formData.name,
          ...(activity.details.community_activity_type.name !== ActivityType.CRISIS_CALL_TRACKER && {
            description: activity.details.description_of_crisis,
          }),
          // Details are required for some activity types, as well as some fields inside are also required
          details: {
            school: activity.details.school.id,
            supported_school: activity.details.supported_school?.id ?? null,
            ...(activity.details.assigned_to && { assigned_to: activity.details.assigned_to.id }),
            ...(activity.details.service_categories && {
              service_categories: activity.details.service_categories.map((category) => category.id),
            }),
          },
        },
        {
          onSuccess: () => {
            setIsEditName(false);
          },
        },
      );
    }
  };

  const bulkSaveNameClickHandler = (formData: z.infer<typeof activityNameSchema>) => {
    if (!activity.details.recurring_group?.id) {
      return showErrorToast('Activity series not found', 'Please try again later');
    }
    if (isEditName && allowedToUpdate && !isPending) {
      updateRecurringActivity(
        {
          id: activity.details.recurring_group.id,
          name: formData.name,
          // Details are required for some activity types, as well as some fields inside are also required
          details: {
            school: activity.details.school.id,
            ...(activity.details.assigned_to && { assigned_to: activity.details.assigned_to.id }),
            ...(activity.details.service_categories && {
              service_categories: activity.details.service_categories.map((category) => category.id),
            }),
            duration: activity.details.duration,
            type: activity.details.type,
          },
          days_of_week: activity.details.recurring_group.days_of_week,
          end_date_and_time: activity.details.recurring_group.end_date_and_time,
          start_date_and_time: activity.details.recurring_group.start_date_and_time,
          weekly_interval: activity.details.recurring_group.weekly_interval,
        },
        {
          onSuccess: () => {
            setIsEditName(false);
          },
        },
      );
    }
  };

  useKeyPress('Enter', () => form.handleSubmit(saveNameClickHandler));

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex justify-between gap-4">
        <div className="flex w-full flex-col gap-2">
          <div className="flex w-full items-center justify-start gap-2">
            {isEditName
              ? (
                  <Form providerProps={form} className="w-full">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field, fieldState }) => (
                        <FormItem>
                          <HoverCard open={isEditName}>
                            <HoverCardTrigger asChild>
                              <Input
                                type="text"
                                sizeVariant="small"
                                containerClassName="w-full"
                                className="w-full min-w-48 max-w-[470px] shrink grow rounded px-3 py-1.5"
                                {...field}
                                isError={!!fieldState.error}
                              />
                            </HoverCardTrigger>
                            <HoverCardContent
                              align="end"
                              className="flex w-auto gap-1 border-none bg-transparent p-0 shadow-none"
                            >
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    type="button"
                                    variant="primary"
                                    size="icon_32"
                                    className="rounded shadow-custom-medium"
                                    disabled={!!fieldState.error}
                                    isLoading={isPending}
                                    iconLeft={<PurpleIcon name="check" className="shrink-0" />}
                                    onClick={form.handleSubmit(saveNameClickHandler)}
                                  />
                                </TooltipTrigger>
                                <TooltipPortal>
                                  <TooltipContent>Save only for current activity</TooltipContent>
                                </TooltipPortal>
                              </Tooltip>
                              {activity.details.recurring_group && (
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <Button
                                      type="button"
                                      variant="primary"
                                      size="icon_32"
                                      className="rounded shadow-custom-medium"
                                      disabled={!!fieldState.error}
                                      isLoading={isRecurringPending}
                                      iconLeft={<PurpleIcon name="save" className="shrink-0" />}
                                      onClick={form.handleSubmit(bulkSaveNameClickHandler)}
                                    />
                                  </TooltipTrigger>
                                  <TooltipPortal>
                                    <TooltipContent>Save for all activities in the series</TooltipContent>
                                  </TooltipPortal>
                                </Tooltip>
                              )}
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    type="button"
                                    variant="secondary"
                                    size="icon_32"
                                    className="rounded border-0 shadow-custom-medium"
                                    iconLeft={<PurpleIcon name="X" className="shrink-0" />}
                                    onClick={cancelEditClickHandler}
                                  />
                                </TooltipTrigger>
                                <TooltipPortal>
                                  <TooltipContent>Cancel</TooltipContent>
                                </TooltipPortal>
                              </Tooltip>
                            </HoverCardContent>
                          </HoverCard>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </Form>
                )
              : (
                  <Heading tag="h1" className="text-lg font-semibold text-grey-title">
                    {activity.name}
                  </Heading>
                )}
            {allowedToUpdate && !isEditName && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="tertiary"
                    size="icon_32"
                    className="shrink-0"
                    iconLeft={<PurpleIcon name="pencil" />}
                    onClick={() => setIsEditName(true)}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Edit activity name</TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )}
          </div>
          <ul className="flex flex-wrap items-center gap-2">
            {activityTags.map(({ color, name }) => (
              <li key={name}>
                <ChoiceTag color={color}>{name}</ChoiceTag>
              </li>
            ))}
          </ul>
        </div>
        {allowedToUpdate && activity.details.recurring_group && (
          <DropdownRoot>
            <DropdownTrigger asChild>
              <Button
                type="button"
                aria-label="Activity options"
                variant="tertiary"
                size="icon_32"
                className="text-grey-600"
                iconLeft={<PurpleIcon name="dots-vertical" className="size-5 shrink-0" />}
              />
            </DropdownTrigger>
            <DropdownContent className="min-w-max" align="end" side="bottom">
              <DropdownItem
                iconName="trash"
                className="cursor-pointer text-error-main transition-colors duration-200 hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main focus-visible:outline-none [&>svg]:text-error-main"
                onSelect={individualSelectHandler}
              >
                Delete this activity
              </DropdownItem>
              <DropdownItem
                iconName="folder-remove"
                className="cursor-pointer text-error-main transition-colors duration-200 hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main focus-visible:outline-none [&>svg]:text-error-main"
                onSelect={recurringSelectHandler}
              >
                Delete activity series
              </DropdownItem>
            </DropdownContent>
          </DropdownRoot>
        )}
        {allowedToUpdate && !activity.details.recurring_group && (
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="destructive_secondary"
                size="icon_32"
                className="border-0"
                iconLeft={<PurpleIcon name="trash" className="size-5 shrink-0" />}
                onClick={individualSelectHandler}
              />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>Delete activity</TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )}
      </div>
      <ActivityDescriptionSection activity={activity} />
      <DeleteActivityDialog activity={activity} isRecurring={isRecurringDelete} />
    </div>
  );
};
