import { getAxiosInstance } from '../../api';
import { PRINT_ENDPOINTS } from '../../constants/private-api';
import type { TPrintRequestParameters } from '@purple/shared-types';

export const getPrintData = async (parameters: TPrintRequestParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<Blob>(PRINT_ENDPOINTS.PRINT, {
    params: parameters,
    responseType: 'blob',
  });

  return response.data;
};
