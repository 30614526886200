import { Text } from '@purple/ui';
import { DataTable } from '~/components';
import { studentAttendanceColumns } from './studentAttendanceColumns';
import type { FC } from 'react';
import type { TTotalIncidentTransformed } from '@purple/shared-types';

type TStudentAttendanceSectionProps = {
  attendanceData: Array<TTotalIncidentTransformed>;
};

export const StudentAttendanceSection: FC<TStudentAttendanceSectionProps> = ({ attendanceData }) => {
  return (
    <div className="flex flex-col">
      <Text variant="size-14" type="body-600" className="text-grey-title">Attendance Analysis</Text>
      <Text variant="size-10" type="body-400" className="text-grey-800">{`Total ${attendanceData.length} records`}</Text>
      <DataTable
        columns={studentAttendanceColumns}
        data={attendanceData}
        emptyStateTitle="No Attendance found"
        emptyStateMessage="There are no Attendance to display. If it an error, please contact support."
      />
    </div>
  );
};
