import { useQueryParameter } from '@purple/hooks';
import { PRINT_OPTIONS } from '@purple/shared-types';
import { AccessDenied } from '@purple/ui';
import { StudentDataPrintView, StudentVisualDashboardPrintView } from './components';
import type { FC } from 'react';
import type { TPrintOptions } from '@purple/shared-types';

export const Print: FC = () => {
  const { query: variant } = useQueryParameter<TPrintOptions>({
    queryName: 'variant',
  });

  if (variant === PRINT_OPTIONS.STUDENT_VISUAL_DASHBOARD) {
    return <StudentVisualDashboardPrintView />;
  }

  if (variant === PRINT_OPTIONS.STUDENT_DATA) {
    return <StudentDataPrintView />;
  }

  return (
    <div className="flex h-screen items-center justify-center bg-white">
      <AccessDenied />
    </div>
  );
};
