import { SCHOOL_USER_ACCESS_FILTERS } from '@purple/shared-types';

export const SEARCH_DEBOUNCE_MS = 1000;

export const USERS_ACCESS_SORT_SELECT_OPTIONS = [
  {
    label: 'Last Name (ASC)',
    value: 'last_name',
  },
  {
    label: 'Last Name (DESC)',
    value: '-last_name',
  },
  {
    label: 'Status (ASC)',
    value: 'is_active',
  },
  {
    label: 'Status (DESC)',
    value: '-is_active',
  },
  {
    label: 'Email (ASC)',
    value: 'email',
  },
  {
    label: 'Email (DESC)',
    value: '-email',
  },
  {
    label: 'Phone Number (ASC)',
    value: 'contacts__phone',
  },
  {
    label: 'Phone Number (DESC)',
    value: '-contacts__phone',
  },
  {
    label: 'Title (ASC)',
    value: 'title',
  },
  {
    label: 'Title (DESC)',
    value: '-title',
  },
] as const;

export const USERS_ACCESS_FILTERS_TABS = [
  {
    label: 'Title',
    value: SCHOOL_USER_ACCESS_FILTERS.TITLE,
  },
  {
    label: 'Status',
    value: SCHOOL_USER_ACCESS_FILTERS.IS_ACTIVE,
  },
] as const;
