import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSafSubmitterTypes } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useSafSubmitterTypes = () => {
  const { isError, ...props } = useQuery({
    queryKey: [SAF_QUERY_KEYS.DISTRICT_SUBMITTER_TYPES],
    queryFn: () => getSafSubmitterTypes(),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Error', 'Failed to fetch SAF submitter types');
    }
  }, [isError]);

  return { ...props, isError };
};
