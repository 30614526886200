import type {
  IBasePaginationRequestParameters,
  IPaginatedResponse,
  ISubPopulation,
  IUser,
  TAdminTranslation,
  TNameableEntity,
  TSchoolItem,
} from './api';
import type { Prettify, ValueOf } from './utils';

export const SafSubmitterType = {
  STUDENT: 'student',
  TEACHER: 'teacher_or_staff_member',
  PARENT: 'parent_or_caregiver',
  PROVIDER: 'community_member_or_service_provider',
  PURPLE_USER: 'purple_user',
} as const;
export type TSafSubmitterType = ValueOf<typeof SafSubmitterType>;
export const ReadableSafSubmitterType = {
  [SafSubmitterType.STUDENT]: 'Student',
  [SafSubmitterType.TEACHER]: 'Teacher or Staff Member',
  [SafSubmitterType.PARENT]: 'Parent or Caregiver',
  [SafSubmitterType.PROVIDER]: 'Community Member or Service Provider',
  [SafSubmitterType.PURPLE_USER]: 'Purple User',
} as const;
export const ReadableSafSubmitterTypeShortForm = {
  [SafSubmitterType.STUDENT]: 'Student',
  [SafSubmitterType.TEACHER]: 'Teacher / Staff Member',
  [SafSubmitterType.PARENT]: 'Parent / Caregiver',
  [SafSubmitterType.PROVIDER]: 'Community / SP',
  [SafSubmitterType.PURPLE_USER]: 'Purple User',
} as const;

export const SAF_PROCESS_STEPS_VALUES = {
  LINK_STUDENT: 'link_student',
  ASSIGN_PRIORITY: 'assign_priority',
  DOCUMENT_ACTIONS: 'document_actions',
  CLOSE_SAF: 'close_saf',
} as const;

export type TSafProcessStepsValues = ValueOf<typeof SAF_PROCESS_STEPS_VALUES>;

export const SAF_AREA_CATEGORIES_VALUES = {
  BASIC_NEEDS: 'basic_needs',
  EDUCATION_AND_VOCATIONAL: 'education_and_vocational',
  EMOTIONAL_AND_MENTAL_HEALTH: 'emotional_and_mental_health',
  EXTRA_CURRICULAR_ENRICHMENT: 'extra_curricular_enrichment',
  HEALTH: 'health',
  LEGAL_SUPPORT: 'legal_support',
  LIFE_EVENTS_STRESSORS: 'life_events_stressors',
  NO_NEED: 'no_need',
} as const;

export type TSafAreaCategoriesValues = ValueOf<typeof SAF_AREA_CATEGORIES_VALUES>;

export const SAF_AREA_SUBCATEGORIES_LABELS = {
  [SAF_AREA_CATEGORIES_VALUES.BASIC_NEEDS]: 'Basic Needs',
  [SAF_AREA_CATEGORIES_VALUES.EDUCATION_AND_VOCATIONAL]: 'Educational and Vocational',
  [SAF_AREA_CATEGORIES_VALUES.EMOTIONAL_AND_MENTAL_HEALTH]: 'Emotional and Mental Health',
  [SAF_AREA_CATEGORIES_VALUES.EXTRA_CURRICULAR_ENRICHMENT]: 'Extra-Curricular Enrichment',
  [SAF_AREA_CATEGORIES_VALUES.HEALTH]: 'Health',
  [SAF_AREA_CATEGORIES_VALUES.LEGAL_SUPPORT]: 'Legal Support',
  [SAF_AREA_CATEGORIES_VALUES.LIFE_EVENTS_STRESSORS]: 'Life Events/Stressors',
  [SAF_AREA_CATEGORIES_VALUES.NO_NEED]: 'No Need',
} as const;

export type TSafAreaSubcategoriesLabels = ValueOf<typeof SAF_AREA_SUBCATEGORIES_LABELS>;

export const SAF_NO_NEED_REASON_VALUES = {
  misunderstanding: 'misunderstanding',
  accidental_submission: 'accidental_submission',
  blank_submission: 'blank_submission',
  duplicate_submission: 'duplicate_submission',
  other: 'other',
} as const;

export type TSafNoNeedReasonValues = ValueOf<typeof SAF_NO_NEED_REASON_VALUES>;

export const SAF_NO_NEED_REASON_LABELS = {
  [SAF_NO_NEED_REASON_VALUES.misunderstanding]: 'Misunderstanding',
  [SAF_NO_NEED_REASON_VALUES.accidental_submission]: 'Accidental Submission',
  [SAF_NO_NEED_REASON_VALUES.blank_submission]: 'Blank Submission',
  [SAF_NO_NEED_REASON_VALUES.duplicate_submission]: 'Duplicate Submission',
  [SAF_NO_NEED_REASON_VALUES.other]: 'Other',
} as const;

export type TSafNoNeedReasonLabels = ValueOf<typeof SAF_NO_NEED_REASON_LABELS>;

export type TSafSubmitter = {
  /**
   * The first name of the submitter.
   */
  first_name: string;
  /**
   * The last name of the submitter.
   */
  last_name: string;
  /**
   * The email address of the submitter.
   */
  email?: string;
  /**
   * The phone number of the submitter.
   */
  phone?: string;
};

export type TSafDto = {
  /**
   * The type of submitter.
   */
  submitter_type: TSafSubmitterType;
  /**
   * The type of need the student is submitting.
   */
  needs: string[];
  /**
   * Whether the form was submitted anonymously.
   */
  is_submitted_anonymously?: boolean;
  /**
   * Whether the student is filling the form for themselves. If `true`, the `student` field will be populated.
   */
  is_student_filling_form_for_himself?: boolean;
  /**
   * The details of the submitter.
   */
  submitter?: TSafSubmitter;
  /**
   * Organization or campus of the submitter.
   */
  submitter_org_or_campus?: string;
  /**
   * First name of the submitted student.
   */
  submitted_student_first_name?: string;
  /**
   * Middle name of the submitted student.
   */
  submitted_student_middle_name?: string;
  /**
   * Last name of the submitted student.
   */
  submitted_student_last_name?: string;
  /**
   * The ID of the submitted student.
   */
  submitted_student_id?: string;
  /**
   * The grade of the submitted student.
   */
  submitted_student_grade?: string;
  /**
   * The phone number of the student's parent / guardian / caregiver.
   */
  submitted_student_parent_phone?: string;
  /**
   * The ID of the school the student is attending.
   */
  submitted_school?: string;
  /**
   * The details of the student's need.
   */
  description: string;
  /**
   * The studentId of Purple User SAF Form.
   */
  student?: string;
};

export type TSafTranslations = 'en' | 'ar' | 'es' | 'vi' | 'zh';

export type TSafNeedWithTranslation = Prettify<
  TNameableEntity & {
    translations: Record<TSafTranslations, string>;
  }
>;

export const SAF_STATUS = {
  NEW: 'new',
  PRIORITIZED: 'prioritized',
  ON_HOLD: 'on_hold',
  CLOSED: 'closed',
} as const;
export type TSafStatus = ValueOf<typeof SAF_STATUS>;

export const SAF_PRIORITY = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
} as const;
export type TSafPriority = ValueOf<typeof SAF_PRIORITY>;

export const SAF_RESOLUTION = {
  CHECKED_IN_WITH_STUDENT: 'checked_in_with_student',
  COMMUNICATED_WITH_PARENTS: 'communicated_with_parents',
  CREATED_A_PLAN: 'created_a_plan_with_student_parent_staff',
  PROVIDED_RESOURCE: 'provided_resource',
  COMMUNICATED_WITH_FACULTY: 'communicated_with_faculty_staff',
  COMMUNICATED_WITH_SERVICE_PROVIDERS: 'communicated_with_service_providers',
  CONNECTED_TO_INTERNAL_SERVICE: 'connected_to_internal_service',
  CONNECTED_TO_EXTERNAL_SERVICE: 'connected_to_external_service',
  OTHER: 'other',
  NO_NEED_IDENTIFIED: 'no_need_identified',
  STUDENT_UNIDENTIFIABLE: 'student_unidentifiable',
  REFERRED_TO_SERVICE: 'referred_to_service',
  ATTEMPTED_ACTION: 'attempted_action',
  CONNECTED_TO_SERVICE: 'connected_to_service',
} as const;
export type TSafResolution = ValueOf<typeof SAF_RESOLUTION>;

export type TSafListManagerRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string | null;
  time_period?: string | null;
  status?: string | null;
  priority?: string | null;
  current_owner?: string | null;
};

export type TSafListManagerStudent = {
  id: string;
  full_name: string;
  subpop: ISubPopulation[];
  grade: string;
  school: TSchoolItem;
  student_id: string;
  photo: string;
  phone?: string[];
};

export type TSafOwner = {
  id: string;
  email: string;
  full_name: string;
  avatar: string;
};

export type TSafListManagerDto = {
  id: string;
  saf_number: string;
  submitted_student_first_name: string;
  submitted_student_last_name: string;
  student: TSafListManagerStudent | null;
  priority: string;
  submitter: TSafSubmitter;
  submitter_type: TSafSubmitterType;
  status: TSafStatus;
  created_at: string;
  priority_assigned_at: string | null;
  current_owner: TSafOwner | null;
  is_not_prioritized_in_2_days: boolean;
  has_unread_email: boolean;
  documented_actions: number;
};

export type TSafDetailsDto = {
  id: string;
  saf_processing_step: TSafProcessStepsValues;
  saf_number: string;
  status: TSafStatus;
  priority: TSafPriority | null;
  description: string;
  title: string;
  description_translated: string;
  on_hold_details: TSafOnHoldDetails | null;
  closed_saf_details: TSafClosedDetails | null;
  saf_submission_details: TSafSubmissionDetails | null;
  no_need_details: TSafNoNeedDetails | null;
  saf_details: TSafDetails | null;
  linked_student_details: (TSafListManagerStudent & { phone: string[] }) | null;
  saf_stats: TSafStats | null;
  documented_actions: number;
};

export type TSaflDetailsUpdateBody = Prettify<
  Partial<Pick<TSafDetailsDto, 'priority'>> & {
    student?: string;
    on_hold_reason?: string;
    on_hold_reason_text?: string;
    no_need_reason?: string[];
    no_need_reason_text?: string;
  }
>;

export type TSafOnHoldDetails = {
  on_hold_at: string;
  on_hold_reason: string;
  on_hold_reason_text: string;
};

export type TSafNoNeedDetails = {
  status_changed_at: string;
  no_need_reason: TSafNoNeedReasonLabels[];
  no_need_reason_text: string;
};

export type TSafClosedDetails = {
  closed_at: string;
  time_until_closed_days: number | null;
  resolution: string[];
  service_area_subcategories: Record<TSafAreaSubcategoriesLabels, TSafServiceAreaSubcategory[]>;
};

export type TSafServiceAreaSubcategory = {
  id: string;
  name: string;
};

export type TSafSubmissionDetails = {
  submitted_student_first_name: string;
  submitted_student_middle_name: string;
  submitted_student_last_name: string;
  submitted_student_grade: string;
  submitter_type: TSafSubmitterType;
  submitted_student_school: string;
  is_submitted_anonymously: boolean;
  submitter_name: string;
  submitter_phone: string;
  submitter_email: string;
};
export type TSafDetails = {
  saf_need: string | null;
  created_at: string;
  priority_assigned_at: string | null;
  time_until_closed_days: number | null;
  saf_owner: string;
};

export type TSafCloseCheckboxOption = {
  name: string;
  display_name: string;
};

export type TSafCloseOptions = Record<TSafAreaCategoriesValues, TSafCloseCheckboxOption[]>;

export type TSafStats = {
  created_at: string;
  status_changed_at: string | null;
  days_in_current_status: number;
};

export type TSafManagerTotals = {
  new: number;
  on_hold: number;
  prioritized: number;
  closed: 0;
};

export type TSafListManagerResponse<T> = IPaginatedResponse<T> & {
  totals: TSafManagerTotals;
};

export type TSafListManagerFilterSchool = {
  id: string;
  name: string;
};

export type TSafListManagerFilterCurrentOwner = {
  id: string;
  full_name: string;
};

export type TSafListManagerFilters = {
  school: TSafListManagerFilterSchool[];
  current_owner: TSafListManagerFilterCurrentOwner[];
};

export type TSafPlaceOnHoldRequest = {
  on_hold_reason: string;
  on_hold_reason_text?: string;
  on_hold_until?: string;
};

export type TSafRemoveFromHoldRequest = {
  priority: string;
};

export type TSafTransferRequest = {
  is_has_school_access: boolean;
  current_owner?: string;
  transfer_reason: string;
};

export type TSafNoNeedRequest = {
  no_need_reason: string[];
  no_need_reason_text: string;
};

export type TSafEmailListRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string | null;
  status?: string | null;
};

export type TSafHistoryRequestParameters = IBasePaginationRequestParameters;

export const EmailStatus = {
  New: 'new',
  Sent: 'sent',
  Acknowledged: 'acknowledged',
  Pending: 'pending',
  Failed: 'failed',
} as const;
export type TEmailStatus = ValueOf<typeof EmailStatus>;

export type TSafCorrespondenceListItem = {
  id: string;
  subject: string;
  from_email: string;
  to_email: string;
  created_at: string;
  status: TEmailStatus;
};
export type TSafCorrespondenceListResponse = IPaginatedResponse<TSafCorrespondenceListItem[]>;

export type TSafCorrespondenceAttachment = {
  created_at: string;
  file: string;
  filename: string;
  id: string;
  size: number;
};

export type TSafCorrespondenceMessage = {
  id: string;
  sender_email: string;
  recipient_email: string;
  message_json: string;
  message_html: string;
  status: TEmailStatus;
  failed_reason?: string | null;
  created_at: string;
  attachments: TSafCorrespondenceAttachment[];
};

export type TSafCorrespondence = {
  id: string;
  subject: string;
  email_messages: TSafCorrespondenceMessage[];
};

export type TSafHistory = {
  id: number;
  field: string;
  original_value: string | null;
  new_value: string;
  user: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar'>;
  user_pk_as_string: string;
  datetime: string;
};
export type TSafHistoryResponse = IPaginatedResponse<TSafHistory[]>;

export type TSafSendEmailPayload = {
  safId: string;
  subject: string;
  message_json: string;
  message_html: string;
  attachments: File[];
};

export type TSafSendEmailResponse = {
  id: string;
  saf: string;
  subject: string;
};

export type TSafReplyEmailPayload = {
  safId: string;
  correspondenceId: string;
  message_json: string;
  message_html: string;
  attachments: File[];
};

export type TSafReplyEmailResponse = {
  id: string;
  message_html: string;
  message_json: string;
  recipient_email: string;
  sender_email: string;
};

export type TSafTextTranslationsResponse = {
  id: number;
  // This is string JSON field
  introduction_text: string;
  // This is string JSON field
  acknowledgement_text: string;
  // This is string JSON field
  disclaimer_text: string;
  // This is string JSON field
  special_ed_text: string;
};

export type TSafTextTranslationsView = {
  id: number;
  introduction_text: TAdminTranslation;
  acknowledgement_text: TAdminTranslation;
  disclaimer_text: TAdminTranslation;
  special_ed_text: TAdminTranslation;
};

export type TSafSubmitterTypeItem = {
  id: string;
  type: TSafSubmitterType;
  is_active: boolean;
};

export type TSafSubmitterTypesListResponse = {
  id: number;
  submitter_types: TSafSubmitterTypeItem[];
};

export type TSafNeedItem = {
  id: string;
  name: string;
  translations: TAdminTranslation;
};

export type TSafFieldSettingItem = {
  id: string;
  field_name: string;
  field_label: string;
  translations: TAdminTranslation;
  is_required: boolean;
  is_always_required: boolean;
};

export type TSafFieldSettingsResponse = {
  id: number;
  needs: TSafNeedItem[];
  field_settings: TSafFieldSettingItem[];
  can_submit_anonymously: boolean;
};
