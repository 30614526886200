import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { Toaster, TooltipProvider } from '@purple/ui';
import { queryClient } from '~/constants/query-client';
import { ApiProvider } from '~/providers/ApiProvider';
import { RouteHistoryProvider } from '~/providers/RouteHistoryProvider';
import { ThemeProvider } from '~/providers/ThemeProvider';

import { PurpleApplication } from './App';
import { store } from './store';
import '@purple/ui/main.css';

const rootElement = document.querySelector('#root')!;
ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ApiProvider>
            <RouteHistoryProvider>
              <ThemeProvider>
                <TooltipProvider>
                  <PurpleApplication />
                  <Toaster />
                </TooltipProvider>
              </ThemeProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </RouteHistoryProvider>
          </ApiProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
);
