import { Text } from '@purple/ui';
import { DataTable } from '~/components';
import { studentTotalFlagsColumns } from './studentTotalFlagsColumns';
import type { FC } from 'react';
import type { TStudentTotalFlagsItem } from '@purple/shared-types';

type TStudentTotalFlagsSectionProps = {
  totalFlagsData: TStudentTotalFlagsItem[];
};

export const StudentTotalFlagsSection: FC<TStudentTotalFlagsSectionProps> = ({ totalFlagsData }) => {
  return (
    <div className="flex flex-col">
      <Text variant="size-14" type="body-600" className="text-grey-title">Total Flags</Text>
      <Text variant="size-10" type="body-400" className="text-grey-800">{`Total ${totalFlagsData.length} records`}</Text>
      <DataTable
        columns={studentTotalFlagsColumns}
        data={totalFlagsData}
        emptyStateTitle="No Total Flags found"
        emptyStateMessage="There are no Total Flags to display. If it an error, please contact support."
      />
    </div>
  );
};
