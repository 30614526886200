import { memo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Checkbox, FormControl, FormField, FormItem, FormLabel, Label, Separator } from '@purple/ui';
import type { FC } from 'react';
import type { Path } from 'react-hook-form';
import type { TStudentDataPrintSettingsSchema } from './schema';

type TGroupCheckBoxesWrapper = {
  categoryName: Path<Pick<TStudentDataPrintSettingsSchema, 'attempted_category' | 'general_category' | 'targeted_category' | 'color_trends_category' | 'safs_category'>>;
  subcategoryName: Path<Pick<TStudentDataPrintSettingsSchema, 'safs' | 'attempted' | 'general' | 'student_color_trends' | 'targeted'>>;
  categoryTitle: string;
  /**
   * Options for the checkboxes in the group (values)
   */
  options: string[];
};

export const GroupCheckBoxes: FC<TGroupCheckBoxesWrapper> = memo(({ categoryName, categoryTitle, subcategoryName, options }) => {
  const { watch, setValue, control } = useFormContext<TStudentDataPrintSettingsSchema>();

  const isCategoryChecked = watch(categoryName);
  // The issue likely comes from Path utility type from react-hook-form. When using watch(), TypeScript can't infer that these fields are always arrays.
  const subCategories = watch(subcategoryName) as string[];

  const categoryChangeHandler = useCallback((checked: boolean) => {
    setValue(categoryName, checked);
    setValue(subcategoryName, checked ? options : []);
  }, [setValue, categoryName, subcategoryName, options]);

  const subcategoryChangeHandler = useCallback((option: string) => {
    const updatedSubcategories = subCategories.includes(option)
      ? subCategories.filter((subcategory) => subcategory !== option)
      : [...subCategories, option];

    setValue(subcategoryName, updatedSubcategories);

    if (updatedSubcategories.length > 0) {
      return setValue(categoryName, true);
    }

    setValue(categoryName, false);
  }, [setValue, subCategories, subcategoryName, categoryName]);

  return (
    <div key={categoryName} id={categoryName} className="rounded-lg border border-grey-200 p-4">
      <div className="flex flex-col gap-2.5">
        <div className="flex items-center gap-2 space-y-0">
          <Checkbox id={categoryName} checked={isCategoryChecked} onCheckedChange={categoryChangeHandler} />
          <Label htmlFor={categoryName} className="text-base font-semibold">{categoryTitle}</Label>
        </div>
        <Separator className="bg-grey-200" />
        <div className="flex flex-col gap-2 pl-6">
          {options.map((option) => (
            <FormField
              key={option}
              control={control}
              name="student_color_trends"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value={field.value.toString()}
                      checked={subCategories.includes(option)}
                      className="size-5"
                      onCheckedChange={() => subcategoryChangeHandler(option)}
                    />
                  </FormControl>
                  <FormLabel className="text-sm font-medium text-grey-title">
                    {snakeToCamelWithSpaces(option)}
                  </FormLabel>
                </FormItem>
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
});
