import { useCallback, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ModalType } from '~/constants/modals';
import { useCurrentUser, useModal } from '~/hooks';
import { useMarkBannerAsRead } from '~/queries';
import { BannerItem } from './BannerItem';
import { BannerPreviewDialog } from './BannerPreviewDialog';
import { makeErrorBannersToTheTop } from './helpers';
import type { TUserBanner } from '@purple/shared-types';

export const BannerSection: React.FC = () => {
  const { user } = useCurrentUser();

  const { toggleModal: toggleDetailsModal } = useModal(ModalType.BANNER_DETAILS);

  const [banners, setBanners] = useState<TUserBanner[]>(makeErrorBannersToTheTop(user.notifications ?? []));
  const [isHidden, setIsHidden] = useState<boolean>(banners.length === 0);
  const [selectedBanner, setSelectedBanner] = useState<TUserBanner | null>(null);

  const { mutate: markAsRead } = useMarkBannerAsRead();

  useEffect(() => {
    setBanners(makeErrorBannersToTheTop(user.notifications ?? []));
  }, [user.notifications]);

  const closeBannerClickHandler = useCallback((banner: TUserBanner) => {
    setBanners((prevBanners) => prevBanners.filter((item) => item.id !== banner.id));
    markAsRead(banner.id);
    toggleDetailsModal(false);
  }, [markAsRead, toggleDetailsModal]);

  const learnMoreClickHandler = useCallback((banner: TUserBanner) => {
    setSelectedBanner(banner);
    toggleDetailsModal(true);
  }, [toggleDetailsModal]);

  if (isHidden) return null;

  return (
    <ul className="flex w-full max-w-[1440px] flex-col gap-4 px-8 pt-8">
      <AnimatePresence onExitComplete={() => setIsHidden(banners.length === 0)}>
        {banners.map((item) => (
          <BannerItem key={item.id} banner={item} onClose={closeBannerClickHandler} onLearnMore={learnMoreClickHandler} />
        ))}
      </AnimatePresence>
      <BannerPreviewDialog banner={selectedBanner} onSuccess={closeBannerClickHandler} />
    </ul>
  );
};
