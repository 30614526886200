import { Heading, Text } from '@purple/ui';

const DeactivatedDistrict = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="flex w-[500px] flex-col items-center justify-center gap-5">
        <Heading tag="h1" variant="size-28" type="heading-700">
          District deactivated
        </Heading>
        <Text variant="size-16" type="body-400" className="text-grey-600">
          Unfortunately, the requested district has been deactivated. Please contact the site administration for further information.
        </Text>
      </div>
    </div>
  );
};

export default DeactivatedDistrict;
