import { type FC, memo } from 'react';
import { Label, Pie, PieChart } from 'recharts';
import { Card, CardContent, CardHeader, ChartLegend, cn, EMPTY_CHART_DATA, ReChartContainer, Text } from '@purple/ui';
import type { TChartConfig } from '@purple/ui';

type TPieChartSectionProperties = {
  title: string;
  chartData: {
    name: string;
    value: number;
    color: string;
  }[];
  chartConfig: TChartConfig;
  chartLegendClassName?: string;
  containerClassName?: string;
  isActiveAnimation?: boolean;
};

export const PieChartSection: FC<TPieChartSectionProperties> = memo(({ title, chartData, chartConfig, chartLegendClassName, containerClassName, isActiveAnimation = false }) => {
  const totalValue = chartData.reduce((acc, { value }) => acc + value, 0);
  return (
    <Card className={cn('p-2', containerClassName)}>
      <CardHeader className="flex flex-row items-center justify-between gap-2 space-y-0 rounded-none">
        <Text variant="size-10" type="body-600">
          {title}
        </Text>
      </CardHeader>
      <CardContent className="mt-1 flex gap-1">
        <ReChartContainer config={chartConfig} className="aspect-square h-[120px]">
          <PieChart accessibilityLayer>
            <Pie
              dataKey="value"
              data={totalValue > 0 ? chartData : EMPTY_CHART_DATA}
              outerRadius={55}
              innerRadius={35}
              strokeWidth={5}
              isAnimationActive={isActiveAnimation}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                    return (
                      <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
                        <tspan x={viewBox.cx} y={viewBox.cy} className="text-xl font-bold">
                          {totalValue}
                        </tspan>
                      </text>
                    );
                  }
                  return null;
                }}
              />
            </Pie>
          </PieChart>
        </ReChartContainer>
        <ChartLegend data={chartData} className={cn(chartLegendClassName)} labelsClassName="text-[10px]" valuesClassName="text-[10px]" />
      </CardContent>
    </Card>
  );
});
