import * as z from 'zod';
import { TIME_PERIODS } from '~/constants/options';

export const studentDataPrintSettingsModalSchema = z.object({
  color_trends_category: z.boolean().default(false),
  safs_category: z.boolean().default(false),
  general_category: z.boolean().default(false),
  targeted_category: z.boolean().default(false),
  attempted_category: z.boolean().default(false),
  time_period: z.nativeEnum(TIME_PERIODS),
  is_private: z.boolean().default(false),
  student_color_trends: z.array(z.string()),
  safs: z.array(z.string()),
  general: z.array(z.string()),
  targeted: z.array(z.string()),
  attempted: z.array(z.string()),
});

export type TStudentDataPrintSettingsSchema = z.infer<typeof studentDataPrintSettingsModalSchema>;
