import { TakeActionDocumentType } from '@purple/shared-types';

export const SEARCH_DEBOUNCE_MS = 1000;

export const ACTIONS_SORT_SELECT_OPTIONS = [
  {
    label: 'Name (ASC)',
    value: 'title',
  },
  {
    label: 'Name (DESC)',
    value: '-title',
  },
  {
    label: 'Date (ASC)',
    value: 'date_and_time',
  },
  {
    label: 'Date (DESC)',
    value: '-date_and_time',
  },
  {
    label: 'Document As (ASC)',
    value: 'document_as',
  },
  {
    label: 'Document As (DESC)',
    value: '-document_as',
  },
  {
    label: 'Owner (ASC)',
    value: 'created_by__last_name',
  },
  {
    label: 'Owner (DESC)',
    value: '-created_by__last_name',
  },
] as const;

export const SCHOOL_ACTION_FILTERS_OPTIONS = {
  categories: [
    {
      label: 'Document As',
      value: 'document_as',
      filters: [
        {
          label: 'General',
          value: TakeActionDocumentType.GENERAL,
        },
        {
          label: 'Targeted',
          value: TakeActionDocumentType.TARGETED,
        },
        {
          label: 'Attempted',
          value: TakeActionDocumentType.ATTEMPTED,
        },
      ],
    },
  ],
};
