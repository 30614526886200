import { getAxiosInstance } from '../../api';
import { TAGS_ENDPOINTS } from '../../constants/private-api';
import type { TCustomTagListResponse, TCustomTagsListRequestParameters } from '@purple/shared-types';

export const getTagsList = async (parameters: TCustomTagsListRequestParameters) => {
  const apiInstance = getAxiosInstance();
  const response = await apiInstance.get<TCustomTagListResponse>(TAGS_ENDPOINTS.ROOT, {
    params: parameters,
  });
  return response.data;
};
