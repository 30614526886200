/* eslint-disable react/no-array-index-key */
import { Skeleton } from '@purple/ui';

export const TakeActionListSkeleton: React.FC = () => {
  return (
    <div className="border-grey-200 max-h-max overflow-y-auto overflow-x-hidden rounded-lg border p-4 pb-2">
      <div className="text-grey-950 [&_[cmdk-group-heading]]:text-grey-600 overflow-hidden [&_[cmdk-group-heading]]:mb-2 [&_[cmdk-group-heading]]:px-4 [&_[cmdk-group-heading]]:text-sm [&_[cmdk-group-heading]]:font-medium" role="status" data-value="Action">
        <Skeleton className="mb-4 h-5 w-full max-w-[80px]" />
        <div className="flex w-full flex-col gap-2">
          {Array.from({ length: 9 }).map((_, index) => (
            <Skeleton key={index} className="h-8 w-full" />
          ))}
        </div>
      </div>
    </div>
  );
};
