import { getRandomColor, snakeCaseToCapitalized } from '@purple/shared-utils';
import { CHART_COLORS } from '~/modules/Home/Tabs/Dashboard/constants';

const MAX_VISIBLE_SEGMENTS = 6;

export const processChartData = (data: Array<{ label: string; value: number }>) => {
  if (!data.length) return [];

  if (data.length <= MAX_VISIBLE_SEGMENTS) return data;

  const mainSegments = data.slice(0, MAX_VISIBLE_SEGMENTS);
  const otherValue = data
    .slice(MAX_VISIBLE_SEGMENTS)
    .reduce((sum, item) => sum + Number(item.value), 0);

  return [...mainSegments, { label: 'Other', value: otherValue }];
};

export const createConfigAndData = (transformedData: Array<{ label: string; value: number }>) => {
  const config = transformedData.reduce<Record<string, { label: string; color: string }>>(
    (accumulator, { label }, index) => ({
      ...accumulator,
      [label]: {
        label,
        color: CHART_COLORS[index] ?? getRandomColor(),
      },
    }),
    {},
  );

  const data = transformedData.map(({ label, value }) => ({
    name: snakeCaseToCapitalized(label),
    value: Number(value),
    fill: config[label]!.color!,
    color: config[label]!.color!,
  }));

  return { config, data };
};
