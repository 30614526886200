import { useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import {
  AccessDenied,
  Card,
  CardContent,
  CardData,
  CardDataDescription,
  CardDataIndicator,
  CardDataValue,
  CardTitle,
  ChartCardContainer,
  ChartCardContent,
  ChartCardFooter,
  ChartCardHeader,
  createLineChartData,
  Heading,
  LineChart,
} from '@purple/ui';
import DownloadableWrapper from '~/components/DownloadableWrapper/DownloadableWrapper';
import { AppRoutes } from '~/constants/routes/routes';
import { useGetStudentTrendsAndFlags } from '~/queries/student-trends-and-flags';
import { StudentDetailsCalendar } from '../../../StudentDetailsCalendar';
import DashboardSkeleton from '../Dashboard/DashboardSkeleton';
import { TotalFlagsComponent, TotalFlagsEmpty } from './TotalFlags';
import type { TCardDataFormatterContext } from '@purple/ui';
import type { DownloadableWrapperReference } from '~/components';

const ATTENDANCE_ANALYTICS_FIELDS = [
  {
    name: 'Current Year',
    color: '#AE5983',
    yAxisLabel: 'Total Flags',
  },
  {
    name: 'Previous Year',
    color: '#E1C122',
    yAxisLabel: 'Total Flags',
  },
];

const BEHAVIOR_ANALYTICS_FIELDS = [
  {
    name: 'Current Year',
    color: '#4554A5',
    yAxisLabel: 'Total Flags',
  },
  {
    name: 'Previous Year',
    color: '#F89B3F',
    yAxisLabel: 'Total Flags',
  },
];

const TrendAndFlags = () => {
  const navigate = useNavigate();

  const attendanceTrendsRef = useRef<DownloadableWrapperReference>(null);
  const behaviorAnalysisRef = useRef<DownloadableWrapperReference>(null);

  const { isLoading, data, studentId } = useGetStudentTrendsAndFlags();

  const { avgAttendancePerCycle, studentTrending, flagsPlot } = data || {};

  const isTotalFlagsDataExist = flagsPlot?.plot.length && flagsPlot?.colorRanges;

  const formatAttendanceDescription = ({ isIncrease, isDecrease }: TCardDataFormatterContext): string => {
    const { prevRate } = avgAttendancePerCycle || {};
    if (isIncrease) {
      return `up from ${prevRate} last year`;
    }
    if (isDecrease) {
      return `down from ${prevRate} last year`;
    }
    return 'stable compared to last year';
  };

  const formatStudentIsDescription = ({ isIncrease, isDecrease }: TCardDataFormatterContext): string => {
    const { currentRate, prevRate } = studentTrending || {};

    if (isIncrease) {
      return `up from ${currentRate} last cycle`;
    }
    if (isDecrease) {
      return `down from ${prevRate} last cycle`;
    }
    return 'stable compared to last cycle';
  };

  const studentIsTitle = useMemo(() => {
    if (studentTrending) {
      const { currentRate, prevRate } = studentTrending || {};

      if (currentRate < prevRate) {
        return 'Student is - Trending Green';
      }
      if (currentRate > prevRate) {
        return 'Student is - Trending Red';
      }
      return 'Student is - Trending Yellow';
    }
    return '';
  }, [studentTrending]);

  const navigateToAttendanceReport = useCallback(() => {
    navigate(AppRoutes.App.Students.StudentDetails.AttendanceTrendsReport.makePath(studentId));
  }, [navigate, studentId]);

  const navigateToBehaviorReport = useCallback(() => {
    navigate(AppRoutes.App.Students.StudentDetails.BehaviorAnalysisReport.makePath(studentId));
  }, [navigate, studentId]);

  const downloadAttendanceTrendsChartHandler = useCallback(() => {
    attendanceTrendsRef.current?.downloadImage({
      scale: 1,
      quality: 1,
      format: 'png',
    });
  }, [attendanceTrendsRef]);

  const downloadBehaviorAnalysisChartHandler = useCallback(() => {
    behaviorAnalysisRef.current?.downloadImage({
      scale: 1,
      quality: 1,
      format: 'png',
    });
  }, [behaviorAnalysisRef]);

  if (isLoading) return <DashboardSkeleton />;

  return (
    <div className="flex flex-col gap-4 px-4 pb-4 pt-6">
      <Heading type="heading-600" variant="size-18" className="text-grey-title">
        Color Trends Statistic
      </Heading>
      <div className="grid grid-cols-2 gap-4">
        {Boolean(avgAttendancePerCycle) && (
          <Guard
            requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_COLOR_TRENDS_STATISTICS]}
            fallback={(
              <AccessDenied
                iconSize={24}
                accessDeniedMessage="You do not have permission to view this statistic."
                className="size-full rounded-lg border border-grey-200 bg-white p-6 py-2"
              />
            )}
          >
            <Card tag="li">
              <CardTitle>AVG Attendance Flags per Cycle this Year</CardTitle>
              <CardContent>
                <CardData
                  value={avgAttendancePerCycle?.currentRate as number}
                  compareValue={avgAttendancePerCycle?.prevRate as number}
                  reversed
                >
                  <CardDataValue applyTrendingColor enableAnimation formatterOptions={{ maximumFractionDigits: 2 }} />
                  <CardDataIndicator />
                  <CardDataDescription formatter={formatAttendanceDescription} />
                </CardData>
              </CardContent>
            </Card>
          </Guard>
        )}
        {Boolean(studentTrending) && (
          <Guard
            requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_COLOR_TRENDS_STATISTICS]}
            fallback={(
              <AccessDenied
                iconSize={24}
                accessDeniedMessage="You do not have permission to view this statistic."
                className="size-full rounded-lg border border-grey-200 bg-white p-6 py-2"
              />
            )}
          >
            <Card tag="li">
              <CardTitle>{studentIsTitle}</CardTitle>
              <CardContent>
                <CardData
                  value={studentTrending?.currentRate as number}
                  compareValue={studentTrending?.prevRate as number}
                  reversed
                >
                  <CardDataValue applyTrendingColor enableAnimation formatterOptions={{ maximumFractionDigits: 2 }} />
                  <CardDataIndicator />
                  <CardDataDescription formatter={formatStudentIsDescription} />
                </CardData>
              </CardContent>
            </Card>
          </Guard>
        )}
      </div>
      {isTotalFlagsDataExist
        ? (
            <TotalFlagsComponent data={flagsPlot.plot} colorRange={flagsPlot.colorRanges} />
          )
        : (
            <TotalFlagsEmpty />
          )}
      <div className="flex gap-4">
        <DownloadableWrapper ref={attendanceTrendsRef} fileName={`Attendance Trends for ${studentId}`} containerClassName="w-full max-w-[850px]">
          <ChartCardContainer>
            <ChartCardHeader onClick={downloadAttendanceTrendsChartHandler} title="Attendance Trends" />
            <ChartCardContent className="p-0">
              {data && (
                <LineChart
                  fields={ATTENDANCE_ANALYTICS_FIELDS}
                  chartData={createLineChartData(
                    'Current Year',
                    'Previous Year',
                    data.attendanceAnalysis.currentYear,
                    data.attendanceAnalysis.previousYear,
                  )}
                />
              )}
            </ChartCardContent>
            <ChartCardFooter onClick={navigateToAttendanceReport} actionTitle="View Report" />
          </ChartCardContainer>
        </DownloadableWrapper>
        <DownloadableWrapper ref={behaviorAnalysisRef} fileName={`Behavior Analysis for ${studentId}`} containerClassName="w-full max-w-[850px]">
          <ChartCardContainer>
            <ChartCardHeader onClick={downloadBehaviorAnalysisChartHandler} title="Behavior Analysis" />
            <ChartCardContent className="p-0">
              {data && (
                <LineChart
                  fields={BEHAVIOR_ANALYTICS_FIELDS}
                  chartData={createLineChartData(
                    'Current Year',
                    'Previous Year',
                    data?.behaviorAnalysis.currentYear,
                    data?.behaviorAnalysis.previousYear,
                  )}
                />
              )}
            </ChartCardContent>
            <ChartCardFooter onClick={navigateToBehaviorReport} actionTitle="View Report" />
          </ChartCardContainer>
        </DownloadableWrapper>
      </div>
      <Guard
        requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_STUDENT_CALENDAR]}
        fallback={(
          <AccessDenied
            accessDeniedMessage="You do not have permission to view a calendar."
            className="size-full rounded-lg border border-grey-200 bg-white p-6 py-24"
          />
        )}
      >
        {studentId && <StudentDetailsCalendar studentId={studentId} />}
      </Guard>
    </div>
  );
};

export { TrendAndFlags };
