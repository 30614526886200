import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { postActionChoices } from '@purple/shared-services';
import {
  SAF_AREA_SUBCATEGORIES_LABELS,
  type TActionChoiceItem,
  type TActionChoicesRequestParameters,
} from '@purple/shared-types';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useActionChoices = (parameters: TActionChoicesRequestParameters) => {
  const wasRendered = useRef(false);
  const [data, setData] = useState<TActionChoiceItem | null>(null);

  const { mutate: callActionChoices, isError } = useMutation({
    mutationKey: [ACTIONS_QUERY_KEYS.GET_ACTION_CHOICES, parameters],
    mutationFn: postActionChoices,
    onSuccess: (response) => {
      setData(response);
    },
    onError: () => {
      showErrorToast('Failed to fetch action choices', 'Please try again later');
    },
  });

  useEffect(() => {
    const { limit = 100, offset = 0, record_action_type, students, priority_list, enabled } = parameters;

    if (enabled && !wasRendered.current) {
      wasRendered.current = true;
      callActionChoices({
        limit,
        offset,
        record_action_type,
        priority_list,
        ...(students && {
          students: Array.isArray(students) ? [...students] : [students],
        }),
      });
    }
  }, [parameters, wasRendered, callActionChoices]);

  const typeOptions = useMemo(
    () => (data?.type ? Object.entries(data.type).map(([value, label]) => ({ value, label })) : []),
    [data?.type],
  );

  const subTypeOptions = useMemo(
    () => (data?.subtype ? Object.entries(data.subtype).map(([value, label]) => ({ value, label })) : []),
    [data?.subtype],
  );

  const serviceOptions = useMemo(
    () =>
      data?.service_categories?.map((service) => ({
        label: SAF_AREA_SUBCATEGORIES_LABELS[service.name],
        value: service.id,
      })) ?? [],
    [data?.service_categories],
  );

  const focusAreaOptions = useMemo(
    () => data?.focus_areas?.map((focusArea) => ({ label: focusArea.name, value: focusArea.id.toString() })) ?? [],
    [data?.focus_areas],
  );

  const levelOfDistressOptions = useMemo(
    () =>
      data?.level_of_distress ? Object.entries(data.level_of_distress).map(([value, label]) => ({ value, label })) : [],
    [data?.level_of_distress],
  );

  const suicideScreeningAssessmentResultOptions = useMemo(
    () =>
      data?.suicide_screening_assessment_result
        ? Object.entries(data?.suicide_screening_assessment_result).map(([value, label]) => ({ value, label }))
        : [],
    [data?.suicide_screening_assessment_result],
  );

  const sessionParticipantsOptions = useMemo(
    () =>
      data?.session_participants
        ? Object.entries(data.session_participants).map(([value, label]) => ({ value, label }))
        : [],
    [data?.session_participants],
  );

  const treatAssessmentResultOptions = useMemo(
    () =>
      data?.threat_assessment_result
        ? Object.entries(data.threat_assessment_result).map(([value, label]) => ({ value, label }))
        : [],
    [data?.threat_assessment_result],
  );

  const crisisTypeOptions = useMemo(
    () => (data?.crisis_types ? Object.entries(data.crisis_types).map(([value, label]) => ({ value, label })) : []),
    [data?.crisis_types],
  );

  return {
    data,
    isError,
    typeOptions,
    subTypeOptions,
    serviceOptions,
    focusAreaOptions,
    levelOfDistressOptions,
    suicideScreeningAssessmentResultOptions,
    sessionParticipantsOptions,
    treatAssessmentResultOptions,
    crisisTypeOptions,
  };
};
