import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LANGUAGE_QUERY_NAME, type TSafFieldSettingsResponse } from '@purple/shared-types';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadioGroup,
  RadioGroupItem,
  Textarea,
} from '@purple/ui';
// hooks
import { useArabicLanguage } from '../hooks';
// components
import { DetailsDescription } from './DetailsDescription';
// types
import type React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { z } from 'zod';
import type { safFormSchema } from './schema';

type TObservationFormProperties = {
  /**
   * The form object that contains the form state and methods.
   */
  form: UseFormReturn<z.infer<typeof safFormSchema>>;
  /**
   * The transition delta that determines the direction of the form transition.
   */
  transitionDelta: number;
  /**
   * The field settings data that contains the form settings.
   */
  fieldSettingsData: TSafFieldSettingsResponse;
};

export const ObservationForm: React.FC<TObservationFormProperties> = (props) => {
  const { form, transitionDelta, fieldSettingsData } = props;

  const [searchParameters] = useSearchParams();
  const currentLanguage = searchParameters.get(LANGUAGE_QUERY_NAME) || 'en';

  const { t } = useTranslation('saf');
  const { isArabic } = useArabicLanguage();

  const submitterType = form.watch('submitter');
  const isSelfStudent = form.watch('myself');

  const translatedText = useMemo(() => {
    const translatedText = {
      description: {
        text: '',
        is_required: false,
      },
      select_what_this_saf_is_for: {
        text: '',
        is_required: false,
      },
    };

    fieldSettingsData?.field_settings.forEach((field) => {
      Object.keys(translatedText).forEach((key) => {
        if (field.field_name === key) {
          const translatedWord = field.translations[currentLanguage as keyof typeof field.translations];
          const isRequiredField = field.is_required;
          translatedText[key as keyof typeof translatedText].text = translatedWord || '.';
          translatedText[key as keyof typeof translatedText].is_required = isRequiredField;
        }
      });
    });

    return translatedText;
  }, [fieldSettingsData, currentLanguage]);

  const needsList = useMemo(() => {
    const needs = fieldSettingsData.needs;
    if (!needs) return [];

    return needs.map(({ id, translations }) => ({
      value: id,
      label: translations[currentLanguage as keyof typeof translations] || '',
    }));
  }, [fieldSettingsData, currentLanguage]);

  return (
    <motion.div
      initial={{ x: transitionDelta >= 0 ? '50%' : '-50%', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className="grid w-full grid-cols-2 gap-x-4 gap-y-6"
    >
      <FormField
        control={form.control}
        name="needs"
        render={({ field }) => (
          <FormItem className="col-span-2 space-y-2">
            <FormLabel required={translatedText.select_what_this_saf_is_for.is_required}>{translatedText.select_what_this_saf_is_for.text}</FormLabel>
            <FormControl>
              <RadioGroup onValueChange={field.onChange} value={field.value} dir={isArabic ? 'rtl' : 'ltr'}>
                {needsList.map(({ label, value }) => (
                  <FormItem key={value} className="flex items-center gap-x-2 space-y-0">
                    <FormControl>
                      <RadioGroupItem value={value} />
                    </FormControl>
                    <FormLabel>{label}</FormLabel>
                  </FormItem>
                ))}
              </RadioGroup>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="col-span-2 flex w-full flex-col gap-2">
        <DetailsDescription submitterType={submitterType} isSelfStudent={isSelfStudent} />
        <FormField
          control={form.control}
          name="details"
          render={({ field }) => (
            <FormItem>
              <FormLabel required={translatedText.description.is_required}>{translatedText.description.text}</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!form.formState.errors.details}
                  placeholder={t('form.observation.details.placeholder')}
                  responsiveHeight
                  className="min-h-[120px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </motion.div>
  );
};
