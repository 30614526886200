import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getActivityTypes } from '@purple/shared-services';
import { ACTIVITY_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TActivityTypeEntity } from '@purple/shared-types';
import type { TCustomUseQueryOptions } from '~/types';

export const useActivitiesTypes = (
  options?: TCustomUseQueryOptions<TActivityTypeEntity[], AxiosError>,
) => {
  const { isError, ...rest } = useQuery({
    queryKey: [ACTIVITY_QUERY_KEYS.GET_ACTIVITY_TYPES_LIST],
    queryFn: getActivityTypes,
    ...options,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Unable to get activity types');
    }
  }, [isError]);

  return { isError, ...rest };
};
