import { useEffect } from 'react';
import { useIsMounted, useLocalStorage } from 'usehooks-ts';
import { useQueryParameter } from '@purple/hooks';
import { IMPERSONATE_QUERIES, LocalStorageKey } from '@purple/shared-utils';
import { useLogoutMutation } from '~/queries';

export const useImpersonateToken = () => {
  const isMounted = useIsMounted();

  const [, setStorageToken] = useLocalStorage<string>(
    LocalStorageKey.Auth.Token.ImpersonateToken,
    '',
    { serializer: (value) => value, deserializer: (value) => value, initializeWithValue: false },
  );
  const [, setStorageRefresh] = useLocalStorage<string>(
    LocalStorageKey.Auth.Token.ImpersonateRefresh,
    '',
    { serializer: (value) => value, deserializer: (value) => value, initializeWithValue: false },
  );

  const { query: token, onClearQuery: onClearToken } = useQueryParameter({
    queryName: IMPERSONATE_QUERIES.TOKEN,
  });
  const { query: refresh, onClearQuery: onClearRefresh } = useQueryParameter({
    queryName: IMPERSONATE_QUERIES.REFRESH,
  });

  const { mutate: logout } = useLogoutMutation({
    disableReset: true,
    disabledToast: true,
  });

  useEffect(() => {
    if (token) {
      setStorageToken(token);
      onClearToken();
    }
  }, [token, onClearToken, setStorageToken]);

  useEffect(() => {
    if (refresh) {
      // Optionally logout from the previous impersonation session if the refresh token is present
      const oldRefresh = localStorage.getItem(LocalStorageKey.Auth.Token.ImpersonateRefresh);
      if (oldRefresh && oldRefresh !== refresh && isMounted()) {
        logout({ refresh: oldRefresh });
      }
      setStorageRefresh(refresh);
      onClearRefresh();
    }
  }, [refresh, onClearRefresh, setStorageRefresh, isMounted]); // eslint-disable-line react-hooks/exhaustive-deps
};
