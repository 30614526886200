import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import type { TSafFieldSettingsResponse, TSafSubmitterType } from '@purple/shared-types';

export const getSafFieldSettings = async ({ submitter_type }: {
  submitter_type: TSafSubmitterType;
}) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TSafFieldSettingsResponse>(SAF_ENDPOINTS.FIELD_SETTINGS, {
    params: { submitter_type },
  });
  return response.data;
};
