import { useTranslation } from 'react-i18next';
import { getInitialsFromName } from '@purple/shared-utils';
import { Avatar, AvatarFallback, AvatarImage, Heading, Skeleton, Text } from '@purple/ui';
// store
import { useEffect, useMemo } from 'react';
import { SAF_CARDS } from '~/constants/saf';
import { useAppSelector } from '~/hooks';
import { districtSelector } from '~/store/features/district';
// helpers
import { useStudentAssistance } from '~/hooks/redux';
import { useSafSubmitterTypes } from '~/queries';
// components
import { SafCard } from './SafCard';
// types
import type React from 'react';
import type { TSafSubmitterType } from '@purple/shared-types';

const ANIMATION_DELAY_MULTIPLIER = 0.2;

type TSubmitterCHooserProperties = {
  /**
   * Callback when a SAF submitter card is clicked.
   * @param type - The type of the submitter.
   */
  onSubmitterClick?: (type: TSafSubmitterType) => void;
};

export const SubmitterChooser: React.FC<TSubmitterCHooserProperties> = (props) => {
  const { onSubmitterClick } = props;

  const { t } = useTranslation('saf');
  const { logo, name } = useAppSelector(districtSelector) ?? {};
  const { returnType, setSafReturnType } = useStudentAssistance();

  const { data: submitterTypes, isPending: isSubmitterTypesPending } = useSafSubmitterTypes();

  const districtName = useMemo(() => name ?? 'Unknown School District', [name]);

  useEffect(() => {
    if (returnType !== null) {
      setSafReturnType(null);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const safCards = useMemo(() => {
    const activeSubmitterTypesNames = submitterTypes?.submitter_types.filter((type) => type.is_active).map((type) => type.type) ?? [];

    return SAF_CARDS.filter((card) => activeSubmitterTypesNames.includes(card.type));
  }, [submitterTypes]);

  return (
    <div className="flex w-full flex-col items-center gap-6">
      <Avatar className="size-[108px]">
        <AvatarImage src={logo ?? undefined} alt={districtName} loading="lazy" />
        <AvatarFallback>{getInitialsFromName(districtName, 4)}</AvatarFallback>
      </Avatar>
      <div className="flex w-full flex-col items-center gap-2">
        <Heading tag="h1" variant="size-28" className="font-semibold text-grey-title">
          {t('intro.title', { districtName: getInitialsFromName(districtName, 4) })}
        </Heading>
        <Text variant="size-16" type="body-400" className="text-lg leading-7">
          {t('intro.user')}
        </Text>
      </div>
      {isSubmitterTypesPending
        ? (
            <div className="grid w-[752px] grid-flow-row grid-cols-2 gap-6">
              <Skeleton className="h-[200px] w-[364px]" />
              <Skeleton className="h-[200px] w-[364px]" />
              <Skeleton className="h-[200px] w-[364px]" />
              <Skeleton className="h-[200px] w-[364px]" />
            </div>
          )
        : (
            <ul className="grid w-[752px] grid-flow-row grid-cols-2 gap-6">
              {safCards.map((card, index) => (
                <li key={card.type}>
                  <SafCard
                    style={{ animationDelay: `${index * ANIMATION_DELAY_MULTIPLIER}s` }}
                    iconName={card.icon}
                    label={t(`intro.${card.i18nKey}`)}
                    onCardClick={() => onSubmitterClick?.(card.type)}
                  />
                </li>
              ))}
            </ul>
          )}

    </div>
  );
};
