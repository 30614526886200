import { Text } from '@purple/ui';
import { DataTable } from '~/components';
import { studentBehaviorColumns } from './studentBehaviorColumns';
import type { FC } from 'react';
import type { TTotalIncidentTransformed } from '@purple/shared-types';

type TStudentBehaviorSectionProps = {
  behaviorData: Array<TTotalIncidentTransformed>;
};

export const StudentBehaviorSection: FC<TStudentBehaviorSectionProps> = ({ behaviorData }) => {
  return (
    <div className="flex flex-col">
      <Text variant="size-14" type="body-600" className="text-grey-title">Behavior Analysis</Text>
      <Text variant="size-10" type="body-400" className="text-grey-800">{`Total ${behaviorData.length} records`}</Text>
      <DataTable
        columns={studentBehaviorColumns}
        data={behaviorData}
        emptyStateTitle="No Behavior found"
        emptyStateMessage="There are no Behavior to display. If it an error, please contact support."
      />
    </div>
  );
};
