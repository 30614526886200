import { useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebouncedCallback } from '@purple/hooks';
import { ActivityType, TAGS_TYPES } from '@purple/shared-types';
import {
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  NumberInput,
  TagSelect,
} from '@purple/ui';
import { ReadableActivityType } from '~/constants/create-activity';
import { useAppSelector, useCreateActivity } from '~/hooks';
import { useActivitiesTypes, useCreateNotRecurringActivity, useCustomTags, useSchoolList } from '~/queries';
import { userSelector } from '~/store/features/user';
import { ActivityContainerModal } from '../ActivityContainerModal';
import { hisdSouperFoodPantryEventSchema } from './schema';
import type { z } from 'zod';

type THisdSouperFoodPantryEventModalProperties = {
  onSuccessfulActivity?: () => void;
};

const HisdSouperFoodPantryEventModal = ({ onSuccessfulActivity }: THisdSouperFoodPantryEventModalProperties) => {
  const formId = useId();
  const { toggleActivityModal, isHisdSouperFoodPantryEventModalOpen } = useCreateActivity();

  const { schools } = useAppSelector(userSelector);

  const { data: allTags } = useCustomTags({
    content_type: TAGS_TYPES.COMMUNITY_ACTIVITY,
  }, {
    enabled: isHisdSouperFoodPantryEventModalOpen,
  });
  const { mutate: createActivity, isPending: isCreatingActivity } = useCreateNotRecurringActivity();

  const schoolsOptions = useMemo(() => {
    return schools.map((school) => ({
      label: school.name,
      value: school.id,
    }));
  }, [schools]);

  const [debouncedSchoolSearchValue, setDebouncedSchoolSearchValue] = useState<string>('');

  const { data: schoolData, isFetching: isSchoolsFetching } = useSchoolList({ search: debouncedSchoolSearchValue });

  const debouncedSchoolSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSchoolSearchValue(searchQuery);
  }, 500);

  const { data: activitiesTypes } = useActivitiesTypes();

  const community_activity_type_id = useMemo(() => {
    return activitiesTypes?.find((item) => item.name === ActivityType.HISD_SOUPER_FOOD_PARTY_EVENT)?.id;
  }, [activitiesTypes]);
  const tagOptions = useMemo(() => allTags?.results ?? [], [allTags]);

  const defaultValues = useMemo(() => {
    const [defaultSchool] = schools;

    return {
      school: schools.length === 1 ? defaultSchool?.id : '',
      name: '',
      date_and_time: new Date(),
      bags_provided_count: undefined,
      supported_school: '',
      tags: [],
    };
  }, [schools]);

  const form = useForm<z.infer<typeof hisdSouperFoodPantryEventSchema>>({
    resolver: zodResolver(hisdSouperFoodPantryEventSchema),
    mode: 'onChange',
    defaultValues,
  });

  const formSchool = form.watch('school');

  const supportedSchoolsOptions = useMemo(
    () =>
      schoolData?.results.map((school) => ({
        label: school.name,
        value: school.id,
      })).filter((school) => school.value !== formSchool) ?? [],
    [schoolData?.results, formSchool],
  );

  const resetFormHandler = () => {
    toggleActivityModal(null);
    onSuccessfulActivity?.();
    form.reset(defaultValues);
  };

  const submitActivityHandler = (data: z.infer<typeof hisdSouperFoodPantryEventSchema>) => {
    const body = {
      community_activity_type: community_activity_type_id,
      school: data.school,
      name: data.name,
      date_and_time: data.date_and_time.toISOString(),
      bags_provided_count: data.bags_provided_count,
      ...(data.supported_school && { supported_school: data.supported_school }),
      tags: data.tags,
    };

    createActivity(body, {
      onSuccess: () => {
        resetFormHandler();
      },
    });
  };

  return (
    <ActivityContainerModal
      title={ReadableActivityType[ActivityType.HISD_SOUPER_FOOD_PARTY_EVENT]}
      submitButtonLabel="Create"
      formId={formId}
      activityType={ActivityType.HISD_SOUPER_FOOD_PARTY_EVENT}
      isLoading={isCreatingActivity}
      onClose={resetFormHandler}
    >
      <Form
        providerProps={form}
        id={formId}
        className="grid w-full grid-cols-2 gap-4"
        onSubmit={form.handleSubmit(submitActivityHandler)}
      >
        <FormField
          control={form.control}
          name="school"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>School</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select school"
                    selectedLabel={schoolsOptions.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search school..." emptyContent="School not found.">
                  {schoolsOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Activity Name</FormLabel>
              <FormControl>
                <Input {...field} isError={!!form.formState.errors.name} placeholder="Enter name here" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="date_and_time"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Date & Time</FormLabel>
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  defaultMonth={field.value}
                  selected={field.value}
                  disabled={{ before: new Date() }}
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="bags_provided_count"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Quantity of Bags Provided</FormLabel>
              <FormControl>
                <NumberInput
                  {...field}
                  type="number"
                  isError={!!fieldState.error}
                  placeholder="Enter Quantity of Bags Provided"
                  min={0}
                  changeOnWheel
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="supported_school"
          render={({ field, fieldState }) => (
            <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
              <FormLabel>Are bags provided to other school?</FormLabel>
              <div className="flex w-full flex-col gap-1">
                <ComboBox modal>
                  <FormControl>
                    <ComboBoxTrigger
                      isError={!!fieldState.error}
                      placeholder="Select school"
                      selectedLabel={supportedSchoolsOptions.find((option) => option.value === field.value)?.label}
                    />
                  </FormControl>
                  <ComboBoxContent
                    shouldFilter={false}
                    loading={isSchoolsFetching}
                    searchPlaceholder="Search school..."
                    emptyContent="School not found."
                    onSearchChange={debouncedSchoolSearch}
                  >
                    {supportedSchoolsOptions.map(({ label, value }) => (
                      <ComboBoxItem
                        key={value}
                        value={value}
                        selected={value === field.value}
                        onSelect={field.onChange}
                      >
                        {label}
                      </ComboBoxItem>
                    ))}
                  </ComboBoxContent>
                </ComboBox>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="tags"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Tags</FormLabel>
              <FormControl>
                <TagSelect
                  value={tagOptions.filter((tag) => field.value.includes(tag.id))}
                  onChange={(tags) => field.onChange(tags.map((tag) => tag.id))}
                  isError={!!fieldState.error}
                  options={tagOptions}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </Form>
    </ActivityContainerModal>
  );
};

export { HisdSouperFoodPantryEventModal };
