import { Text } from '@purple/ui';
import { DataTable } from '~/components';
import { useStudentSafsColumns } from './useStudentSafsColumns';
import type { FC } from 'react';
import type { TStudentSafItem } from '@purple/shared-types';

const SAF_DESCRIPTION_KEY = 'description';
const SAF_RESOLUTION_KEY = 'resolution';

type StudentSafsSectionProps = {
  studentSaftData: TStudentSafItem[];
  sectionTitle: string;
  sectionSubtitle: string;
  safQueryString: string;
  emptyStateTitle?: string;
  emptyStateMessage?: string;
};
export const StudentSafsSection: FC<StudentSafsSectionProps> = ({ sectionSubtitle, sectionTitle, studentSaftData, emptyStateMessage, emptyStateTitle, safQueryString }) => {
  const safTableOptions = safQueryString.split(',').reduce((acc, option) => {
    switch (option) {
      case SAF_DESCRIPTION_KEY:
        return { ...acc, hasSafDescription: true };
      case SAF_RESOLUTION_KEY:
        return { ...acc, hasSafResolution: true };
      default:
        return acc;
    }
  }, {});
  const studentSafColumns = useStudentSafsColumns(safTableOptions);
  return (
    <div className="flex flex-col">
      <Text variant="size-14" type="body-600" className="text-grey-title">{sectionTitle}</Text>
      <Text variant="size-10" type="body-400" className="text-grey-800">{sectionSubtitle}</Text>
      <DataTable
        columns={studentSafColumns}
        data={studentSaftData}
        emptyStateTitle={emptyStateTitle}
        emptyStateMessage={emptyStateMessage}
      />
    </div>
  );
};
