import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useMask } from '@react-input/mask';
import { motion } from 'framer-motion';
import { LANGUAGE_QUERY_NAME, SafSubmitterType } from '@purple/shared-types';
import { CONTAIN_DIGIT_REGEX, mergeReferences } from '@purple/shared-utils';
import { Checkbox, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@purple/ui';
import { SAF_PHONE_MASK } from '~/constants/saf';
import type { TSafFieldSettingsResponse } from '@purple/shared-types';
// types
import { useArabicLanguage } from '../hooks';
import type React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { z } from 'zod';
import type { safFormSchema } from './schema';

type TInfoFormProperties = {
  /**
   * The form object that contains the form state and methods.
   */
  form: UseFormReturn<z.infer<typeof safFormSchema>>;
  /**
   * The transition delta that determines the direction of the form transition.
   */
  transitionDelta: number;
  /**
   * The field settings data that contains the form settings.
   */
  fieldSettingsData: TSafFieldSettingsResponse;
};

export const InfoForm: React.FC<TInfoFormProperties> = (props) => {
  const { form, transitionDelta, fieldSettingsData } = props;

  const [searchParameters] = useSearchParams();
  const currentLanguage = searchParameters.get(LANGUAGE_QUERY_NAME) || 'en';

  const translatedText = useMemo(() => {
    const translatedText = {
      is_student_filling_form_for_himself: {
        text: '',
        is_required: false,
      },
      is_submitted_anonymously: {
        text: '',
        is_required: false,
      },
      submitter__first_name: {
        text: '',
        is_required: false,
      },
      submitter__last_name: {
        text: '',
        is_required: false,
      },
      submitter__email: {
        text: '',
        is_required: false,
      },
      submitter__phone: {
        text: '',
        is_required: false,
      },
      submitter_org_or_campus: {
        text: '',
        is_required: false,
      },
    };

    fieldSettingsData?.field_settings.forEach((field) => {
      Object.keys(translatedText).forEach((key) => {
        if (field.field_name === key) {
          const translatedWord = field.translations[currentLanguage as keyof typeof field.translations];
          const isRequiredField = field.is_required;
          translatedText[key as keyof typeof translatedText].text = translatedWord || '.';
          translatedText[key as keyof typeof translatedText].is_required = isRequiredField;
        }
      });
    });

    return translatedText;
  }, [fieldSettingsData, currentLanguage]);

  const inputReference = useMask({
    mask: SAF_PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });
  const { t } = useTranslation('saf');
  const { isArabic } = useArabicLanguage();

  const isForMySelf = form.watch('myself');
  const isAnonymous = form.watch('anonymous');
  const submitter = form.watch('submitter');

  return (
    <motion.div
      initial={{ x: transitionDelta >= 0 ? '50%' : '-50%', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className="grid w-full grid-cols-2 gap-x-4 gap-y-6"
    >
      <div className="col-span-2 grid w-full grid-cols-1 gap-x-4 gap-y-3">
        {submitter === SafSubmitterType.STUDENT && (
          <FormField
            control={form.control}
            name="myself"
            render={({ field }) => (
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <Checkbox
                    {...field}
                    value={field.value.toString()}
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    disabled={isAnonymous}
                  />
                </FormControl>
                <FormLabel>{translatedText.is_student_filling_form_for_himself.text}</FormLabel>
              </FormItem>
            )}
          />
        )}

        {!isForMySelf && fieldSettingsData.can_submit_anonymously && (
          <FormField
            control={form.control}
            name="anonymous"
            render={({ field }) => (
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <Checkbox
                    {...field}
                    value={field.value.toString()}
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabel>{translatedText.is_submitted_anonymously.text}</FormLabel>
              </FormItem>
            )}
          />
        )}
      </div>

      {!isAnonymous && (
        <>
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel required={
                  translatedText.submitter__first_name.is_required
                }
                >
                  {translatedText.submitter__first_name.text}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    isError={!!form.formState.errors.firstName}
                    placeholder={t('form.info.first-name.placeholder')}
                    type="text"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel required={translatedText.submitter__last_name.is_required}>{translatedText.submitter__last_name.text}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    isError={!!form.formState.errors.lastName}
                    placeholder={t('form.info.last-name.placeholder')}
                    type="text"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel required={translatedText.submitter__email.is_required}>
                  {translatedText.submitter__email.text}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    isError={!!form.formState.errors.email}
                    placeholder={
                      submitter === SafSubmitterType.STUDENT || submitter === SafSubmitterType.PARENT
                        ? t('form.info.email.placeholder-2')
                        : t('form.info.email.placeholder')
                    }
                    type="email"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel required={translatedText.submitter__phone.is_required}>
                  {translatedText.submitter__phone.text}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    ref={mergeReferences([field.ref, inputReference])}
                    isError={!!form.formState.errors.phone}
                    placeholder={SAF_PHONE_MASK}
                    type="tel"
                    dir={isArabic ? 'rtl' : 'ltr'}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {(submitter === SafSubmitterType.TEACHER || submitter === SafSubmitterType.PROVIDER) && (
            <FormField
              control={form.control}
              name="organization"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel required={translatedText.submitter_org_or_campus.is_required}>{translatedText.submitter_org_or_campus.text}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      isError={!!form.formState.errors.organization}
                      placeholder={t('form.info.organization.placeholder')}
                      type="text"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </>
      )}
    </motion.div>
  );
};
