import { format } from 'date-fns';
import { Text } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';
import type { TStudentTotalFlagsItem } from '@purple/shared-types';

export const studentTotalFlagsColumns: ColumnDef<TStudentTotalFlagsItem>[] = [
  {
    accessorKey: 'period',
    header: () => (
      <Text variant="size-10" type="body-600" className="max-w-[450px] uppercase text-grey-600">
        Period
      </Text>
    ),
    cell: ({ row }) => {
      const startDate = format(row.original.cycle_start_date, 'MM.dd.yyyy'); ;
      const endDate = format(row.original.pull_date, 'MM.dd.yyyy');
      return (
        <Text variant="size-12" type="body-500" className="text-grey-950">
          {startDate && endDate ? `${startDate} - ${endDate}` : '—'}
        </Text>
      );
    },
  },
  {
    accessorKey: 'attendance_cc',
    header: () => (
      <Text variant="size-10" type="body-600" className="uppercase text-grey-600">
        Attendance
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-12" type="body-500" className="text-grey-950">
        {row.original.attendance_cc ? `${row.original.attendance_cc} flags` : '0 flags'}
      </Text>
    ),
  },
  {
    accessorKey: 'behavior_cc',
    header: () => (
      <Text variant="size-10" type="body-600" className="uppercase text-grey-600">
        Behavior
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-12" type="body-500" className="text-grey-950">
        {row.original.behavior_cc ? `${row.original.behavior_cc} flags` : '0 flags'}
      </Text>
    ),
  },
  {
    accessorKey: 'total_flags_cc',
    header: () => (
      <Text variant="size-10" type="body-600" className="uppercase text-grey-600">
        Total Flags Number
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-12" type="body-500" className="text-grey-950">
        {row.original.total_flags_cc ? `${row.original.total_flags_cc} flags` : '0 flags'}
      </Text>
    ),
  },
  {
    accessorKey: 'color_trend',
    header: () => (
      <Text variant="size-10" type="body-600" className="uppercase text-grey-600">
        Color Trend
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-12" type="body-500" className="text-grey-950">
        {row.original.color_trend ? row.original.color_trend : '—'}
      </Text>
    ),
  },
];
