import { SCHOOL_SAFS_FILTERS } from '@purple/shared-types';

export const SEARCH_DEBOUNCE_MS = 1000;

export const SAFS_SORT_SELECT_OPTIONS = [
  {
    label: 'SAF Number (ASC)',
    value: 'saf_number',
  },
  {
    label: 'SAF Number (DESC)',
    value: '-saf_number',
  },
  {
    label: 'Date/Time Opened (ASC)',
    value: 'created_at',
  },
  {
    label: 'Date/Time Opened (DESC)',
    value: '-created_at',
  },
] as const;

export const SAFS_FILTERS_TABS = [
  {
    label: 'Priority',
    value: SCHOOL_SAFS_FILTERS.PRIORITY,
  },
  {
    label: 'Date/Time Opened',
    value: SCHOOL_SAFS_FILTERS.DATE_TIME_OPENED,
  },
  {
    label: 'Needs',
    value: SCHOOL_SAFS_FILTERS.NEEDS,
  },
] as const;
