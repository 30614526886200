import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueries } from '@tanstack/react-query';
import { districtsCheck, getStudentActionsList, getStudentDashboard, getStudentDetails, getStudentNotesPrint, getStudentSafsList, getTotalFlags, getTotalIncidents, getTotalIncidentsAttendance } from '@purple/shared-services';
import { TakeActionDocumentType } from '@purple/shared-types';
import { ACTIONS_QUERY_KEYS, DISTRICTS_QUERY_KEYS, NOTE_QUERY_KEYS, STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { TIME_PERIODS } from '~/constants/options';
import { ActionsSection } from '../ActionsSection/ActionsSection';
import { PageHeader } from '../PageHeader';
import { StudentAttendanceSection } from '../StudentAttendanceSection';
import { StudentBehaviorSection } from '../StudentBehaviorSection';
import { StudentCardSection } from '../StudentCardSection';
import { StudentDetailsSection } from '../StudentDetailsSection';
import { StudentNotesSection } from '../StudentNotesSection';
import { StudentSafsSection } from '../StudentSafsSection';
import { StudentTotalFlagsSection } from '../StudentTotalFlagsSection';
import type { TTotalIncident } from '@purple/shared-types';

const TOTAL_FLAGS_KEY = 'total_flags';
const ATTENDANCE_TRENDS_KEY = 'attendance_trends';
const BEHAVIOR_ANALYSIS_KEY = 'behavior_analysis';

export const StudentDataPrintView = () => {
  const [searchParams] = useSearchParams();

  const studentId = searchParams.get('studentId');
  const token = searchParams.get('token');
  const timePeriod = searchParams.get('time_period') ?? TIME_PERIODS.this_academic_year;

  const general_record_action_type = searchParams.get('general');
  const targeted_record_action_type = searchParams.get('targeted');
  const attempted_record_action_type = searchParams.get('attempted');

  const color_trends = searchParams.get('student_color_trends');
  const safs = searchParams.get('safs');

  const hasPrivateNotes = searchParams.get('is_private');

  const subdomain = useMemo(() => window.location.hostname.split('.')[0], []);

  const hasTotalFlags = useMemo(() => color_trends !== null && color_trends.includes(TOTAL_FLAGS_KEY), [color_trends]);
  const hasAttendanceTrends = useMemo(() => color_trends !== null && color_trends.includes(ATTENDANCE_TRENDS_KEY), [color_trends]);
  const hasBehaviorAnalysis = useMemo(() => color_trends !== null && color_trends.includes(BEHAVIOR_ANALYSIS_KEY), [color_trends]);

  const studentDetailsQueryParameters = useMemo(() => ({
    limit: 1,
    offset: 0,
    token,
  }), [token]);

  const generalActionQueryParameters = useMemo(() => ({
    limit: 999999999999999,
    offset: 0,
    token,
    document_as: TakeActionDocumentType.GENERAL,
    record_action_type: general_record_action_type,
    time_period: timePeriod,
  }), [general_record_action_type, timePeriod, token]);

  const attemptedActionQueryParameters = useMemo(() => ({
    limit: 999999999999999,
    offset: 0,
    token,
    document_as: TakeActionDocumentType.ATTEMPTED,
    record_action_type: attempted_record_action_type,
    time_period: timePeriod,
  }), [attempted_record_action_type, timePeriod, token]);

  const targetedActionQueryParameters = useMemo(() => ({
    limit: 999999999999999,
    offset: 0,
    token,
    document_as: TakeActionDocumentType.TARGETED,
    record_action_type: targeted_record_action_type,
    time_period: timePeriod,
  }), [targeted_record_action_type, timePeriod, token]);

  const safQueryParameters = useMemo(() => ({
    limit: 999999999999999,
    offset: 0,
    token,
    time_period: timePeriod,
  }), [timePeriod, token]);

  const studentNotesQueryParameters = useMemo(() => ({
    student_id: studentId!,
    limit: 999999999999999,
    offset: 0,
    token,
    is_private: hasPrivateNotes,
    time_period: timePeriod,
  }), [hasPrivateNotes, token, studentId, timePeriod]);

  const totalFlagsParameters = useMemo(() => ({
    studentId: studentId!,
    token,
    time_period: timePeriod,
  }), [studentId, timePeriod, token]);

  const incidentsParameters = useMemo(() => ({
    studentId: studentId!,
    token,
  }), [studentId, token]);

  const incidentsAttendanceParameters = useMemo(() => ({
    studentId: studentId!,
    token,
  }), [studentId, token]);

  const { districtData, studentDashboard, studentDetails, generalStudentActions, attemptedStudentActions, targetedStudentActions, isAllRequestSuccess, isAnyRequestError, isLoading, studentSafsData, studentNotesData, studentActionsData, attendanceTrendsData, behaviorAnalysisData, totalFlagsData } = useQueries({
    queries: [
      {
        queryKey: [STUDENTS_QUERY_KEYS.GET_DASHBOARD, studentId],
        queryFn: () => getStudentDashboard(studentId!, token!),
        enabled: !!studentId && !!token,
      },
      {
        queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_DETAILS, studentId],
        queryFn: () => getStudentDetails(studentId!, token!),
        enabled: !!studentId && !!token,
      },
      {
        queryKey: [DISTRICTS_QUERY_KEYS.CHECK],
        queryFn: () => districtsCheck(subdomain!),
        enabled: !!subdomain,
      },
      {
        queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_SCHOOL_LIST, { studentId, generalActionQueryParameters }],
        queryFn: () => getStudentActionsList({ studentId: studentId!, queryParameters: generalActionQueryParameters }),
        enabled: !!studentId && general_record_action_type !== null && general_record_action_type.length > 0 && !!token,
      },
      {
        queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_SCHOOL_LIST, { studentId, attemptedActionQueryParameters }],
        queryFn: () => getStudentActionsList({ studentId: studentId!, queryParameters: attemptedActionQueryParameters }),
        enabled: !!studentId && attempted_record_action_type !== null && attempted_record_action_type.length > 0 && !!token,
      },
      {
        queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_SCHOOL_LIST, { studentId, targetedActionQueryParameters }],
        queryFn: () => getStudentActionsList({ studentId: studentId!, queryParameters: targetedActionQueryParameters }),
        enabled: !!studentId && targeted_record_action_type !== null && targeted_record_action_type.length > 0 && !!token,
      },
      {
        queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_SAFS_LIST, { studentId, safQueryParameters }],
        queryFn: () => getStudentSafsList({ studentId: studentId!, queryParameters: safQueryParameters }),
        enabled: !!studentId && safs !== null && safs.length > 0 && !!token,
      },
      {
        queryKey: [NOTE_QUERY_KEYS.GET_STUDENT_NOTES_PRINT, { studentNotesQueryParameters }],
        queryFn: () => getStudentNotesPrint(studentNotesQueryParameters),
        enabled: !!token,
      },
      {
        queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_SCHOOL_LIST, { studentId, studentDetailsQueryParameters }],
        queryFn: () => getStudentActionsList({ studentId: studentId!, queryParameters: studentDetailsQueryParameters }),
        enabled: !!studentId && !!token,
      },
      {
        queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_TOTAL_FLAGS_TABLE_DATA, totalFlagsParameters],
        queryFn: () => getTotalFlags(totalFlagsParameters),
        enabled: hasTotalFlags && !!studentId && !!token,
      },
      {
        queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_TOTAL_INCIDENTS_TABLE_DATA, incidentsParameters],
        queryFn: () => getTotalIncidents(incidentsParameters),
        enabled: hasBehaviorAnalysis && !!studentId && !!token,
        select: (data: TTotalIncident) => Object.entries(data).map(([key, value]) => ({
          month: key,
          current: value.current_year,
          previous: value.previous_year,
        })),
      },
      {
        queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_TOTAL_INCIDENTS_ATTENDANCE_TABLE_DATA, incidentsAttendanceParameters],
        queryFn: () => getTotalIncidentsAttendance(incidentsAttendanceParameters),
        enabled: hasAttendanceTrends && !!studentId && !!token,
        select: (data: TTotalIncident) => Object.entries(data).map(([key, value]) => ({
          month: key,
          current: value.current_year,
          previous: value.previous_year,
        })),
      },
    ],
    combine: (results) => {
      return {
        studentDashboard: results[0].data,
        studentDetails: results[1].data,
        districtData: results[2].data,
        generalStudentActions: results[3].data,
        attemptedStudentActions: results[4].data,
        targetedStudentActions: results[5].data,
        studentSafsData: results[6].data,
        studentNotesData: results[7].data,
        studentActionsData: results[8].data,
        totalFlagsData: results[9].data,
        behaviorAnalysisData: results[10].data,
        attendanceTrendsData: results[11].data,
        isLoading: results.some((result) => result.isLoading),
        isAllRequestSuccess: results.every((result) => result.isSuccess),
        isAnyRequestError: results.some((result) => result.isError),
      };
    },
  });

  if (isAnyRequestError || isLoading) return null;

  return (
    <div className="mx-auto w-full max-w-[1122px] bg-white px-4 pt-4" id={isAllRequestSuccess ? 'download-ready' : ''}>
      {districtData && <PageHeader districtInfo={districtData} />}

      <div className="pt-4">
        {studentDetails && <StudentDetailsSection studentDetailsData={studentDetails} />}
        {studentDashboard && studentActionsData && <StudentCardSection total_safs={studentDashboard.insight.total_safs} totals={studentActionsData.totals} />}
      </div>
      <div className="flex flex-col gap-4">

        {studentNotesData && (
          <StudentNotesSection studentNotesData={studentNotesData.results} />
        )}
        {hasTotalFlags && totalFlagsData && (
          <StudentTotalFlagsSection totalFlagsData={totalFlagsData.results} />
        )}
        {hasAttendanceTrends && attendanceTrendsData && (
          <StudentAttendanceSection attendanceData={attendanceTrendsData} />
        )}
        {hasBehaviorAnalysis && behaviorAnalysisData && (
          <StudentBehaviorSection behaviorData={behaviorAnalysisData} />
        )}
        {safs && studentSafsData && (
          <StudentSafsSection
            studentSaftData={studentSafsData.results}
            safQueryString={safs}
            sectionTitle="SAFs"
            sectionSubtitle={`Total ${studentSafsData.count} records`}
            emptyStateTitle="No SAFs found"
            emptyStateMessage="There are no SAFs to display. If it an error, please contact support."
          />
        )}

        {general_record_action_type && generalStudentActions && (
          <ActionsSection
            generalActionsData={generalStudentActions.results}
            sectionTitle="Actions (General)"
            sectionSubtitle={`Total ${generalStudentActions.count} records`}
            emptyStateTitle="No General Actions found"
            emptyStateMessage="There are no General Actions to display. If it an error, please contact support."
          />
        )}
        {targeted_record_action_type && targetedStudentActions && (
          <ActionsSection
            generalActionsData={targetedStudentActions.results}
            sectionTitle="Actions (Targeted)"
            sectionSubtitle={`Total ${targetedStudentActions.count} records`}
            emptyStateTitle="No Targeted Actions found"
            emptyStateMessage="There are no Targeted Actions to display. If it an error, please contact support."
          />
        )}
        {attempted_record_action_type && attemptedStudentActions && (
          <ActionsSection
            generalActionsData={attemptedStudentActions.results}
            sectionTitle="Actions (Attempted)"
            sectionSubtitle={`Total ${attemptedStudentActions.count} records`}
            emptyStateTitle="No Attempted Actions found"
            emptyStateMessage="There are no Attempted Actions to display. If it an error, please contact support."
          />
        )}

      </div>
    </div>
  );
};
