const CustomizedGroupTick = (props: any) => {
  const { x, y, payload, fontSize = 12 } = props;

  const splitDateRange = (dateRange: string) => {
    const dates = dateRange.split(' - ');
    return [dates[0], dates[1]];
  };

  const [startDateFormatted, endDateFormatted] = splitDateRange(payload.value as string);

  return (
    <g>
      <text
        x={x}
        y={y}
        fill="#4E617B"
        fontSize={fontSize}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        <tspan x={x} dy="0.5em">
          {startDateFormatted}
        </tspan>
        <tspan x={x} dy="1.2em">
          {endDateFormatted}
        </tspan>
      </text>
    </g>
  );
};

export { CustomizedGroupTick };
