import { useMemo } from 'react';
import { getRandomColor, snakeCaseToCapitalized } from '@purple/shared-utils';
import { EMPTY_CHART_DATA } from '@purple/ui';
import { CHART_COLORS } from '~/modules/Home/Tabs/Dashboard/constants';
import { BarChartCard } from '../BarChartCard';
import { PieChartCard } from '../PieChartCard';
import type { FC } from 'react';
import type { TCrisisActivityListResponse } from '@purple/shared-types';
import type { TChartConfig } from '@purple/ui';

type TCrisisContentStatisticSectionProperties = {
  data?: TCrisisActivityListResponse;
  isLoading?: boolean;
};

export const CrisisContentStatisticSection: FC<TCrisisContentStatisticSectionProperties> = ({ data, isLoading }) => {
  const { crisis_by_months, crisis_by_region, crisis_by_type } = data || {};

  const crisisByRegionConfig: TChartConfig = useMemo(
    () =>
      crisis_by_region
        ? Object.keys(crisis_by_region).reduce(
            (accumulator, name, index) => ({
              ...accumulator,
              [name]: {
                label: name,
                color: CHART_COLORS[index] ?? getRandomColor(),
              },
            }),
            {
              value: {
                label: 'Region',
              },
            },
          )
        : {},
    [crisis_by_region],
  );

  const crisisByTypeConfig: TChartConfig = useMemo(
    () =>
      crisis_by_type
        ? Object.keys(crisis_by_type).reduce(
            (accumulator, name, index) => ({
              ...accumulator,
              [name]: {
                label: name,
                color: CHART_COLORS[index] ?? getRandomColor(),
              },
            }),
            {
              value: {
                label: 'Type',
              },
            },
          )
        : {},
    [crisis_by_type],
  );

  const crisisByMonthsConfig: TChartConfig = useMemo(
    () =>
      crisis_by_months
        ? Object.keys(crisis_by_months).reduce(
            (accumulator, name) => ({
              ...accumulator,
              [name]: {
                label: name,
                color: '#5369BD',
              },
            }),
            {
              value: {
                label: 'Month',
              },
            },
          )
        : {},
    [crisis_by_months],
  );

  const crisisByRegionData = useMemo(
    () =>
      crisis_by_region
        ? Object.entries(crisis_by_region).map(([name, value]) => ({
            name: snakeCaseToCapitalized(name),
            value: Number(value?.toFixed(0)) || 0,
            fill: crisisByRegionConfig[name]!.color!,
            color: crisisByRegionConfig[name]!.color!,
          }))
        : [],
    [crisis_by_region, crisisByRegionConfig],
  );

  const crisisByTypeData = useMemo(
    () =>
      crisis_by_type
        ? Object.entries(crisis_by_type).map(([name, value]) => ({
            name: snakeCaseToCapitalized(name),
            value: Number(value?.toFixed(0)) || 0,
            fill: crisisByTypeConfig[name]!.color!,
            color: crisisByTypeConfig[name]!.color!,
          }))
        : [],
    [crisis_by_type, crisisByTypeConfig],
  );

  const crisisByMonthsData = useMemo(
    () =>
      crisis_by_months
        ? Object.entries(crisis_by_months).map(([name, value]) => ({
            month: snakeCaseToCapitalized(name),
            value: Number(value?.toFixed(0)) || 0,
            fill: '#5369BD',
            color: '#5369BD',
          }))
        : [],
    [crisis_by_months],
  );

  const totalCrisisByRegion = useMemo(
    () => crisisByRegionData.reduce((accumulator, item) => accumulator + item.value, 0),
    [crisisByRegionData],
  );
  const totalCrisisByType = useMemo(
    () => crisisByTypeData.reduce((accumulator, item) => accumulator + item.value, 0),
    [crisisByTypeData],
  );

  const hasNoTotalCrisisByRegion = useMemo(() => totalCrisisByRegion === 0, [totalCrisisByRegion]);
  const hasNoTotalCrisisByType = useMemo(() => totalCrisisByType === 0, [totalCrisisByType]);

  return (
    <section className="grid grid-cols-1 gap-4 lg:grid-cols-2">
      <PieChartCard
        cardTitle=" Crisis by Type"
        chartConfig={crisisByRegionConfig}
        chartData={hasNoTotalCrisisByType ? EMPTY_CHART_DATA : crisisByTypeData}
        totalValue={totalCrisisByType}
        isLoading={isLoading}
        onDownload={() => {}}
      />
      <PieChartCard
        cardTitle="Crisis by Region"
        chartConfig={crisisByTypeConfig}
        chartData={hasNoTotalCrisisByRegion ? EMPTY_CHART_DATA : crisisByRegionData}
        totalValue={totalCrisisByRegion}
        isLoading={isLoading}
        onDownload={() => {}}
      />

      <BarChartCard
        cardTitle="Total Crisis"
        chartConfig={crisisByMonthsConfig}
        chartData={crisisByMonthsData}
        isLoading={isLoading}
        onDownload={() => {}}
        className="lg:col-span-2"
      />
    </section>
  );
};
