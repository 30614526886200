import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { LANGUAGE_QUERY_NAME } from '@purple/shared-types';
import { getInitialsFromName } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
import { SAF_LANGUAGES } from '~/constants/saf';
import type React from 'react';

type TFormPageHeaderProperties = {
  /**
   * The source of the school district logo image.
   */
  imageSrc?: string | null;
  /**
   * The name of the school district.
   */
  districtName?: string;
};

export const FormPageHeader: React.FC<TFormPageHeaderProperties> = (props) => {
  const { imageSrc, districtName = 'Unknown School District' } = props;

  const [_searchParameters, setSearchParameters] = useSearchParams();

  const { i18n } = useTranslation();

  const languageChangeHandler = (value: string) => {
    i18n.changeLanguage(value);
    document.body.dir = value === 'ar' ? 'rtl' : 'ltr';

    // Update the language query parameter in the URL.
    setSearchParameters(
      (previous) => {
        const newSearchParameters = new URLSearchParams(previous);
        newSearchParameters.set(LANGUAGE_QUERY_NAME, value);
        return newSearchParameters;
      },
      {
        replace: true,
      },
    );
  };

  return (
    <header className="flex min-h-[68px] w-full items-center justify-between border border-b-grey-300 bg-white px-8 py-4">
      <div className="flex items-center gap-2">
        <Avatar size={32}>
          <AvatarImage src={imageSrc ?? undefined} alt={districtName} />
          <AvatarFallback>{getInitialsFromName(districtName)}</AvatarFallback>
        </Avatar>
        <strong className="font-primary text-sm font-medium leading-5 text-blue-700">{districtName}</strong>
      </div>
      <RadixSelect defaultValue={i18n.language} onValueChange={languageChangeHandler}>
        <RadixSelectTrigger className="w-40">
          <RadixSelectValue placeholder="Select language" />
        </RadixSelectTrigger>
        <RadixSelectContent>
          {SAF_LANGUAGES.map((language) => (
            <RadixSelectItem key={language.value} value={language.value}>
              {language.label}
            </RadixSelectItem>
          ))}
        </RadixSelectContent>
      </RadixSelect>
    </header>
  );
};
