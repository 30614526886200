import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { PurpleIcon } from '@purple/icons';
import { LANGUAGE_QUERY_NAME, type TSafTextTranslationsView } from '@purple/shared-types';
import { Button, cn, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, Heading } from '@purple/ui';
import { SafLinkExtention } from '~/components';
import { SafReturnType, StudentAssistanceScreen } from '~/constants/saf';
import { useStudentAssistance } from '~/hooks/redux';
import { useArabicLanguage } from '../hooks';

type TSuccessCardProperties = {
  /**
   * The data object that contains the translations.
   */
  data: TSafTextTranslationsView | undefined;
};

export const SuccessCard = ({ data }: TSuccessCardProperties) => {
  const { t } = useTranslation('saf');

  const [searchParameters] = useSearchParams();
  const currentLanguage = searchParameters.get(LANGUAGE_QUERY_NAME) || 'en';

  const introductionText = useMemo(() => {
    return (data?.introduction_text as Record<string, string>)?.[currentLanguage] || '';
  }, [data, currentLanguage]);

  const { setSafCurrentScreen, setSafReturnType } = useStudentAssistance();
  const { isArabic } = useArabicLanguage();

  const currentStudentSelectHandler = () => {
    setSafReturnType(SafReturnType.CURRENT_STUDENT);
    setSafCurrentScreen(StudentAssistanceScreen.FORM);
  };

  const newStudentSelectHandler = () => {
    setSafReturnType(SafReturnType.NEW_STUDENT);
    setSafCurrentScreen(StudentAssistanceScreen.FORM);
  };

  const introductionEditorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      SafLinkExtention.configure({
        autolink: true,
        defaultProtocol: 'https',
      }),
      Placeholder.configure({
        placeholder: () => 'Enter your disclaimer text here...',
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: introductionText,
    editable: false,
  });

  useEffect(() => {
    introductionEditorConfig?.commands.setContent(introductionText);
  }, [introductionText, introductionEditorConfig]);

  return (
    <section className="mt-[152px] flex w-full max-w-[840px] flex-col items-center gap-12 rounded-2xl border border-grey-200 bg-white p-12">
      <div className="flex w-full flex-col items-center gap-6">
        <div className="flex size-16 items-center justify-center rounded-xl bg-success-bg">
          <PurpleIcon name="check-circle" className="size-8 text-success-main" />
        </div>
        <div className="flex w-full flex-col items-center gap-2">
          <Heading tag="h2" type="heading-600" variant="size-22" className="text-grey-title">
            {t('success.title')}
          </Heading>
          <EditorContent
            editor={introductionEditorConfig}
            disabled
            className="minimal-tiptap-editor flex size-full cursor-text flex-col p-0 font-primary text-sm font-medium text-grey-600"
          />
        </div>
      </div>
      <DropdownRoot>
        <DropdownTrigger asChild>
          <Button
            type="button"
            iconRight={<PurpleIcon name="chevron-down" className="size-5 text-white" />}
            dir={isArabic ? 'rtl' : 'ltr'}
          >
            {t('success.trigger')}
          </Button>
        </DropdownTrigger>
        <DropdownContent useTriggerWidth>
          <DropdownItem
            iconName="user"
            onSelect={currentStudentSelectHandler}
            dir={isArabic ? 'rtl' : 'ltr'}
            className={cn({ '[&>svg]:ml-2 [&>svg]:mr-0': isArabic })}
          >
            {t('success.option-1')}
          </DropdownItem>
          <DropdownItem
            iconName="user-add"
            onSelect={newStudentSelectHandler}
            dir={isArabic ? 'rtl' : 'ltr'}
            className={cn({ '[&>svg]:ml-2 [&>svg]:mr-0': isArabic })}
          >
            {t('success.option-2')}
          </DropdownItem>
        </DropdownContent>
      </DropdownRoot>
    </section>
  );
};
