import { z } from 'zod';
import { zodAlwaysRefine } from '@purple/shared-utils';

export const safFormSchema = zodAlwaysRefine(
  z.object({
    needs: z
      .string({
        invalid_type_error: 'Need ID must be a string.',
        required_error: 'Need is required.',
      })
      .min(1, {
        message: 'Need is required.',
      })
      .trim(),
    details: z
      .string({
        invalid_type_error: 'Description must be a string.',
      })
      .trim()
      .min(1, {
        message: 'Description are required.',
      })
      .max(10000, {
        message: 'Description must be less than 10000 characters.',
      }),
  }),
);
