import { TakeActionType } from './actions';
import type { ValueOf } from '../utils';
import type { TBanner } from './banners';
import type { IBasePaginationRequestParameters, IPaginatedResponse } from './general';
import type { TSchoolAddress } from './schools';

export type TUserBanner = Pick<
  TBanner,
  'id'
  | 'body'
  | 'title'
  | 'type'
  | 'end_date_and_time'
  | 'is_view_more'
  | 'start_date_and_time'
  | 'view_more_link'
>;

export type IUser = {
  id: string;
  email: string;
  username: string | null;
  full_name: string | null;
  avatar: string | null;
  ecosystem_default_view: 'list' | 'day' | 'week' | 'month';
  last_login: null;
  date_joined: string;
  schools: TUserSchool[];
  // TODO: use Role enum instead of string when it will be done
  role: string;
  prounitas_roles: string[];
  notifications: TUserBanner[];
  title?: string | null;
  impersonator_id: string | null;
};

export type TUserSchool = {
  id: string;
  name: string;
};

export const UserSearchActionChoice = {
  YOUR_CONSULTANTS: 'your_consultants',
  PURPLE_USER_PARTICIPANTS: 'purple_user_participants',
} as const;
export type TUserSearchActionChoice = ValueOf<typeof UserSearchActionChoice>;

export type TUserSearchRequestParameters = {
  search?: string;
  action_choices?: TUserSearchActionChoice;
  school_access?: string;
  with_self?: boolean;
  status?: string;
};

export type TUserOwner = Pick<IUser, 'id' | 'avatar' | 'email' | 'full_name' | 'prounitas_roles'>;

export type TUserSearchResponse = IPaginatedResponse<TUserOwner[]>;

export type IUserEventsRequestParameters = {
  type?: string;
  is_owner?: string;
  is_reminders?: string;
  is_weekends?: string;
  /**
   * Date from which to start searching for events
   */
  start_time__gte?: string;
  /**
   * Date until which to search for events
   */
  end_time__lte?: string;
} & IBasePaginationRequestParameters;

export const ECO_SYSTEM_EVENT_TYPES = {
  ACTION: 'action',
  GROUP_ACTION: 'group_action',
  COMMUNITY_ACTIVITY: 'community_activity',
  ONE_TIME_SESSION_MEETING: 'one_time_session_meeting',
  RECURRING_SESSION_MEETING: 'recurring_session_meeting',
  ...TakeActionType,
} as const;

export type TEcoSystemActionsTypes = ValueOf<typeof ECO_SYSTEM_EVENT_TYPES>;

export const ECO_SYSTEM_EVENT_SUBTYPES = {
  ONE_TIME: 'one_time',
  RECURRING: 'recurring',
  GROUP_ACTION: 'action_group',
} as const;

export type TEcoSystemEventSubtypes = ValueOf<typeof ECO_SYSTEM_EVENT_SUBTYPES>;

export type IUserEventsPaginatedResponse<T> = {
  owned_by_user: number;
} & IPaginatedResponse<T>;

export type IUserEvent = {
  id: string;
  related_object_id: string;
  title: string;
  type: TEcoSystemActionsTypes | null;
  subtype: TEcoSystemEventSubtypes | null;
  owner: string;
  description: string;
  start_time: string;
  end_time: string | null;
};

export type TUserAddress = TSchoolAddress;

export type TUserChangeEmail = { expired_at: string; new_email: string };

export type TUserProfileDTO = {
  id: string;
  email: string;
  username: string | null;
  full_name: string;
  avatar: string | null;
  ecosystem_default_view: 'list' | 'day' | 'week' | 'month';
  schools: TUserSchool[];
  prounitas_roles: [];
  title: string;
  company_name: string;
  change_email_request: TUserChangeEmail | null;
  department: string;
  phone_number: string;
  language: string;
  timezone: string;
  address: TUserAddress | null;
};

export type TUserProfileUpdateDTO = Partial<TUserProfileDTO> & {
  address?: Omit<TUserAddress, 'id'> | null;
};

export type TUserProfileBasic = {
  username: string | null;
  full_name: string;
  avatar: string | null;
  title: string;
};

export type TUserAccountDetails = {
  title: string;
  email: string;
  phone_number: string;
  company_name?: string;
  department?: string;
  change_email_request?: TUserChangeEmail | null;
};

export type TUserSchoolAccess = {
  schools: TUserSchool[];
};

export type TLocationDetails = {
  language: string;
  timezone: string;
  address: TUserAddress | null;
};

export type TUserProfile = {
  id: string;
  basic_details: TUserProfileBasic;
  account_details: TUserAccountDetails;
  school_access: TUserSchoolAccess;
  location_details: TLocationDetails;
};

export type TUpdateUserAccountDetails = Partial<TUserAccountDetails>;

export type TUserProfileMutationError = {
  avatar?: string[];
};

export type TUpdateUserLocationDetails = Partial<TLocationDetails>;

export type TUserFilesListRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string;
};

export type TUserFile = {
  id: string;
  title: string;
  file: string;
  size: number;
  updated_at: string;
};

export type TUserChangePasswordRequestData = {
  old_password: string;
  new_password: string;
};

export type TUserChangePasswordMutationError = {
  old_password?: string[];
  new_password?: string[];
};

export type TUserChangePhotoRequestData = {
  avatar: File | null;
};

export type TUserChangeEmailRequestData = {
  new_email: string;
};
