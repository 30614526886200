import { useMutation } from '@tanstack/react-query';
import { markBannerAsRead } from '@purple/shared-services';
import { USER_QUERY_KEYS } from '@purple/shared-utils';
import { showSuccessToast } from '~/shared/lib';

export const useMarkBannerAsRead = () => {
  return useMutation(
    {
      mutationKey: [USER_QUERY_KEYS.MARK_BANNER_AS_READ],
      mutationFn: markBannerAsRead,
      onSuccess: () => {
        showSuccessToast('System message', 'Banner removed and marked as read');
      },
    },
  );
};
