import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { MonthView, YearStudentCalendarLegend, YearStudentNavigation } from './components';
import type { TStudentCalendarItemType } from '@purple/shared-types';
import type { YearEvent } from './types';

type YearCalendarProperties = {
  year: number;
  events: YearEvent[];
  calendarSwitcher: React.ReactNode;
  selectedEventType: TStudentCalendarItemType;
  onNavigate: (date: Date) => void;
  currentDate: Date;
};

export const YearStudentCalendar: React.FC<YearCalendarProperties> = ({
  year,
  events,
  calendarSwitcher,
  selectedEventType,
  onNavigate,
  currentDate,
}) => {
  const months = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) => new Date(year, i, 1));
  }, [year]);

  const eventsByDay = useMemo(() => {
    const dayEventMap: Record<string, YearEvent[]> = {};
    events.forEach((event) => {
      const dayKey = format(event.date, 'yyyy-MM-dd');
      if (!dayEventMap[dayKey]) {
        dayEventMap[dayKey] = [];
      }
      dayEventMap[dayKey].push(event);
    });
    return dayEventMap;
  }, [events]);

  return (
    <div className="flex flex-col gap-4 rounded-lg border border-grey-200 p-4">
      <div className="flex items-center justify-between">
        {calendarSwitcher}
        <YearStudentNavigation currentDate={currentDate} onNavigate={onNavigate} />
      </div>
      <YearStudentCalendarLegend selectedEventType={selectedEventType} />
      <div className="grid grid-cols-3 gap-6">
        {months.map((month) => (
          <div key={month.toString()} className="max-w-[224px]">
            <MonthView month={month} eventsByDay={eventsByDay} selectedEventType={selectedEventType} />
          </div>
        ))}
      </div>
    </div>
  );
};
