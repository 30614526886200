import { useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DonutChartCard, Heading } from '@purple/ui';
import DownloadableWrapper from '~/components/DownloadableWrapper/DownloadableWrapper';
import { AppRoutes } from '~/constants/routes/routes';
import { showErrorToast } from '~/shared/lib';
import type { DownloadableWrapperReference } from '~/components/DownloadableWrapper/DownloadableWrapper';
import type { IStudentDashboardData } from '~/queries/student-dashboard/types';

type CircleOfSupportProperties = {
  data: IStudentDashboardData | null;
};

const CircleOfSupport = ({ data }: CircleOfSupportProperties) => {
  const supportByTypeRef = useRef<DownloadableWrapperReference>(null);
  const supportByProgramRef = useRef<DownloadableWrapperReference>(null);
  const supportByRoleRef = useRef<DownloadableWrapperReference>(null);
  const supportByNeedRef = useRef<DownloadableWrapperReference>(null);

  const { supportByType, supportByProgram, supportByRole, supportByNeed } = data || {};
  const { studentId } = useParams();
  const navigate = useNavigate();

  const downloadSupportByTypeChartHandler = useCallback(() => {
    supportByTypeRef.current?.downloadImage({
      scale: 2,
      quality: 1,
      format: 'png',
    });
  }, [supportByTypeRef]);

  const downloadSupportByProgramChartHandler = useCallback(() => {
    supportByProgramRef.current?.downloadImage({
      scale: 2,
      quality: 1,
      format: 'png',
    });
  }, [supportByProgramRef]);

  const downloadSupportByRoleChartHandler = useCallback(() => {
    supportByRoleRef.current?.downloadImage({
      scale: 2,
      quality: 1,
      format: 'png',
    });
  }, [supportByRoleRef]);

  const downloadSupportByNeedChartHandler = useCallback(() => {
    supportByNeedRef.current?.downloadImage({
      scale: 2,
      quality: 1,
      format: 'png',
    });
  }, [supportByNeedRef]);

  const navigateSupportByRoleHandler = () => {
    if (!studentId) {
      return showErrorToast('System message', 'Student ID is missing');
    }
    navigate(AppRoutes.App.Students.StudentDetails.SupportByRole.makePath(studentId));
  };

  const navigateSupportByTypeHandler = () => {
    if (!studentId) {
      return showErrorToast('System message', 'Student ID is missing');
    }
    navigate(AppRoutes.App.Students.StudentDetails.SupportByType.makePath(studentId));
  };

  const navigateSupportByProgramHandler = () => {
    if (!studentId) {
      return showErrorToast('System message', 'Student ID is missing');
    }
    navigate(AppRoutes.App.Students.StudentDetails.SupportByProgram.makePath(studentId));
  };

  const navigateSupportByNeedsHandler = () => {
    if (!studentId) {
      return showErrorToast('System message', 'Student ID is missing');
    }
    navigate(AppRoutes.App.Students.StudentDetails.SupportByNeeds.makePath(studentId));
  };

  return (
    <>
      <Heading type="heading-600" variant="size-18" className="text-grey-title">
        Circle of Support
      </Heading>
      {supportByType && supportByNeed && supportByProgram && supportByRole && (
        <div className="mt-2 grid grid-cols-2 gap-4">
          <DownloadableWrapper ref={supportByTypeRef} fileName={`Support By Type for ${studentId}`}>
            <DonutChartCard
              onClickDownload={downloadSupportByTypeChartHandler}
              onClickViewReport={navigateSupportByTypeHandler}
              data={supportByType}
              title="Support by Type"
              actionTitle="View Report"
            />
          </DownloadableWrapper>
          <DownloadableWrapper ref={supportByProgramRef} fileName={`Support by Program for ${studentId}`}>
            <DonutChartCard
              onClickDownload={downloadSupportByProgramChartHandler}
              onClickViewReport={navigateSupportByProgramHandler}
              data={supportByProgram}
              title="Support by Program"
              actionTitle="View Report"
            />
          </DownloadableWrapper>
          <DownloadableWrapper ref={supportByRoleRef} fileName={`Support by Role for ${studentId}`}>
            <DonutChartCard
              onClickDownload={downloadSupportByRoleChartHandler}
              onClickViewReport={navigateSupportByRoleHandler}
              data={supportByRole}
              title="Support by Role"
              actionTitle="View Report"
            />
          </DownloadableWrapper>
          <DownloadableWrapper ref={supportByNeedRef} fileName={`Support by Need for ${studentId}`}>
            <DonutChartCard
              onClickDownload={downloadSupportByNeedChartHandler}
              onClickViewReport={navigateSupportByNeedsHandler}
              data={supportByNeed}
              title="Support by Need"
              actionTitle="View Report"
            />
          </DownloadableWrapper>
        </div>
      )}
    </>
  );
};

export default CircleOfSupport;
