import { Text } from '@purple/ui';
import { DataTable } from '~/components';
import { actionsColumns } from './actionsColumns';
import type { FC } from 'react';
import type { TStudentActionListItem } from '@purple/shared-types';

type TGeneralActionsSectionProps = {
  generalActionsData: TStudentActionListItem[];
  sectionTitle: string;
  sectionSubtitle: string;
  emptyStateTitle?: string;
  emptyStateMessage?: string;
};

export const ActionsSection: FC<TGeneralActionsSectionProps> = ({
  generalActionsData,
  sectionTitle,
  sectionSubtitle,
  emptyStateTitle = 'No Actions found',
  emptyStateMessage = 'There are no Actions to display. If it an error, please contact support.',
}) => {
  return (
    <div className="flex flex-col">
      <Text variant="size-14" type="body-600" className="text-grey-title">{sectionTitle}</Text>
      <Text variant="size-10" type="body-400" className="text-grey-800">{sectionSubtitle}</Text>
      <DataTable
        columns={actionsColumns}
        data={generalActionsData}
        emptyStateTitle={emptyStateTitle}
        emptyStateMessage={emptyStateMessage}
      />
    </div>
  );
};
