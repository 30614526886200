import type { TAppRoutes } from '~/constants/routes';

export const AppRoutes: TAppRoutes = {
  Auth: {
    SignIn: {
      Root: {
        path: '/auth/sign-in',
        name: 'Sign In',
        key: 'sign-in',
        makePath: (query) => (query ? `/auth/sign-in?state=${query}` : '/auth/sign-in'),
      },
    },
    CreateAccount: {
      Root: {
        path: '/auth/create-account',
        name: 'Create Account',
        key: 'create-account',
        makePath: () => '/auth/create-account',
      },
    },
    ResetPassword: {
      Root: {
        path: '/auth/reset-password',
        name: 'Reset Password',
        key: 'reset-password',
        makePath: () => '/auth/reset-password',
      },
    },
    NewPassword: {
      Root: {
        path: '/auth/reset/:userId/:token',
        name: 'New Password',
        key: 'new-password',
        makePath: () => '/auth/reset/:userId/:token',
      },
    },
    SsoComplete: {
      Root: {
        path: '/auth/sso/complete',
        name: 'SSO Complete',
        key: 'sso-complete',
        makePath: () => '/auth/sso/complete',
      },
    },
  },
  App: {
    Home: {
      Root: {
        path: '/',
        name: 'Home',
        key: 'home',
        makePath: (tabName) => `/?${new URLSearchParams({ ...(tabName && { tab: tabName.toString() }) }).toString()}`,
      },
    },
    NewStudentsReport: {
      Root: {
        path: '/new-students-report',
        name: 'New Students Report',
        key: 'new-students-report',
        makePath: (defaultTimePeriod) =>
          `/new-students-report?${new URLSearchParams({
            ...(defaultTimePeriod && { time_period: defaultTimePeriod.toString() }),
          }).toString()}`,
      },
    },
    GradeReport: {
      Root: {
        path: '/grade-report',
        name: 'Grade Report',
        key: 'grade-report',
        makePath: (defaultTimePeriod, grade) =>
          `/grade-report?${new URLSearchParams({
            ...(defaultTimePeriod && { time_period: defaultTimePeriod.toString() }),
            ...(grade && { grade: grade.toString() }),
          }).toString()}`,
      },
    },
    SafSubmittersReport: {
      Root: {
        path: '/saf-submitters-report',
        name: 'Saf Submitters Report',
        key: 'saf-submitters-report',
        makePath: () =>
          '/saf-submitters-report',
      },
    },
    Visualizer: {
      Root: {
        path: '/visualizer',
        name: 'Visualizer',
        key: 'visualizer',
        makePath: (tabName, view, priorityListId, color) =>
          `/visualizer/?${new URLSearchParams({
            ...(tabName && { tab: tabName.toString() }),
            ...(view && { view: view.toString() }),
            ...(priorityListId && { list_id: priorityListId.toString() }),
            ...(color && { current_color: color.toString() }),
          }).toString()}`,
      },
    },
    School: {
      Root: {
        path: '/school/:schoolId',
        name: 'School',
        key: 'school',
        makePath: (schoolId, tabName, viewName) =>
          `/school/${schoolId}/?${new URLSearchParams({
            ...(tabName && { tab: tabName.toString() }),
            ...(viewName && { view: viewName.toString() }),
          }).toString()}`,
      },
    },
    Students: {
      Root: {
        path: '/students',
        name: 'Students',
        key: 'students-list',
        makePath: () => '/students',
      },
      StudentDetails: {
        Root: {
          path: '/students/:studentId/',
          name: 'Student Details',
          key: 'student-details',
          makePath: (studentId, tabName) =>
            `/students/${studentId}/?${new URLSearchParams({ ...(tabName && { tab: tabName.toString() }) }).toString()}`,
        },
        SupportByNeeds: {
          path: '/students/:studentId/support-by-needs',
          name: 'Support by Needs',
          key: 'support-by-needs',
          makePath: (studentId) => `/students/${studentId}/support-by-needs`,
        },
        AttendanceImpactAnalysis: {
          path: '/students/:studentId/attendance-impact-analysis',
          name: 'Attendance Impact Analysis',
          key: 'attendance-impact-analysis',
          makePath: (studentId) => `/students/${studentId}/attendance-impact-analysis`,
        },
        SupportByRole: {
          path: '/students/:studentId/support-by-role',
          name: 'Support by Role',
          key: 'support-by-role',
          makePath: (studentId) => `/students/${studentId}/support-by-role`,
        },
        SupportByType: {
          path: '/students/:studentId/support-by-type',
          name: 'Support by Type',
          key: 'support-by-type',
          makePath: (studentId) => `/students/${studentId}/support-by-type`,
        },
        SupportByProgram: {
          path: '/students/:studentId/support-by-program',
          name: 'Support by Program',
          key: 'support-by-program',
          makePath: (studentId) => `/students/${studentId}/support-by-program`,
        },
        TotalFlagsReport: {
          path: '/students/:studentId/total-flags-report',
          name: 'Total Flags Report',
          key: 'total-flags-report',
          makePath: (studentId) => `/students/${studentId}/total-flags-report`,
        },
        AttendanceTrendsReport: {
          path: '/students/:studentId/attendance-trends-report',
          name: 'Attendance Trends Report',
          key: 'attendance-trends-report',
          makePath: (studentId) => `/students/${studentId}/attendance-trends-report`,
        },
        BehaviorAnalysisReport: {
          path: '/students/:studentId/behavior-analysis-report',
          name: 'Behavior Analysis Report',
          key: 'behavior-analysis-report',
          makePath: (studentId) => `/students/${studentId}/behavior-analysis-report`,
        },
      },
    },
    Actions: {
      Root: {
        path: '/actions',
        name: 'Actions',
        key: 'actions',
        makePath: () => '/actions',
      },
      Details: {
        path: '/actions/:actionId',
        name: 'Action Details',
        key: 'action-details',
        makePath: (actionId, tab) =>
          `/actions/${actionId}/?${new URLSearchParams({ ...(tab && { tab: tab.toString() }) }).toString()}`,
      },
      Group: {
        path: '/actions/group',
        name: 'My Group Actions',
        key: 'my-group-actions',
        makePath: () => '/actions/group',
      },
      GroupDetails: {
        path: '/actions/group/:groupId',
        name: 'Group Details',
        key: 'group-details',
        makePath: (groupId, tab) =>
          `/actions/group/${groupId}/?${new URLSearchParams({ ...(tab && { tab: tab.toString() }) }).toString()}`,
      },
    },
    Safs: {
      Root: {
        path: '/safs',
        name: 'SAF List Manager',
        key: 'safs',
        makePath: (defaultOwnerId, defaultTimePeriod, defaultSafStatus) =>
          `/safs/?${new URLSearchParams({
            ...(defaultOwnerId && { current_owner: defaultOwnerId.toString() }),
            ...(defaultTimePeriod && { time_period: defaultTimePeriod.toString() }),
            ...(defaultSafStatus && { status: defaultSafStatus.toString() }),
          }).toString()}`,
      },
      SAFDetails: {
        path: '/safs/:safId',
        name: 'SAF Details',
        key: 'saf-details',
        makePath: (safId, tab) =>
          `/safs/${safId}/?${new URLSearchParams({ ...(tab && { tab: tab.toString() }) }).toString()}`,
      },
    },
    ServiceLinks: {
      Root: {
        path: '/service-links',
        name: 'Service Link Manager',
        key: 'service-links',
        makePath: () => '/service-links',
      },
    },
    Profile: {
      Root: {
        path: '/profile',
        name: 'User profile',
        key: 'profile',
        makePath: () => '/profile',
      },
    },
    District: {
      Root: {
        path: '/district',
        name: 'District',
        key: 'district',
        makePath: () => '/district',
      },
      Settings: {
        path: '/district/settings',
        name: 'District Settings',
        key: 'district-settings',
        makePath: () => '/district/settings',
      },
    },
    Monitor: {
      Root: {
        path: '/monitor',
        name: 'Monitor',
        key: 'monitor',
        makePath: () => '/monitor',
      },
    },
    Community: {
      Root: {
        path: '/community',
        name: 'Community',
        key: 'community',
        makePath: (defaultTimePeriod, defaultOwnerId) =>
          `/community?${new URLSearchParams({
            ...(defaultTimePeriod && { time_period: defaultTimePeriod.toString() }),
            ...(defaultOwnerId && { created_by: defaultOwnerId.toString() }),
          }).toString()}`,
      },
      Details: {
        path: '/community/:activityId',
        name: 'Community Details',
        key: 'community-details',
        makePath: (communityId, tab) =>
          `/community/${communityId}/?${new URLSearchParams({ ...(tab && { tab: tab.toString() }) }).toString()}`,
      },
    },
    ServiceProviders: {
      Root: {
        path: '/service-providers',
        name: 'Service Providers',
        key: 'service-providers',
        makePath: () => '/service-providers',
      },
    },
    Help: {
      Root: {
        path: '/help',
        name: 'Help',
        key: 'help',
        makePath: () => '/help',
      },
    },
  },
  Form: {
    Root: {
      path: '/form',
      name: 'Form',
      key: 'form',
      makePath: () => '/form',
    },
    StudentAssistance: {
      Root: {
        path: '/form/student-assistance',
        name: 'Student Assistance Form',
        key: 'student-assistance-form',
        makePath: () => '/form/student-assistance',
      },
    },
  },
  System: {
    ConfirmEmail: {
      Root: {
        path: '/email-change-request/:userId/:token',
        name: 'Change Email',
        key: 'change-email',
        makePath: () => '/email-change-request/:userId/:token',
      },
    },
    DeclineEmail: {
      Root: {
        path: '/email-change-decline/:userId/:token',
        name: 'Decline Email',
        key: 'decline-email',
        makePath: () => '/email-change-decline/:userId/:token',
      },
    },
    NotFound: {
      Root: {
        path: '/404',
        name: 'Not Found',
        key: 'not-found',
        makePath: () => '/404',
      },
    },
    DeactivatedDistrict: {
      Root: {
        path: '/deactivated',
        name: 'Deactivated District',
        key: 'deactivated',
        makePath: () => '/deactivated',
      },
    },
    ServerError: {
      Root: {
        path: '/500',
        name: 'Server Error',
        key: 'server-error',
        makePath: () => '/500',
      },
    },
    Print: {
      path: '/print',
      name: 'Print',
      key: 'print',
      makePath: (variant) => `/print?${new URLSearchParams({ ...(variant && { variant: variant.toString() }) }).toString()}`,
    },
  },
} as const;

export const WHITE_LIST_ROUTES = [
  AppRoutes.Auth.SignIn.Root.path,
  AppRoutes.Auth.CreateAccount.Root.path,
  AppRoutes.Auth.ResetPassword.Root.path,
  AppRoutes.Auth.NewPassword.Root.path,
  AppRoutes.Form.Root.path,
  AppRoutes.Form.StudentAssistance.Root.path,
  AppRoutes.System.ServerError.Root.path,
  AppRoutes.System.NotFound.Root.path,
  AppRoutes.System.ConfirmEmail.Root.path,
  AppRoutes.System.DeclineEmail.Root.path,
  AppRoutes.Auth.SsoComplete.Root.path,
  AppRoutes.System.Print.path,
] as const;
