import { useMemo } from 'react';
import { STUDENT_CALENDAR_ITEM_TYPE, type TStudentCalendarItemType } from '@purple/shared-types';
import { cn, Text } from '@purple/ui';
import { ACTION_EVENT_COUNT_COLOR_MAP, ATTENDANCE_COLOR_MAP } from './../../constants';

type TYearStudentCalendarLegendProperties = {
  selectedEventType: TStudentCalendarItemType;
};

const YearStudentCalendarLegend = ({ selectedEventType }: TYearStudentCalendarLegendProperties) => {
  const legendItems = useMemo(() => {
    if (selectedEventType === STUDENT_CALENDAR_ITEM_TYPE.ATTENDANCE) {
      return Object.keys(ATTENDANCE_COLOR_MAP).map((key) => ({
        label: key,
        color: ATTENDANCE_COLOR_MAP[key as keyof typeof ATTENDANCE_COLOR_MAP],
      }));
    }

    if (selectedEventType === STUDENT_CALENDAR_ITEM_TYPE.BEHAVIOR || selectedEventType === STUDENT_CALENDAR_ITEM_TYPE.ACTIONS) {
      return Object.keys(ACTION_EVENT_COUNT_COLOR_MAP).map((key) => {
        const label = +key > 4 ? '5+ events' : `${key} event`;
        return {
          label,
          color: ACTION_EVENT_COUNT_COLOR_MAP[key as unknown as keyof typeof ACTION_EVENT_COUNT_COLOR_MAP],
        };
      });
    }

    return [];
  }, [selectedEventType]);

  if (selectedEventType === STUDENT_CALENDAR_ITEM_TYPE.ALL) {
    return null;
  }

  return (
    <div className="flex gap-4">
      {legendItems.map((item) => {
        const key = `${selectedEventType}-${item.label}`;
        return (
          <div key={key} className="flex items-center gap-2">
            <div className={cn('flex size-2 rounded-full', item.color)} />
            <Text variant="size-12" type="body-500" className="text-grey-600">{item.label}</Text>
          </div>
        );
      })}
    </div>
  );
};

export { YearStudentCalendarLegend };
