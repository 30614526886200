import type { ValueOf } from './utils';

export const PRINT_OPTIONS = {
  STUDENT_VISUAL_DASHBOARD: 'student-visual-dashboard',
  STUDENT_DATA: 'student-data',
} as const;

export type TPrintOptions = ValueOf<typeof PRINT_OPTIONS>;

export type TPrintRequestParameters = {
  variant: TPrintOptions;
  /**
   * @param landscape - Whether the print should be in landscape mode
   * @example landscape: true
   */
  landscape: 'true' | 'false';
  /**
   * @param scale - The scale of the print 0 - 1 range
   * @example scale: 0.5
   */
  scale?: number;
} & Record<string, string | number | null | undefined>;
