import React, { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import {
  STUDENT_CALENDAR_ITEM_TYPE,
  STUDENT_CALENDAR_PERIOD,
  type TStudentCalendarItemType,
  type TStudentCalendarPeriod,
} from '@purple/shared-types';
import { capitalizeFirstLetter } from '@purple/shared-utils';
import { Separator, SortingRadioGroup, SortingRoot, SortingSingleMarkGroup, Text } from '@purple/ui';

const RADIO_ITEMS = [
  {
    value: STUDENT_CALENDAR_PERIOD.YEAR,
    label: 'Year view',
  },
  {
    value: STUDENT_CALENDAR_PERIOD.MONTH,
    label: 'Month view',
  },
];

const ITEM_TYPE_OPTIONS = [
  {
    value: STUDENT_CALENDAR_ITEM_TYPE.ATTENDANCE,
    label: 'Student Attendance Calendar',
  },
  {
    value: STUDENT_CALENDAR_ITEM_TYPE.BEHAVIOR,
    label: 'Student Behavior Calendar',
  },
  {
    value: STUDENT_CALENDAR_ITEM_TYPE.ACTIONS,
    label: 'Student Actions Calendar',
  },
  {
    value: STUDENT_CALENDAR_ITEM_TYPE.ALL,
    label: 'All Calendars',
  },
];

type StudentCalendarSwitcherProperties = {
  eventType: TStudentCalendarItemType;
  onEventTypeChange: (eventType: TStudentCalendarItemType) => void;
  period: TStudentCalendarPeriod;
  onPeriodChange: (period: TStudentCalendarPeriod) => void;
};

const StudentCalendarSwitcher = ({
  eventType,
  onEventTypeChange,
  period,
  onPeriodChange,
}: StudentCalendarSwitcherProperties) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const title = React.useMemo(() => {
    const typeLabel = eventType === STUDENT_CALENDAR_ITEM_TYPE.ALL ? '' : capitalizeFirstLetter(eventType);
    return `Student ${typeLabel} Calendar (${capitalizeFirstLetter(period)})`;
  }, [eventType, period]);

  const selectedEventTypeOption = React.useMemo(() => {
    return ITEM_TYPE_OPTIONS.find((option) => option.value === eventType);
  }, [eventType]);

  const typeOptions = useMemo(() => {
    const allOptions = ITEM_TYPE_OPTIONS;
    if (period === STUDENT_CALENDAR_PERIOD.YEAR) {
      return allOptions.filter((option) => option.value !== STUDENT_CALENDAR_ITEM_TYPE.ALL);
    }
    return allOptions;
  }, [period]);

  return (
    <div className="flex flex-col gap-1">
      <SortingRoot
        trigger={(
          <div className="flex flex-row items-center gap-2">
            <Text variant="size-16" type="body-600" className="text-grey-title">
              {title}
            </Text>
            <PurpleIcon name={isOpen ? 'chevron-up' : 'chevron-down'} className="text-grey-title" />
          </div>
        )}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <SortingSingleMarkGroup
          options={typeOptions}
          selectedOption={selectedEventTypeOption}
          onChange={(newOption: { value: string; label: string }) => {
            onEventTypeChange(newOption.value as TStudentCalendarItemType);
          }}
        />
        <Separator className="border-brand-blue-700" />
        <SortingRadioGroup
          name="period"
          items={RADIO_ITEMS}
          selectedValue={period}
          onChange={(newPeriod: string) => onPeriodChange(newPeriod as TStudentCalendarPeriod)}
        />
      </SortingRoot>
      <Text variant="size-12" type="body-400" className="text-grey-600" pw-id="calendar-disclaimer">
        This data is collected from your Student Information System and is only as accurate as the data stored there.
      </Text>
    </div>
  );
};

export { StudentCalendarSwitcher };
