import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TAGS_TYPES } from '@purple/shared-types';
import { getRandomIntegerInRange, tagsSchema } from '@purple/shared-utils';
import { Form, FormControl, FormField, FormItem, FormMessage, Skeleton, TagSelect } from '@purple/ui';
import { ChoiceTag } from '~/components';
import { useApplyCustomTags, useCustomTags } from '~/queries';
import { ActionSectionHeader } from './ActionSectionHeader';
import type React from 'react';
import type { z } from 'zod';
import type { TActionDetails } from '@purple/shared-types';

type TActionTagsSectionProperties = {
  action: TActionDetails;
  hideTitle?: boolean;
  triggerClassName?: string;
};

export const ActionTagsSection: React.FC<TActionTagsSectionProperties> = (props) => {
  const { action, hideTitle = false } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { data: allTags, isFetching: isAllFetching } = useCustomTags({
    content_type: TAGS_TYPES.ACTION,
  });
  const { data: selectedTags, isFetching: isSelectedFetching } = useCustomTags({
    content_type: TAGS_TYPES.ACTION,
    object_id: action.id,
  });
  const { mutate: applyTags, isPending } = useApplyCustomTags();

  const tagOptions = useMemo(() => allTags?.results ?? [], [allTags]);
  const tags = useMemo(() => selectedTags?.results ?? [], [selectedTags]);
  const isFetching = useMemo(() => isSelectedFetching || isAllFetching, [isAllFetching, isSelectedFetching]);

  const defaultValues: z.infer<typeof tagsSchema> = useMemo(
    () => ({
      tags: tags ?? [],
    }),
    [tags],
  );

  const form = useForm<z.infer<typeof tagsSchema>>({
    resolver: zodResolver(tagsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const applyTagClickHandler = () => {
    setIsEditing(true);
  };

  const closeClickHandler = () => {
    setIsEditing(false);
  };

  const saveTagsClickHandler = (formData: z.infer<typeof tagsSchema>) => {
    applyTags(
      {
        object_id: action.id,
        content_type: TAGS_TYPES.ACTION,
        tags: formData.tags.map((tag) => tag.id),
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  if (isFetching) {
    return (
      <div className="flex w-full flex-col gap-4">
        <div className="flex h-8 flex-row items-center justify-between gap-2">
          <Skeleton className="h-[24px] w-[98px]" />
          <Skeleton className="h-[24px] w-[54px]" />
        </div>
        <div className="flex w-full flex-row gap-4">
          <Skeleton className="h-6 w-full rounded-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
          <Skeleton className="h-6 w-full rounded-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
          <Skeleton className="h-6 w-full rounded-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-4">
      <ActionSectionHeader
        title={hideTitle ? null : 'Tags'}
        editButtonLabel="Apply Tag"
        editButtonIcon="plus"
        editing={isEditing}
        loading={isPending}
        onCancel={closeClickHandler}
        onEdit={applyTagClickHandler}
        onSave={form.handleSubmit(saveTagsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              <FormField
                control={form.control}
                name="tags"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-1 flex-col gap-1 space-y-0">
                    <FormControl>
                      <TagSelect
                        {...field}
                        isError={!!fieldState.error}
                        options={tagOptions}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <ul className="flex flex-row flex-wrap items-center gap-2">
              {tags.map(({ name, color }) => (
                <li key={name}>
                  <ChoiceTag color={color}>{name}</ChoiceTag>
                </li>
              ))}
            </ul>
          )}
    </div>
  );
};
