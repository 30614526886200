import { z } from 'zod';

export const multiSelectOption = z.object({
  label: z.string(),
  value: z.string(),
});
export const multiSelectOptions = z.array(multiSelectOption);

export const multiSelectNumberOption = z.object({
  label: z.string(),
  value: z.number(),
});
export const multiSelectNumberOptions = z.array(multiSelectNumberOption);
