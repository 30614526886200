import { PurpleIcon } from '@purple/icons';
import { formatDateShortMonth } from '@purple/shared-utils';
import { Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { TSafListManagerDto } from '@purple/shared-types';

const ICON_SIZE = 16;

type TSubmittedDateColumnProperties = {
  data: TSafListManagerDto;
};

const SubmittedDateColumn = ({ data }: TSubmittedDateColumnProperties) => {
  const { created_at, is_not_prioritized_in_2_days } = data;

  return (
    <div className="flex items-center gap-1">
      <Text variant="size-14" type="body-500" className="text-nowrap text-grey-950">
        {formatDateShortMonth(created_at) || '-'}
      </Text>
      {is_not_prioritized_in_2_days && (
        <Tooltip delayDuration={300}>
          <TooltipTrigger>
            <PurpleIcon name="exclamation-circle" height={ICON_SIZE} width={ICON_SIZE} className="text-error-main" />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="center" className="w-[272px] text-center">
              This SAF has not been prioritized yet. It&apos;s been more than 48 hours!
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      )}
    </div>
  );
};

export { SubmittedDateColumn };
