import { useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { SafSubmitterType } from '@purple/shared-types';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { Button, Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, Separator, Textarea } from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useCreateSaf, useSafFieldSettings } from '~/queries';
import { safFormSchema } from './purpleUserSafSchema';
import type { z } from 'zod';

type TSafPurpleUserProperties = {
  studentId: string;
};

const SafPurpleUserModal = ({ studentId }: TSafPurpleUserProperties) => {
  const queryClient = useQueryClient();
  const formId = useId();
  const { isOpen, closeModal } = useModal(ModalType.SAF_PURPLE_USER);

  const { mutate: createSaf, isPending } = useCreateSaf();

  const { data: fieldSettingsData, isLoading: isFieldSettingsDataLoading } = useSafFieldSettings({
    submitter_type: SafSubmitterType.PURPLE_USER,
  });

  const form = useForm<z.infer<typeof safFormSchema>>({
    resolver: zodResolver(safFormSchema),
    mode: 'onChange',
    defaultValues: {
      needs: undefined,
      details: '',
    },
  });

  const needsOptions = useMemo(() => {
    return fieldSettingsData?.needs.map((item) => ({
      value: item.id,
      label: item.name,
    })) || [];
  }, [fieldSettingsData]);

  const submitStudentAssistanceHandler = (values: z.infer<typeof safFormSchema>) => {
    const body = {
      submitter_type: SafSubmitterType.PURPLE_USER,
      student: studentId,
      description: values.details,
      needs: [values.needs],
    };

    createSaf(body, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_DETAILS],
        });
        closeModal();
      },
    });
  };

  const closeClickHandler = () => {
    form.reset({
      needs: undefined,
      details: '',
    });
    closeModal();
  };

  return (
    <Dialog open={isOpen} onOpenChange={closeClickHandler}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col">
            <DialogTitle>Submit a Student Assistant Form</DialogTitle>
            <DialogDescription className="sr-only">Submit a Student Assistant Form</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form providerProps={form} onSubmit={form.handleSubmit(submitStudentAssistanceHandler)} className="flex flex-col gap-4 p-6" id={formId}>
          <FormField
            control={form.control}
            name="needs"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>This SAF is for</FormLabel>
                <RadixSelect
                  value={field.value}
                  onValueChange={field.onChange}
                  disabled={isFieldSettingsDataLoading}
                >
                  <FormControl>
                    <RadixSelectTrigger>
                      <RadixSelectValue {...field} placeholder="Select type" />
                    </RadixSelectTrigger>
                  </FormControl>
                  <RadixSelectContent>
                    {needsOptions.map((item) => (
                      <RadixSelectItem key={item.value} value={item.value}>
                        {item.label}
                      </RadixSelectItem>
                    ))}
                  </RadixSelectContent>
                </RadixSelect>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="details"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Description</FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    isError={!!form.formState.errors.details}
                    placeholder="Enter description text here"
                    responsiveHeight
                    className="min-h-[120px] resize-none"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeClickHandler}>
            Cancel
          </Button>
          <Button type="submit" form={formId} variant="primary" disabled={isPending} isLoading={isPending}>
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { SafPurpleUserModal };
