import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { usePaginate, useQueryParameter, useSearch, useSort } from '@purple/hooks';
import { Heading, TableFooterSection } from '@purple/ui';
// hooks
import { useModal } from '~/hooks';
import { useSafEmails } from '~/queries';
// constants
import { ModalType } from '~/constants/modals';
// components
import { DataTable, DataTableSkeleton } from '~/components';
import { NewEmailDialog } from '~/modules/SafListManager/components';
import { EmailListHeader } from './EmailListHeader';
// helpers
import { SafEmailQueryName, SafEmailViewType } from './constants';
import { useSafEmailColumns } from './useSafEmailColumns';
// types
import type React from 'react';
import type { ValueOf } from '@purple/shared-types';

export const EmailList: React.FC = () => {
  const { safId } = useParams();

  const { openModal: openNewEmailModal } = useModal(ModalType.SAF_EMAIL_SUBMITTER);
  const { onQueryChange: onViewChange } = useQueryParameter<ValueOf<typeof SafEmailViewType>>({
    queryName: SafEmailQueryName.VIEW,
  });
  const { sort } = useSort();
  const { debounceSearch: search } = useSearch();
  const { query: status } = useQueryParameter({ queryName: SafEmailQueryName.STATUS });
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();
  const { data, isLoading } = useSafEmails(safId as string, {
    limit,
    offset,
    ordering: sort,
    status,
    search,
  });
  const emails = useMemo(() => data?.results || [], [data?.results]);
  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);
  const isDataExist = useMemo(() => emails.length > 0 && !isLoading, [emails, isLoading]);

  const subjectClickHandler = useCallback(
    (subjectId: string) => {
      onViewChange(SafEmailViewType.DETAILS, {
        onSuccess: (urlParameters) => {
          urlParameters.set(SafEmailQueryName.CORRESPONDENCE, subjectId);
        },
      });
    },
    [onViewChange],
  );

  const safEmailColumns = useSafEmailColumns({
    onSubjectClick: subjectClickHandler,
  });

  return (
    <section className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-col gap-4 px-4 pt-6">
        <Heading tag="h2" className="text-lg font-semibold text-grey-title">
          Emails
        </Heading>
        <EmailListHeader onNewEmail={openNewEmailModal} />
      </div>
      <div className="flex w-full flex-col">
        <DataTable
          columns={safEmailColumns}
          data={emails}
          isLoading={isLoading}
          isFiltersApplied={Boolean(search)}
          emptyStateTitle="Emails not found"
          emptyStateMessage="There are no email records to display. If it is an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} />}
          classNames={{
            cell: 'py-3 h-14',
            emptyCell: 'py-8',
            wrapper: 'mb-0',
          }}
        />
        {isDataExist && (
          <TableFooterSection
            currentPage={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
      <NewEmailDialog modalName={ModalType.SAF_EMAIL_SUBMITTER} />
    </section>
  );
};
