import { Link, useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { Button } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { AttendanceTrendsReportContent } from '~/modules/Students';
import type { FC } from 'react';

export const AttendanceTrendsReportPage: FC = () => {
  const { studentId } = useParams();
  return (
    <div className="flex w-full flex-col items-start justify-normal gap-6">
      <Button variant="link" className="h-auto p-0" iconLeft={<PurpleIcon name="chevron-left" />} asChild>
        <Link to={AppRoutes.App.Students.StudentDetails.Root.makePath(studentId, 'trends_flags')}>
          Back to Student Profile
        </Link>
      </Button>
      <AttendanceTrendsReportContent />
    </div>
  );
};
