import { useCallback, useMemo, useState } from 'react';
import { useDebouncedCallback } from '@purple/hooks';
import { getInitialsFromName } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  Text,
} from '@purple/ui';
import { useUsersSearch } from '~/queries';
import type React from 'react';

type TOwnerListComboBoxProperties = {
  /**
   * The selected value of the combobox.
   */
  value: string;
  /**
   * Whether the combobox is in an error state.
   * @default false
   */
  isError?: boolean;
  /**
   * Callback that is called when the value of the combobox changes.
   */
  onChange: (value: string) => void;
  /**
   * The ID of the school that the list belongs to.
   */
  schoolId: string;
  /*
  * The status of the user that can be used as filter.
  */
  status?: string[];
};

export const OwnerListComboBox: React.FC<TOwnerListComboBoxProperties> = (props) => {
  const { value, isError = false, onChange, schoolId, status } = props;

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { data: ownerUsers, isFetching: isUserFetching } = useUsersSearch({
    search: debouncedSearchValue,
    school_access: schoolId,
    with_self: true,
    status: status?.join(','),
  });

  const singleSelectOptions = useMemo(
    () =>
      ownerUsers?.results.map((user) => ({
        ...user,
        label: user.full_name ?? user.email,
        value: user.id,
      })) ?? [],
    [ownerUsers],
  );

  const selectedOwner = useMemo(
    () => singleSelectOptions.find((user) => user.value === value) ?? null,
    [value, singleSelectOptions],
  );

  const selectValueHandler = useCallback(
    (newValue: string) => {
      onChange(newValue);
    },
    [onChange],
  );

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  return (
    <ComboBox modal>
      <ComboBoxTrigger
        isError={isError}
        placeholder="Select owner"
        selectedLabel={selectedOwner?.full_name}
        disabled={!schoolId}
      />
      <ComboBoxContent
        loading={isUserFetching}
        shouldFilter={false}
        searchPlaceholder="Search owner..."
        emptyContent="Owners not found."
        onSearchChange={debouncedSearch}
      >
        {singleSelectOptions.map(({ id, full_name, avatar }) => (
          <ComboBoxItem key={id} value={id} selected={value === id} onSelect={selectValueHandler} asChild>
            <div className="flex items-center gap-2">
              <Avatar size={32}>
                <AvatarImage src={avatar ?? undefined} />
                <AvatarFallback className="bg-grey-200">{getInitialsFromName(full_name ?? '—')}</AvatarFallback>
              </Avatar>
              <div className="flex flex-col gap-1">
                <Text variant="size-12" type="body-500" className="text-grey-950">
                  {full_name}
                </Text>
                {/* TODO: replace with real role name when BE returns it in response */}
                <Text variant="size-12" type="body-400" className="text-grey-600">
                  -
                </Text>
              </div>
            </div>
          </ComboBoxItem>
        ))}
      </ComboBoxContent>
    </ComboBox>
  );
};
