import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pie, PieChart } from 'recharts';
import { SAF_STATUS, type TDashboardSAFsNeededPrioritization } from '@purple/shared-types';
import { getRandomColor } from '@purple/shared-utils';
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardTitle,
  EMPTY_CHART_DATA,
  ReChartContainer,
  ReChartTooltip,
  ReChartTooltipContent,
} from '@purple/ui';
import { ChartLegend } from '~/components/ChartLegend';
import { AppRoutes } from '~/constants/routes/routes';
import { CHART_COLORS } from '../constants';
import type React from 'react';
import type { TChartConfig } from '@purple/ui';

const chartConfig = {
  value: {
    label: 'SAFs',
  },
  within_24_hours: {
    label: 'Within 24 hours',
    color: '#E1C122',
  },
  within_25_48_hours: {
    label: 'Within 25-48 hours ago',
    color: '#F89B3F',
  },
  over_48_hours: {
    label: 'Over 48 hours ago',
    color: '#AE5983',
  },
} satisfies TChartConfig;

type TSafsNeedingPrioritizationProperties = {
  /**
   * School SAF needing prioritization data for the selected year.
   */
  data?: TDashboardSAFsNeededPrioritization;
};

export const SafsNeedingPrioritization: React.FC<TSafsNeedingPrioritizationProperties> = (props) => {
  const { data } = props;

  const navigate = useNavigate();

  const chartData = useMemo(
    () =>
      data
        ? Object.entries(data)
            .filter(([label]) => label !== 'total')
            .map(([key, value], index) => {
              const color = CHART_COLORS[index] ?? getRandomColor();
              return {
                name: chartConfig[key as keyof Omit<TDashboardSAFsNeededPrioritization, 'total'>].label,
                value,
                fill: color,
                color,
              };
            })
        : [],
    [data],
  );
  const total = useMemo(() => chartData.reduce((accumulator, item) => accumulator + item.value, 0), [chartData]);
  const isNoData = useMemo(() => total === 0, [total]);

  const safProcessClickHandler = () => {
    navigate(AppRoutes.App.Safs.Root.makePath(undefined, undefined, SAF_STATUS.NEW));
  };

  if (!data) {
    return null;
  }

  return (
    <Card className="shadow-custom-medium gap-6 px-4 py-6">
      <CardTitle className="text-base font-semibold">SAFs Needing Prioritization</CardTitle>
      <CardContent className="flex items-center gap-8">
        <ReChartContainer config={chartConfig} className="aspect-square max-h-[164px] w-full shrink-0 basis-[164px]">
          <PieChart>
            <ReChartTooltip cursor={false} content={<ReChartTooltipContent hideLabel />} />
            <Pie outerRadius={164 / 2} data={isNoData ? EMPTY_CHART_DATA : chartData} dataKey="value" nameKey="name" />
          </PieChart>
        </ReChartContainer>
        <div className="flex w-full flex-col gap-6">
          <p className="inline-flex flex-col">
            <strong className="text-grey-title text-4xl font-extrabold">{total}</strong>
            <span className="text-grey-600 text-sm font-medium">Total SAFs</span>
          </p>
          <ChartLegend chartData={chartData} />
        </div>
      </CardContent>
      <CardFooter className="mt-auto justify-center">
        <Button type="button" variant="primary" onClick={safProcessClickHandler}>
          Process SAFs
        </Button>
      </CardFooter>
    </Card>
  );
};
