import { getAxiosInstance } from '../../api';
import { DASHBOARD_ENDPOINTS } from '../../constants/private-api';
import type { TDashboardGradeStudentsPayload, TDashboardGradeStudentsResponse } from '@purple/shared-types';

export const getDashboardGradeStudents = async (parameters: TDashboardGradeStudentsPayload) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TDashboardGradeStudentsResponse>(
    DASHBOARD_ENDPOINTS.GET_DASHBOARD_NEW_STUDENTS_GRADES,
    {
      params: parameters,
    },
  );
  return response.data;
};
