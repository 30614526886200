import axios from 'axios';
import { LocalStorageKey } from '@purple/shared-utils';
import { JWT_ENDPOINTS, PUBLIC_ENDPOINTS } from './constants/public-api';
import { CLIENT_ERROR_STATUS_CODES } from './constants/rest-api-codes';
import { getSubdomain } from './helpers';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

type ApiConfig = {
  /**
   * The base URL for the API.
   */
  baseURL: string;
  /**
   * This callback is called when the API interceptors catch an error.
   * @description When we attempt to refresh the token and it fails this callback is called.
   * @returns void
   */
  interceptorsErrorCallback: (statusCode: number) => void;
};

// CUSTOM ERROR HANDLING TYPES
type AxiosErrorData<T> = Record<keyof T, string[]>;
type CustomAxiosResponse<T> = {
  data: AxiosErrorData<T>;
} & AxiosResponse;
export type CustomAxiosError<T> = {
  response?: CustomAxiosResponse<T>;
} & AxiosError;

type TokenRefreshResponseBody = {
  access: string;
};

type TokenVerifyRequestBody = {
  token: string;
};

type TokenVerifyResponseBody = {
  valid: boolean;
};

let axiosInstance: AxiosInstance | null = null;

export const verify = async (body: TokenVerifyRequestBody) => {
  const apiInstance = getAxiosInstance();
  const response = await apiInstance.post<TokenVerifyResponseBody>(JWT_ENDPOINTS.VERIFY, body);
  return response.data;
};

/**
 * Creates an Axios instance with the given configuration.
 * @param options - the configuration for the Axios instance
 * @param options.baseURL - the base for the Axios instance
 * @param options.interceptorsErrorCallback - the callback to be called when the interceptors catch an error
 * @description This function creates an Axios instance with the given configuration.
 */
export function createApiInstance({ baseURL, interceptorsErrorCallback }: ApiConfig) {
  axiosInstance = axios.create({
    baseURL,
  });

  // Default headers
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.url && !PUBLIC_ENDPOINTS.includes(config.url)) {
        const token = localStorage.getItem(LocalStorageKey.Auth.Token.ImpersonateToken) || localStorage.getItem(LocalStorageKey.Auth.Token.AccessToken);
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      const subdomain = getSubdomain();
      if (subdomain) {
        config.headers['X-District-Subdomain'] = subdomain;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      if (!error.config) {
        throw error;
      }

      const originalRequest: AxiosRequestConfig & { _retry?: boolean } = error.config;
      if (
        error.response
        && error.response.status === CLIENT_ERROR_STATUS_CODES.UNAUTHORIZED
        && !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const isImpersonating = Boolean(localStorage.getItem(LocalStorageKey.Auth.Token.ImpersonateToken));
        const oldRefreshToken = localStorage.getItem(LocalStorageKey.Auth.Token.ImpersonateRefresh) || localStorage.getItem(LocalStorageKey.Auth.Token.RefreshToken);
        if (oldRefreshToken) {
          try {
            const {
              data: { access: newAccessToken },
            } = await axios.post<TokenRefreshResponseBody>(`${baseURL}${JWT_ENDPOINTS.REFRESH}`, {
              refresh: oldRefreshToken,
            });
            if (isImpersonating) {
              localStorage.setItem(LocalStorageKey.Auth.Token.ImpersonateToken, newAccessToken);
            } else {
              localStorage.setItem(LocalStorageKey.Auth.Token.AccessToken, newAccessToken);
            }
            if (originalRequest.headers) {
              originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            }
            return axios(originalRequest);
          } catch (refreshError) {
            const axiosRefreshError = refreshError as AxiosError;
            if (
              axiosRefreshError.response
              && axiosRefreshError.response.status === CLIENT_ERROR_STATUS_CODES.UNAUTHORIZED
            ) {
              interceptorsErrorCallback(error.response.status);
            }
          }
        } else {
          interceptorsErrorCallback(error.response.status);
        }
      }
      throw error;
    },
  );
}

export function getAxiosInstance(): AxiosInstance {
  if (!axiosInstance) {
    throw new Error('API not initialized. Please initialize API before using.');
  }

  return axiosInstance;
}

export { type AxiosError } from 'axios';
