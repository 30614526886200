import React, { useMemo } from 'react';
import { eachDayOfInterval, endOfMonth, format, getDay, startOfMonth } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { TooltipDay } from './../TooltipDay';
import type { TStudentCalendarItemType } from '@purple/shared-types';
import type { YearEvent } from './../../types';

type MonthViewProps = {
  month: Date;
  eventsByDay: Record<string, YearEvent[]>;
  selectedEventType: TStudentCalendarItemType;
};

export const MonthView: React.FC<MonthViewProps> = ({ month, eventsByDay, selectedEventType }) => {
  const firstDayOfMonth = startOfMonth(month);
  const lastDayOfMonth = endOfMonth(month);
  const days = eachDayOfInterval({ start: firstDayOfMonth, end: lastDayOfMonth });
  const firstDayOffset = getDay(firstDayOfMonth);
  const correctedFirstDayOffset = firstDayOffset === 0 ? 6 : firstDayOffset - 1;

  const renderedDays = useMemo(() => {
    return [
      ...Array.from({ length: correctedFirstDayOffset }, (_, i) => <div key={`empty-${i}`} className="size-4" />),
      ...days.map((day) => (
        <TooltipDay
          key={day.toString()}
          day={day}
          events={eventsByDay[format(day, 'yyyy-MM-dd')] || []}
          selectedEventType={selectedEventType}
        />
      )),
    ];
  }, [correctedFirstDayOffset, days, eventsByDay, selectedEventType]);

  return (
    <div className="flex flex-col gap-2">
      <div className="text-size-14 font-body-500 text-grey-950">{format(month, 'MMMM', { locale: enUS })}</div>
      <div className="grid grid-cols-5 gap-4">
        {['M', 'T', 'W', 'Th', 'F'].map((day) => (
          <div key={day} className="font-body-600 text-size-12 size-4 text-center text-grey-600">
            {day}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-5 gap-4">{renderedDays}</div>
    </div>
  );
};
