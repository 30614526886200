import { formatDate } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';
import type { TStudentActionListItem } from '@purple/shared-types';

export const actionsColumns: ColumnDef<TStudentActionListItem>[] = [
  {
    accessorKey: 'title',
    header: () => (
      <Text variant="size-10" type="body-600" className="max-w-[450px] uppercase text-grey-600">
        Title
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-12" type="body-500" className="text-grey-950">
        {row.original.title ? row.original.title : '—'}
      </Text>
    ),
    size: 400,
  },
  {
    accessorKey: 'date_and_time',
    header: () => (
      <Text variant="size-10" type="body-600" className="uppercase text-grey-600">
        Date
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-12" type="body-500" className="text-grey-950">
        {row.original.date_and_time ? formatDate(row.original.date_and_time) : '—'}
      </Text>
    ),
  },
  {
    accessorKey: 'created_by',
    header: () => (
      <Text variant="size-10" type="body-600" className="uppercase text-grey-600">
        Owner
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-12" type="body-500" className="text-grey-950">
        {row.original.created_by && row.original.created_by.full_name.length > 0 ? row.original.created_by.full_name : '—'}
      </Text>
    ),
  },
];
