import { useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChartCardContainer, ChartCardContent, ChartCardFooter, ChartCardHeader, StackedBarChart } from '@purple/ui';
import { CustomizedGroupTick, LegendComponent } from '~/components';
import DownloadableWrapper from '~/components/DownloadableWrapper/DownloadableWrapper';
import { AppRoutes } from '~/constants/routes/routes';
import type { ColorRanges, TStackedChartData } from '@purple/shared-types';
import type { DownloadableWrapperReference } from '~/components';

const BAR_SIZE = 28;

type TTotalFlagsComponentProperties = {
  colorRange: ColorRanges;
  data: TStackedChartData;
};

const TotalFlagsComponent = ({ colorRange, data }: TTotalFlagsComponentProperties) => {
  const { studentId } = useParams();
  const navigate = useNavigate();

  const totalFlagsRef = useRef<DownloadableWrapperReference>(null);

  const downloadTotalFlagsChartHandler = useCallback(() => {
    totalFlagsRef.current?.downloadImage({
      scale: 1,
      quality: 1,
      format: 'png',
    });
  }, [totalFlagsRef]);

  const navigateToReport = useCallback(() => navigate(AppRoutes.App.Students.StudentDetails.TotalFlagsReport.makePath(studentId)), [
    navigate,
    studentId,
  ]);

  return (
    <DownloadableWrapper ref={totalFlagsRef} fileName={`Total Flags for ${studentId}`} containerClassName="w-full max-w-[850px]">
      <ChartCardContainer>
        <ChartCardHeader onClick={downloadTotalFlagsChartHandler} title="Total Flags" />
        <ChartCardContent className="h-[236px] p-0">
          <StackedBarChart
            barSize={BAR_SIZE}
            legend={() => <LegendComponent colorRange={colorRange} />}
            customXAxis={<CustomizedGroupTick />}
            data={data}
          />
        </ChartCardContent>
        <ChartCardFooter onClick={navigateToReport} actionTitle="View Report" />
      </ChartCardContainer>
    </DownloadableWrapper>
  );
};

export { TotalFlagsComponent };
