import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import * as z from 'zod';
import { useDebouncedCallback } from '@purple/hooks';
import {
  cutGradeName,
  getInitialsFromName,
  getNationalFormattedPhoneNumber,
  SAF_QUERY_KEYS,
} from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Text,
} from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { useGetAllStudents } from '~/queries';
import { useUpdateSafDetails } from '~/queries/saf/useUpdateSafDetails';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import TabEditSection from '../TabEditSection/TabEditSection';
import type { TSafDetailsDto } from '@purple/shared-types';

const DEFAULT_STUDENTS_LIMIT = 10;

const likedStudentUpdateFormSchema = z.object({
  student: z.string().min(1, { message: 'Please select a student' }),
});

type TFromValues = z.infer<typeof likedStudentUpdateFormSchema>;

type TSafLinkedStudentDetailsProperties = {
  safId?: string | null;
};

export const SafLinkedStudentDetails = ({ safId }: TSafLinkedStudentDetailsProperties) => {
  const queryClient = useQueryClient();

  const [isEditing, setIsEditing] = useState(false);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  const safDetailsData = queryClient.getQueryData<TSafDetailsDto>([SAF_QUERY_KEYS.GET_SAF_DETAILS, safId]);

  const { linked_student_details } = safDetailsData || {};

  const { mutate, isPending } = useUpdateSafDetails();

  const { data: studentList, isFetching: isStudentsFetching } = useGetAllStudents({
    search: debouncedSearchValue,
    limit: DEFAULT_STUDENTS_LIMIT,
    offset: 0,
    enabled: isEditing,
  });

  const selectOptions = useMemo(
    () =>
      studentList?.results
        .filter((student) => student.id !== linked_student_details?.id)
        .map((student) => ({
          ...student,
          label: `${student.first_name} ${student.last_name}`,
          value: student.id,
        })) ?? [],
    [studentList, linked_student_details?.id],
  );

  const form = useForm<TFromValues>({
    mode: 'onChange',
    resolver: zodResolver(likedStudentUpdateFormSchema),
    defaultValues: {
      student: '',
    },
  });

  const updateLinkedStudentHandler = (fromValue: TFromValues) => {
    if (!safId) {
      return showErrorToast('System Message', 'Unable to update linked student. Check if the SAF is valid.');
    }

    return mutate(
      {
        safId,
        requestBody: {
          student: fromValue.student,
        },
      },
      {
        onSuccess: () => {
          showSuccessToast('System Message', 'Liked student updated successfully');
          queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_DETAILS, safId] });
          setIsEditing(false);
          form.reset();
        },
        onError: () => showErrorToast('System Message', 'Unable to update linked student'),
      },
    );
  };

  return (
    <DescriptionList>
      {linked_student_details && (
        <TabEditSection
          isEditMode={isEditing}
          onEditChange={setIsEditing}
          isUpdating={isPending}
          onUpdate={form.handleSubmit(updateLinkedStudentHandler)}
        />
      )}
      {isEditing
        ? (
            <Form providerProps={form}>
              <FormField
                control={form.control}
                name="student"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel required>Linked Student </FormLabel>
                    <FormControl>
                      <ComboBox modal>
                        <ComboBoxTrigger
                          isError={!!fieldState.error}
                          placeholder="Select new student"
                          value={field.value}
                          selectedLabel={selectOptions.find((option) => option.value === field.value)?.label}
                        />
                        <ComboBoxContent
                          loading={isStudentsFetching}
                          shouldFilter={false}
                          searchPlaceholder="Search by student name or student id"
                          emptyContent="No students found."
                          onSearchChange={debouncedSearch}
                        >
                          {selectOptions.map(({ id, photo, first_name, last_name, student_id, grade }) => (
                            <ComboBoxItem
                              key={id}
                              value={id}
                              selected={field.value === id}
                              onSelect={field.onChange}
                              asChild
                            >
                              <div className="flex items-center gap-2">
                                <Avatar size={32}>
                                  <AvatarImage src={photo ?? undefined} />
                                  <AvatarFallback className="bg-grey-200">
                                    {getInitialsFromName(`${first_name} ${last_name}`)}
                                  </AvatarFallback>
                                </Avatar>
                                <div className="flex flex-col gap-0.5">
                                  <Text variant="size-14" type="body-600">
                                    {first_name}
                                    {' '}
                                    {last_name}
                                  </Text>
                                  <div className="flex items-center gap-2">
                                    <Text variant="size-12" type="body-400" className="text-grey-600">
                                      Student ID:
                                      {' '}
                                      <span className="font-semibold">{student_id.length > 0 ? student_id : '—'}</span>
                                    </Text>
                                    <Text variant="size-12" type="body-400" className="text-grey-600">
                                      Grade:
                                      {' '}
                                      <span className="font-semibold">{grade.length > 0 ? cutGradeName(grade) : '—'}</span>
                                    </Text>
                                  </div>
                                </div>
                              </div>
                            </ComboBoxItem>
                          ))}
                        </ComboBoxContent>
                      </ComboBox>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <DescriptionItem>
              <DescriptionTerm>Linked Student</DescriptionTerm>
              <DescriptionDetails>
                {linked_student_details && linked_student_details.full_name
                  ? (
                      <Link
                        to={AppRoutes.App.Students.StudentDetails.Root.makePath(linked_student_details.id, 'dashboard')}
                        className="text-brand-blue-700 underline-offset-2 hover:underline"
                      >
                        {linked_student_details.full_name}
                      </Link>
                    )
                  : (
                      <span className="text-error-main">Required</span>
                    )}
              </DescriptionDetails>
            </DescriptionItem>
          )}

      <DescriptionItem>
        <DescriptionTerm>Student ID</DescriptionTerm>
        <DescriptionDetails>
          {linked_student_details && linked_student_details.student_id.length > 0
            ? linked_student_details?.student_id.length
            : '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>Student Phone</DescriptionTerm>
        <DescriptionDetails>
          {linked_student_details && linked_student_details.phone.length > 0
            ? linked_student_details.phone
                .map((phone) => getNationalFormattedPhoneNumber({ phoneNumber: phone }))
                .join(', ')
            : '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>Grade</DescriptionTerm>
        <DescriptionDetails>
          {linked_student_details && linked_student_details.grade ? cutGradeName(linked_student_details.grade) : '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>School</DescriptionTerm>
        <DescriptionDetails>
          {linked_student_details && linked_student_details.school && linked_student_details.school.id
            ? (
                <Link
                  to={AppRoutes.App.School.Root.makePath(linked_student_details.school.id, 'overview')}
                  className="text-brand-blue-700 underline-offset-2 hover:underline"
                >
                  {linked_student_details.school.name}
                </Link>
              )
            : (
                '—'
              )}
        </DescriptionDetails>
      </DescriptionItem>
    </DescriptionList>
  );
};
