import type { ValueOf } from '@purple/shared-types';

export const ModalType = {
  CONFIRM_REMOVE_DIALOG: 'confirm-remove-dialog',
  CREATE_NEW_INCIDENT: 'create-new-incident',
  DETAIL_STUDENT_INCIDENT: 'detail-student-incident',
  TAKE_ACTION: 'take-action',
  CREATE_ACTIVITY: 'create-activity',
  REMOVE_MODAL: 'remove-modal',
  MY_SCHOOLS_MODAL: 'my-schools-modal',
  // Student notes modals
  STUDENT_NOTE: 'student-note',
  DELETE_STUDENT_NOTE: 'delete-student-note',
  STUDENTS_DATA_CHART: 'students-data-chart',
  // Student file modals
  UPLOAD_FILE_PREVIEW: 'upload-file-preview',
  FILES_ACCESS_SETTINGS: 'files-access-settings',
  DELETE_STUDENT_FILE: 'delete-student-file',
  EDIT_STUDENT_FILE: 'edit-student-file',
  // School file modals
  DELETE_SCHOOL_FILE: 'delete-school-file',
  EDIT_SCHOOL_FILE: 'edit-school-file',
  // Priority lists modals
  CREATE_PRIORITY_LIST: 'create-priority-list',
  ADD_TO_EXISTING_PRIORITY_LIST: 'add-to-existing-priority-list',
  SHARE_PRIORITY_LIST: 'share-priority-list',
  ADD_TO_PRIORITY_LIST: 'add-to-priority-list',
  // Add students modals
  ADD_STUDENTS_BY_ID: 'add-students-by-id',
  PRINT_STUDENT_SETTINGS: 'print-student-settings',
  // Actions modals
  DELETE_ACTION: 'delete-action',
  DELETE_GROUP_ACTION: 'delete-group-action',
  ACTION_NOTE: 'action-note',
  ACTION_ADD_NOTE: 'action-add-note',
  GROUP_ACTION_NOTE: 'group-action-note',
  GROUP_ACTION_ADD_NOTE: 'group-action-add-note',
  ADD_ACTION_PARTICIPANTS: 'add-action-participants',
  DELETE_ACTION_PARTICIPANTS: 'delete-action-participants',
  DELETE_ACTION_NOTE: 'delete-action-note',
  DELETE_GROUP_ACTION_LINKED_STUDENT: 'delete-group-action-linked-student',
  GROUP_ACTION_MANAGE_MEMBERS: 'group-action-manage-members',
  GROUP_ACTION_ADD_RECURRENCE: 'group-action-add-recurrence',
  // EcoSystemHub modals
  DEFAULT_ECO_SYSTEM_DATA_VIEW: 'default-eco-system-data-view',
  ACTION_EVENT_MODAL: 'action-event-modal',
  COMMUNITY_ACTIVITY_MODAL: 'community-activity-modal',
  GROUP_ACTION_MODAL: 'group-action-modal',
  REMINDER_EVENT_MODAL: 'reminder-event-modal',
  RESCHEDULE_ACTION_EVENT_MODAL: 'reschedule-action-event-modal',
  CONFIRM_EDIT_RECURRING_REMINDER_MODAL: 'confirm-edit-recurring-reminder-modal',
  DELETE_COMMUNITY_ACTIVITY_MODAL: 'delete-activity-event-modal',
  DELETE_ACTION_EVENT_MODAL: 'delete-action-event-modal',
  DELETE_GROUP_ACTION_EVENT_MODAL: 'delete-group-action-event-modal',
  DELETE_REMINDER_EVENT_MODAL: 'delete-reminder-event-modal',
  DELETE_RECURRING_REMINDER_EVENT_MODAL: 'delete-recurring-reminder-event-modal',
  ADD_GROUP_ACTION_MEMBERS_MODAL: 'add-group-action-members-modal',
  DELETE_GROUP_ACTION_MEMBERS_MODAL: 'delete-group-action-members-modal',
  // SAF modals
  CLOSE_SAF: 'close-saf',
  SAF_PRIORITY: 'saf-priority',
  SAF_PURPLE_USER: 'saf-purple-user',
  SAF_PLACE_ON_HOLD: 'saf-place-on-hold',
  SAF_REMOVE_FROM_HOLD: 'saf-remove-from-hold',
  SAF_TRANSFER: 'saf-transfer',
  SAF_NO_NEED: 'no-need',
  SAF_UNIDENTIFIABLE: 'saf-unidentifiable',
  SAF_NEW_EMAIL: 'saf-new-email',
  SAF_EMAIL_SUBMITTER: 'saf-email-submitter',
  // Activity modals
  DELETE_ACTIVITY: 'delete-activity',
  BULK_DELETE_ACTIVITY: 'bulk-delete-activity',
  DELETE_ACTIVITY_FILE: 'delete-activity-file',
  EDIT_ACTIVITY_FILE: 'edit-activity-file',
  // Profile modals
  PROFILE_CHANGE_PASSWORD_MODAL: 'profile-change-password-modal',
  PROFILE_CHANGE_PHOTO_MODAL: 'profile-change-photo-modal',
  DELETE_CRISIS: 'delete-crisis',
  BULK_DELETE_CRISIS: 'bulk-delete-crisis',
  // Log out modals
  LOG_OUT: 'log-out',
  // Banner modals
  BANNER_DETAILS: 'banner-details',
} as const;

export type TModalName = ValueOf<typeof ModalType>;
