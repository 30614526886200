import { STUDENT_CALENDAR_ITEM_TYPE } from '@purple/shared-types';
import type { TStudentCalendarItemType } from '@purple/shared-types';
import type { YearEvent } from './types';

export const ACTION_EVENT_COUNT_COLOR_MAP: { [key: number]: string } = {
  0: 'bg-grey-200',
  1: 'bg-brand-blue-200',
  2: 'bg-brand-blue-400',
  3: 'bg-brand-blue-600',
  4: 'bg-brand-blue-700',
  5: 'bg-brand-blue-900',
};

export const ATTENDANCE_COLOR_MAP = {
  'Present': 'bg-grey-200',
  'Partially Present': 'bg-brand-yellow',
  'Absent': 'bg-other-rose-2',
  'Holiday': 'bg-white border border-brand-blue-700',
};

export const getColorForEvent = (eventList: YearEvent[], eventType: TStudentCalendarItemType) => {
  const eventCount = eventList.length;

  if (eventType === STUDENT_CALENDAR_ITEM_TYPE.ALL) {
    return '';
  }

  if (eventType === STUDENT_CALENDAR_ITEM_TYPE.BEHAVIOR || eventType === STUDENT_CALENDAR_ITEM_TYPE.ACTIONS) {
    return ACTION_EVENT_COUNT_COLOR_MAP[eventCount > 5 ? 5 : eventCount];
  }
  if (eventType === STUDENT_CALENDAR_ITEM_TYPE.ATTENDANCE) {
    const firstEvent = eventList[0];

    if (!firstEvent) {
      return 'bg-grey-200';
    }

    return ATTENDANCE_COLOR_MAP[firstEvent?.value as keyof typeof ATTENDANCE_COLOR_MAP] || 'bg-grey-200';
  }
  return '';
};
