import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  SAF_AREA_SUBCATEGORIES_LABELS,
  TakeActionDocumentType,
  TakeActionLinkType,
  TakeActionType,
} from '@purple/shared-types';
import { constructTakeActionTitle, isFieldExist } from '@purple/shared-utils';
import {
  cn,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
// hooks
import { usePermissions } from '@purple/permissions';
import { useCurrentUser } from '~/hooks/redux';
import { useActionChoices, useUpdateGroupAction } from '~/queries';
// helpers
import { ActionTypeToPermission, ReadableTakeActionDocumentType } from '~/constants/take-action';
// components
import { GroupActionSectionHeader } from './GroupActionSectionHeader';
// types
import type React from 'react';
import type { TGroupActionDetails } from '@purple/shared-types';

const groupDetailsSchema = z
  .object({
    title: z.string(),
    document: z.nativeEnum(TakeActionDocumentType).optional(),
    type: z
      .string({
        invalid_type_error: 'Type name must be a string.',
      })
      .trim()
      .optional(),
    subType: z
      .string({
        invalid_type_error: 'Subtype must be a string.',
      })
      .trim()
      .optional(),
    numberOfResources: z
      .number({
        message: 'Number of resources must be an integer.',
      })
      .int()
      .min(0, {
        message: 'Quantity must be a positive number or zero.',
      }),
    focusAreas: z.array(z.string()).optional(),
    services: z.array(z.string()).optional(),
  })
  .superRefine((data, context) => {
    if (isFieldExist(data.type) && data.type.length === 0) {
      context.addIssue({
        path: ['type'],
        code: z.ZodIssueCode.custom,
        message: 'Type is required.',
      });
    }
    if (isFieldExist(data.subType) && data.subType.length === 0) {
      context.addIssue({
        path: ['subType'],
        code: z.ZodIssueCode.custom,
        message: 'SubType is required.',
      });
    }
    if (isFieldExist(data.focusAreas) && data.focusAreas.length === 0) {
      context.addIssue({
        path: ['focusAreas'],
        code: z.ZodIssueCode.custom,
        message: 'At least one focus area is required.',
      });
    }
    if (isFieldExist(data.services) && data.services.length === 0) {
      context.addIssue({
        path: ['services'],
        code: z.ZodIssueCode.custom,
        message: 'At least one service is required.',
      });
    }
    if (isFieldExist(data.document) && !data.document) {
      context.addIssue({
        path: ['document'],
        code: z.ZodIssueCode.custom,
        message: 'Document type is required.',
      });
    }
  });

type TGroupActionDetailsSectionProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupActionDetailsSection: React.FC<TGroupActionDetailsSectionProperties> = (props) => {
  const {
    groupAction: { id, details, record_action_type },
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { subTypeOptions, typeOptions, focusAreaOptions, serviceOptions } = useActionChoices({
    record_action_type,
    enabled: true,
  });
  const { mutate: updateGroupAction, isPending } = useUpdateGroupAction();
  const { user } = useCurrentUser();
  const { hasPermissions } = usePermissions();
  const allowedToEdit = useMemo(
    () => user.id === details.created_by?.id && hasPermissions(ActionTypeToPermission[record_action_type]),
    [user, details.created_by?.id, hasPermissions, record_action_type],
  );

  const [readableSubType] = useMemo(
    () => subTypeOptions.filter((option) => option.value === details.subtype).map((option) => option.label),
    [details.subtype, subTypeOptions],
  );
  const [readableType] = useMemo(
    () => typeOptions.filter((option) => option.value === details.type).map((option) => option.label),
    [details.type, typeOptions],
  );
  const focusAreas = useMemo(
    () =>
      isFieldExist(details.focus_areas)
        ? details.focus_areas.length > 0
          ? details.focus_areas?.map((area) => area.name).join(', ')
          : '-'
        : undefined,
    [details],
  );
  const serviceCategories = useMemo(
    () =>
      isFieldExist(details.service_categories)
        ? details.service_categories.length > 0
          ? details.service_categories?.map((category) => SAF_AREA_SUBCATEGORIES_LABELS[category.name]).join(', ')
          : '-'
        : undefined,
    [details],
  );

  const defaultValues: z.infer<typeof groupDetailsSchema> = useMemo(
    () => ({
      title: details.title,
      document: details.document_as,
      type: details.type,
      subType: details.subtype,
      numberOfResources: details.number_of_resources_selected ?? 0,
      focusAreas: details.focus_areas?.map((area) => area.id.toString()),
      services: details.service_categories?.map((category) => category.id),
    }),
    [details],
  );

  const form = useForm<z.infer<typeof groupDetailsSchema>>({
    resolver: zodResolver(groupDetailsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const documentType = form.watch('document');

  useEffect(() => {
    if (!documentType) return;
    form.setValue(
      'title',
      constructTakeActionTitle({
        documentType,
        link: TakeActionLinkType.GROUP,
        actionType: record_action_type,
        userName: user.full_name ?? 'Unknown User',
        date: details.created_at,
      }),
    );
  }, [documentType, record_action_type, user.full_name, form, details.created_at]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof groupDetailsSchema>) => {
    updateGroupAction(
      {
        id,
        ...(isFieldExist(details.type) && { type: formData.type }),
        ...(isFieldExist(details.subtype) && { subtype: formData.subType }),
        ...(isFieldExist(details.number_of_resources_selected) && {
          number_of_resources_selected: formData.numberOfResources,
        }),
        ...(isFieldExist(focusAreas) && formData.focusAreas && { focus_areas: formData.focusAreas.map(Number) }),
        ...(isFieldExist(serviceCategories) && { service_categories: formData.services }),
        ...(isFieldExist(details.document_as) && {
          document_as: formData.document,
          title: formData.title,
        }),
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-1">
      <GroupActionSectionHeader
        editing={isEditing}
        loading={isPending}
        disableEditing={!allowedToEdit}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveDetailsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-1">
              {isFieldExist(details.document_as) && (
                <FormField
                  control={form.control}
                  name="document"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Document as</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <RadixSelect onValueChange={field.onChange} value={field.value}>
                          <FormControl>
                            <RadixSelectTrigger
                              className={cn('max-h-9', {
                                'border-error-main': !!fieldState.error,
                              })}
                            >
                              <RadixSelectValue placeholder="Select document type" />
                            </RadixSelectTrigger>
                          </FormControl>
                          <RadixSelectContent>
                            {Object.values(TakeActionDocumentType).map((value) => (
                              <RadixSelectItem key={value} value={value}>
                                {ReadableTakeActionDocumentType[value]}
                              </RadixSelectItem>
                            ))}
                          </RadixSelectContent>
                        </RadixSelect>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.name) && (
                <DescriptionItem>
                  <DescriptionTerm>Group Name</DescriptionTerm>
                  <DescriptionDetails>{details.name}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.type) && (
                <FormField
                  control={form.control}
                  name="type"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Type</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select type"
                              selectedLabel={typeOptions.find((option) => option.value === field.value)?.label}
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent searchPlaceholder="Search type..." emptyContent="Type not found.">
                            {typeOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.subtype) && (
                <FormField
                  control={form.control}
                  name="subType"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Subtype</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select subtype"
                              selectedLabel={subTypeOptions.find((option) => option.value === field.value)?.label}
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent searchPlaceholder="Search subtype..." emptyContent="Subtype not found.">
                            {subTypeOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.number_of_resources_selected) && (
                <FormField
                  control={form.control}
                  name="numberOfResources"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Number of Resources
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!form.formState.errors.numberOfResources}
                            placeholder="Enter number of resources"
                            className="h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(focusAreas) && (
                <FormField
                  control={form.control}
                  name="focusAreas"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Focus Areas</FormLabel>
                      <div className={cn('flex w-full max-w-full flex-col gap-1')}>
                        <FormControl>
                          <MultiSelect
                            {...field}
                            isError={!!form.formState.errors.focusAreas}
                            modalPopover
                            showSearch
                            selectedOptions={focusAreaOptions.filter((service) => field.value?.includes(service.value))}
                            placeholder="Select areas"
                            classNames={{ trigger: 'min-h-9 py-1' }}
                            onOptionChange={field.onChange}
                          >
                            {focusAreaOptions.map((option) => (
                              <MultiSelectItem
                                key={option.value}
                                value={option.value}
                                option={option}
                                isSelected={field.value?.includes(option.value)}
                              />
                            ))}
                          </MultiSelect>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(serviceCategories)
              && (record_action_type === TakeActionType.COUNSELING_SESSION
                ? (
                    <DescriptionItem>
                      <DescriptionTerm>Service Categories</DescriptionTerm>
                      <DescriptionDetails className="whitespace-pre-wrap text-right">{serviceCategories}</DescriptionDetails>
                    </DescriptionItem>
                  )
                : (
                    <FormField
                      control={form.control}
                      name="services"
                      render={({ field }) => (
                        <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                          <FormLabel className="font-primary text-base font-normal text-grey-600">
                            Service Categories
                          </FormLabel>
                          <div className={cn('flex w-full max-w-full flex-col gap-1')}>
                            <FormControl>
                              <MultiSelect
                                {...field}
                                isError={!!form.formState.errors.services}
                                selectedOptions={serviceOptions.filter((service) => field.value?.includes(service.value))}
                                placeholder="Select categories"
                                classNames={{ trigger: 'min-h-9 py-1' }}
                                modalPopover
                                showSearch
                                onOptionChange={field.onChange}
                              >
                                {serviceOptions.map((option) => (
                                  <MultiSelectItem
                                    key={option.value}
                                    value={option.value}
                                    option={option}
                                    isSelected={field.value?.includes(option.value)}
                                  />
                                ))}
                              </MultiSelect>
                            </FormControl>
                            <FormMessage />
                          </div>
                        </FormItem>
                      )}
                    />
                  ))}
              {isFieldExist(details.created_by) && (
                <DescriptionItem>
                  <DescriptionTerm tag="p">Created By</DescriptionTerm>
                  <DescriptionDetails tag="p">{details.created_by.full_name ?? 'N/A'}</DescriptionDetails>
                </DescriptionItem>
              )}
            </Form>
          )
        : (
            <DescriptionList>
              {isFieldExist(details.document_as) && (
                <DescriptionItem>
                  <DescriptionTerm>Document as</DescriptionTerm>
                  <DescriptionDetails>{ReadableTakeActionDocumentType[details.document_as]}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.name) && (
                <DescriptionItem>
                  <DescriptionTerm>Group Name</DescriptionTerm>
                  <DescriptionDetails>{details.name}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.type) && (
                <DescriptionItem>
                  <DescriptionTerm>Type</DescriptionTerm>
                  <DescriptionDetails>{readableType}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.subtype) && (
                <DescriptionItem>
                  <DescriptionTerm>Subtype</DescriptionTerm>
                  <DescriptionDetails>{readableSubType ?? '-'}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.number_of_resources_selected) && (
                <DescriptionItem>
                  <DescriptionTerm>Number of Resources</DescriptionTerm>
                  <DescriptionDetails>{details.number_of_resources_selected}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(focusAreas) && (
                <DescriptionItem>
                  <DescriptionTerm>Focus Areas</DescriptionTerm>
                  <DescriptionDetails className="max-w-[300px] text-right">{focusAreas}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(serviceCategories) && (
                <DescriptionItem>
                  <DescriptionTerm>Service Categories</DescriptionTerm>
                  <DescriptionDetails className="max-w-[300px] text-right">{serviceCategories}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.created_by) && (
                <DescriptionItem>
                  <DescriptionTerm>Created By</DescriptionTerm>
                  <DescriptionDetails>{details.created_by.full_name ?? 'N/A'}</DescriptionDetails>
                </DescriptionItem>
              )}
            </DescriptionList>
          )}
    </div>
  );
};
